﻿import { Client } from './../../../../src/modules/models/client/client';

export class IndividualClient extends Client{
     
    public title: string;
    public titleId: number;
    public firstName: string;
    public lastName: string;
    public gender: number;
    public birthDay: number;
    public birthMonth: number;
    public birthYear: number;
    public ageId: number;
    public age: string;
    public anniversaryYear: number;
    public anniversaryMonth: number;
    public anniversaryDate: number;
    public password: string;
    public userName: string;
    //public List < Hobby > Hobbies { get; set; }
    //public List < Preference > Preferences { get; set; }
}