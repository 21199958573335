﻿import { BaseResource } from '../base-resource';
import { DocumentFileType } from './document-file-type';
import { jsonIgnoreReplacer, jsonIgnore } from 'json-ignore';


export class AdHocDocument extends BaseResource {
    constructor() {
        super();
        this.type = "adHocDocument";
       // this.documentFileType = new DocumentFileType();
    }
    name: string;
    description: string;
    documentFile: string;
    @jsonIgnore() fileSize: string;
    @jsonIgnore() documentFileOriginal: string;
    @jsonIgnore() file: File;
   // documentFileType: DocumentFileType;
}