import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
//import { ModalContentComponent } from "../modal-content/modal-content.component";
import { Store, ICart, AddDocument, DeleteDocument } from '../../../store/index';
import { Observable } from "rxjs";
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document'


@Component({
    selector: 'opt-doc-modal',
    templateUrl: './doc-modal.component.html',
    //styleUrls: ['./doc-modal.component.scss']
})
export class DocModalComponent implements OnInit {

    selectedFiles: AdHocDocument[] = [];
    cart$: Observable<ICart>;  

    constructor(public dialogRef: MatDialogRef<DocModalComponent>,
        private store: Store<any>,
        //public matDialogRef: MatDialogRef<ModalContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    public close() {
        this.dialogRef.close();
    }

    onFileChanged(event) {
        this.store.dispatch(new AddDocument(event.target.files[0]));
        //this.selectedFiles.push(event.target.files[0]);
    }
    ngOnInit() {
        this.cart$ = this.store.select('cart');
        this.cart$.subscribe(state => {
            this.selectedFiles = state.AddedDocuments;
        });
    }

    remove(data: AdHocDocument) {

        this.store.dispatch(new DeleteDocument(data));
        //let index = this.selectedFiles.findIndex(x => x == data);
        //if (index != undefined) {
        //    this.selectedFiles.splice(index, 1);
        //}
        //if (this.selectedFiles.length == 0) {
        //    this.selectedFiles = [];
        //}

    }

    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return 'n/a';
        var i = +(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + ' ' + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    }

    //uplaod() {

    //    this.selectedFiles.forEach(function (file) {
    //        var myReader: FileReader = new FileReader();
    //        myReader.onloadend = (e) => {
    //            let data = myReader.result;
    //            console.log(data);
    //        }
    //        myReader.readAsDataURL(file);
    //    });
       
    //}
}
