﻿import { BaseResource } from '../base-resource';
import { Event } from './event';
import { Package } from './package';
import { BookingItems } from './booking-items';
import { jsonIgnoreReplacer, jsonIgnore } from 'json-ignore';


export class BookingPackages extends BaseResource {
    constructor() {
        super();
        this.bookingItems = [];
        this.event = new Event();
        this.package = new Package();
        this.type = "BookingPackage";
      
       // this.entityConfigurationProfileId = 0;
    }

    endDate: string;
    margin: number;
    name: string;
    priceTypeId: number;
    quantity: string;
    singleDayPackage: false
    startDate: string;
    taxRate: number;   
    bookingItems: BookingItems[];
    package: Package;
    event: Event;
   
    @jsonIgnore() entityConfigurationProfileId: number;
    // optional param

    attendees: number;
    //unitPrice: number;




}