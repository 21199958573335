﻿import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, filter, scan } from "rxjs/operators";
import { RoutePath } from 'src/modules/booking-portal/route-path';
import { Redirect } from 'src/modules/store/actions'

@Injectable()
export class LoginEffects {

    constructor(private actions$: Actions, private router: Router) { }

    
    @Effect({ dispatch: false })
    loginSuccess$ = this.actions$.pipe(
            ofType('SET_CONTACT'),
            map((user) => {
                //console.log('SET_CONTACT');
                //console.log(user);
                //this.router.navigate(['/facility'])
            })
    );

    @Effect({ dispatch: false })
    redirectUser$ = this.actions$.pipe(
            ofType('REDIRECT'),
            map((url: Redirect) => {
            //console.log('SET_CONTACT');
            //console.log(user);
            switch (url.payload) {
                case "LOGIN":
                    this.router.navigate([RoutePath.Login]);
                    break;
                case "SIGNUP":
                    this.router.navigate([RoutePath.SignUp]);
                    break;
                
                case "FACILITY":
                    this.router.navigate([RoutePath.Facility]);
                    break;
                case "CART":
                    this.router.navigate([RoutePath.BookingCart]);
                    break;
                case "CART-LOGIN":
                    this.router.navigate([RoutePath.BookingCart], { queryParams: { index: "2" } });
                    break; 
                case "CART-SIGNUP":
                    this.router.navigate([RoutePath.BookingCart], { queryParams: { index: "2", displayLogin : false } });
                    break;
                case "CART-COMPLETE":
                    this.router.navigate([RoutePath.BookingCart], { queryParams: { index: "3" } });
                    break;
                case "SIGNUPFROMBOOKINGDETAIL":
                    this.router.navigate([RoutePath.SignUpFromBookingDetail]);
                    break;
                case "DirectLoginFromBooking":
                    this.router.navigate([RoutePath.Login], { queryParams: { DirectLoginFromBooking: "1" } });
            }
            
        })
    );
}