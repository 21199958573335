﻿import { BaseResource } from '../base-resource';
import { Item } from './item';
import { PriceGroup } from './price-group';
import { Allocations } from './allocations';
import { jsonIgnoreReplacer, jsonIgnore } from 'json-ignore';


export class BookingItems extends BaseResource {
    constructor() {
        super();
        this.allocations = new Allocations();
        this.item = new Item();
        this.priceGroup = new PriceGroup();
        this.type = "PrivateBookingGeneraladmissionitem";     
        this.priceTypeId = 1;  
        this.unitPrice = 0;
        this.itineraryItem=false;
        this.despatchItem = false;
        this.printBeforeDespatch = false;
        this.scanBeforeDespatch = false;
       // this.showInInvoice = false;
        this.showInDocuments = false;
        this.estimatedUnitCost = 0;
    }
   
    name: string;  
    priceTypeId: number;
    quantity: number;    
    @jsonIgnore() entityConfigurationProfileId: number;
    startTime: string;
    endTime: string;
    allocations: Allocations;
    item: Item;
    priceGroup: PriceGroup;
    unitPrice: number;
    itineraryItem: boolean;
    despatchItem: boolean;
    printBeforeDespatch: boolean;
    scanBeforeDespatch: boolean;
  //  showInInvoice: boolean;
    showInDocuments: boolean;
    estimatedUnitCost: number;
    upsell: boolean; 
}
