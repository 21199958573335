import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  //styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit {
  imagepath: string = environment.ApiUrl + environment.publishFolder;
  constructor() { }

  ngOnInit() {
  }

}
