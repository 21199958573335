﻿import { Action } from '@ngrx/store';

import { Contact } from '../models/client/contact';
import { Booking } from '../models/booking/booking';
import {  PackageFilter } from '../models/booking/common';
import { Allocations } from '../models/booking/allocations';
import { FacilitySummary } from '../models/asset/facility-summary';
import { BespokePackage } from '../models/asset/bespoke-package';
import { ErrorState } from '../models/error/error';
import { MatSnackBar } from '@angular/material';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document'

export enum CartActionTypes {
    REDIRECT='REDIRECT',
    SET_CONTACT = 'SET_CONTACT',
    SIGNOUT = 'SIGNOUT',

    ADD_FACILITY = 'ADD_FACILITY',
    DELETE_FACILITY = 'DELETE_FACILITY',
    UPDATE_FACILITY = 'UPDATE_FACILITY',

    UPDATE_FACILITIES = 'UPDATE_FACILITIES',

    PACKAGE_SEARCH = 'PACKAGE_SEARCH',
    UPDATE_PACKAGES = 'UPDATE_PACKAGES',

    SET_BOOKING = 'SET_BOOKING',
    POST_BOOKING = 'POST_BOOKING',
    PATCH_BOOKING_FACILITY = 'PATCH_BOOKING_FACILITY',
    UPDATE_CART = 'UPDATE_CART',
    COMPLETE_BOOKING = 'COMPLETE_BOOKING',
    ERROR_LOG = 'ERROR_LOG',
    UPDATE_BOOKING_PAKAGE = 'UPDATE_BOOKING_PAKAGE',
    PATCH_BOOKING_CONTACT = 'PATCH_BOOKING_CONTACT',
    ADD_DOCUMENTS = 'ADD_DOCUMENTS',
    DELETE_DOCUMENTS = 'DELETE_DOCUMENTS',
    UPDATE_BOOKING_EVENT_NAME = 'UPDATE_BOOKING_EVENT_NAME',
    DO_BOOKING ='DO_BOOKING',
}
export class Redirect implements Action {
    readonly type = CartActionTypes.REDIRECT
    constructor(public payload: string) { }
}
export class SetContact implements Action {
    readonly type = CartActionTypes.SET_CONTACT
    constructor(public payload: Contact) { }
}
export class SignOut implements Action {
    readonly type = CartActionTypes.SIGNOUT
    constructor() { }
}


export class AddDocument implements Action {
    readonly type = CartActionTypes.ADD_DOCUMENTS
    constructor(public payload: AdHocDocument) { }
}

export class DeleteDocument implements Action {
    readonly type = CartActionTypes.DELETE_DOCUMENTS
    constructor(public payload: AdHocDocument) { }
}

export class AddFacility implements Action {
    readonly type = CartActionTypes.ADD_FACILITY
    constructor(public payload: FacilitySummary) { }
}
export class DeleteFacility implements Action {
    readonly type = CartActionTypes.DELETE_FACILITY
    constructor(public payload: number) { }
}
export class UpdateFacility implements Action {
    readonly type = CartActionTypes.UPDATE_FACILITY
    constructor(public payload: FacilitySummary) { }
}

export class UpdateFacilities implements Action {
    readonly type = CartActionTypes.UPDATE_FACILITIES
    constructor(public payload: FacilitySummary[]) { }
}

export class PackageSearch implements Action {
    readonly type = CartActionTypes.PACKAGE_SEARCH
    constructor() { }
}
export class UpdatePackages implements Action {
    readonly type = CartActionTypes.UPDATE_PACKAGES
    constructor(public payload: BespokePackage[]) { }
}

export class PostBooking implements Action {
    readonly type = CartActionTypes.POST_BOOKING
    constructor(public payload: Booking) { }
}
export class PatchBookingContact implements Action {
    readonly type = CartActionTypes.PATCH_BOOKING_CONTACT
    constructor() { }
}
export class PatchBookingFacility implements Action {
    readonly type = CartActionTypes.PATCH_BOOKING_FACILITY
    constructor(public payload: Booking) { }
}

export class UpdateCart implements Action {
    readonly type = CartActionTypes.UPDATE_CART
    constructor(public payload: Booking) { }
}
export class LogError implements Action {
    readonly type = CartActionTypes.ERROR_LOG
    constructor(public payload: ErrorState) { }
}
export class CompleteBooking implements Action {
    readonly type = CartActionTypes.COMPLETE_BOOKING
    constructor() { }
}

export class UpdateBookingPackage implements Action {
    readonly type = CartActionTypes.UPDATE_BOOKING_PAKAGE
    constructor(public payload: PackageFilter) { }
}

export class UpdateBookingEventName implements Action {
    readonly type = CartActionTypes.UPDATE_BOOKING_EVENT_NAME
    constructor(public payload: string) { }
}


export class DoBooking implements Action {
    readonly type = CartActionTypes.DO_BOOKING
    constructor() { }
}


export type CartActions =
    SetContact | Redirect | SignOut|
    AddFacility |DeleteFacility|UpdateFacility |
    PackageSearch | UpdatePackages |
    PostBooking | PatchBookingFacility | CompleteBooking |
    UpdateCart | UpdateFacilities | UpdateBookingPackage|
    LogError | PatchBookingContact | AddDocument | DeleteDocument | UpdateBookingEventName | DoBooking
    ;   
