import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { FormControl } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CalendarModalComponent } from 'src/modules/booking-portal/common/calendar-modal/calendar-modal.component';
import { CalenderTimeslot, Timeslot } from 'src/modules/models/asset/timeslots'

import { Utility } from 'src/modules/utility';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor'

import { Facility } from 'src/modules/models/asset/facility';
import { Booking } from 'src/modules/models/booking/booking';
import { Router } from '@angular/router';
import { RoutePath } from 'src/modules/booking-portal/route-path';
import { Store, ICart, AddFacility } from 'src/modules/store/index';
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { CurrencyFormat } from 'src/modules/currency-format';
import { FacilityImages } from 'src/modules/models/asset/facility-image';

import { AssetService } from 'src/modules/services/asset.service';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { environment } from 'src/environments/environment'
const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};


//import { TimepickerConfig } from 'ngx-bootstrap/timepicker';

//export function getTimepickerConfig(): TimepickerConfig {
//    return Object.assign(new TimepickerConfig(), {
//        hourStep: 1,
//        minuteStep: environment.MinimumBookingDuration,
//        showMeridian: true,
//        meridians: ['am', 'pm'],
//        readonlyInput: false,
//        showMinutes: true,
//        showSeconds: false

//    });
//}


@Component({
    selector: 'opt-mmc-slider',
    templateUrl: './mmc-slider.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        //{ provide: TimepickerConfig, useFactory: getTimepickerConfig }

    ],
    //styleUrls: ['./mmc-slider.component.scss']
})
export class MmcSliderComponent implements OnInit {

    date = moment();
    isValidStartTime: boolean;
    isValidEndTime: boolean;
    serializedDate = new FormControl(new Date());
    StartTime: Date | undefined = new Date();
    EndTime: Date | undefined = new Date();

    // time picker validation 
    minDate: Date = new Date();//for date picker
    minStartDate: Date;
    maxStartDate: Date;
    minEndDate: Date;
    maxEndDate: Date;
    isValidStart: boolean;
    isValidEnd: boolean;


    galleryOptions: NgxGalleryOptions[] = [];
    galleryImages: NgxGalleryImage[] = [];
    booking: Booking;

    @Input() facilityDetailDTO: Facility;
    @Input() facilityAvailablity: boolean;
    @Input() StartDateTime: string;
    @Input() EndDateTime: string;
    @Input() searchFacilities: FacilitySummary[];
    @Input() facilityId: string;
    @Input() defaultTimeIsAny: boolean;
    @Input() dateInUrl: any;
    @Output() childSearchEvent = new EventEmitter();
    @Output() childBookingEnquiry = new EventEmitter();
    @Output() changeStatus = new EventEmitter();
    //timeslots: CalenderTimeslot[]
    ftc: FacilitySummary = new FacilitySummary();
    timelable: string;
    timelableDate: string;
    s: string;
    isTimeChanged: boolean;

    constructor(private router: Router, private store: Store<any>, private _assetService: AssetService, private dialog: MatDialog) {
        this.s = "!2";
    }



    settimelable() {
        var start = moment(this.StartTime.toISOString())
        var end = moment(this.EndTime.toISOString())

        this.timelable = this.date.format("DD MMM YYYY") + " " + start.format('h:mm a') + " - " + end.format('h:mm a');
        this.timelableDate = this.date.format("DD MMM YYYY");

    }

    ngOnInit(): void {
        this.isTimeChanged = false;
        if (this.defaultTimeIsAny != true) {
            if (this.StartDateTime && this.EndDateTime) {
                this.date = moment(this.StartDateTime);
                
                this.StartTime = Utility.convertISOToDate(this.StartDateTime);
                this.EndTime = Utility.convertISOToDate(this.EndDateTime);

                var start = moment(this.StartTime.toISOString())
                var end = moment(this.EndTime.toISOString())

                this.timelable = this.date.format("DD MMM YYYY") + " | " + start.format('h:mm a') + " - " + end.format('h:mm a');
                this.timelableDate = this.date.format("DD MMM YYYY");
            }
        } else {
            this.timelable = 'Any';
            var dt = Utility.convertISOToDate(this.StartDateTime);
            this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;

            if (this.StartTime.getHours() == 23 && this.StartTime.getMinutes() == 30) {
                this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration - 1, 'm').toDate();
            } else {
                this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
            }
            if (this.dateInUrl != null)
                this.date = moment(this.dateInUrl);
        }

        var obj = this.loadImages();
        this.initializeTime();
        this.timelableDate = this.date.format("DD MMM YYYY");
    }
    openTimePopup() {
        //var arr: CalenderTimeslot[] = [];       
        //arr.push({ start: 5.5, end: 7.25 });
        this.dialog.open(CalendarModalComponent, {
            panelClass: ['custom-dialog-container', 'w60modal'],
            height: 'auto',
            width: '60%',
            data: { slots: this.ftc.calenderTimeslots, availableSlots: this.ftc.availableTimeslots },
            // DialogPosition : top

        });

    }
    loadFacilityTimeslots() {
        var fc = this.facilityId;
        var dt = new Date(this.date.toDate());
        let dtS = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
        let dtE = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
        this.ftc.calenderTimeslots = []
        this.ftc.availableTimeslots = [];
        dtE.setHours(23);
        dtE.setMinutes(59);
        dtE.setSeconds(0);
        this._assetService.timeslotSearch(fc, Utility.convertToISO(dtS), Utility.convertToISO(dtE)).subscribe(res => {
            if (res) {
                res.forEach(t => {
                    if (t.asset.id) {

                        if (!this.ftc.calenderTimeslots)
                            this.ftc.calenderTimeslots = [];
                        var arr: Timeslot[] = [];
                        arr.push(t);
                        var ts = FacilityAdaptor.TimeslotPopulate(arr);
                        if (ts) {
                            ts.forEach(x => {
                                this.ftc.calenderTimeslots.push(x);
                            });

                        }
                        // }
                    }
                });
                //temp solution since this.searchFacilities is undefined

                FacilityAdaptor.populateAvailableTimeslots([this.ftc], dtS);
                if (this.isTimeChanged == false) {
                    if (this.defaultTimeIsAny && this.checkAvailabilityForAny(this.ftc.availableTimeslots) && this.ftc.getAvailabilityLabel() == 'FULLY AVAILABLE') {
                        this.changeStatus.next(true);
                    } else if (this.defaultTimeIsAny && !(this.checkAvailabilityForAny(this.ftc.availableTimeslots) && this.ftc.getAvailabilityLabel() == 'FULLY AVAILABLE')) {
                        this.changeStatus.next(false);
                    }
                }
                //debugger;
            }
        });
        //  }
    }
    
    bookingEnquiry() {
        if (this.facilityAvailablity) {
            this.childBookingEnquiry.next({ timeslots: this.ftc.calenderTimeslots });
        }
    }

    

    apply() {
        if (this.isValidStart && this.isValidEnd) {
            this.settimelable();
            var start = moment(this.StartTime.toISOString())
            var end = moment(this.EndTime.toISOString())
            this.isTimeChanged = true;
            this.childSearchEvent.next({ 'date': new Date(this.date.year(), this.date.month(), this.date.date()), 'StartTime': this.StartTime, 'EndTime': this.EndTime });

        }
    }
    async loadImages() {

        var pr = this._assetService.loadFacilityImages(this.facilityId).toPromise();
        return await pr.then(images => {
            var showThumbnail = true;
            if (images) {
                if (images.large) {
                    if (images.large.length > 1)
                        showThumbnail = true;
                    else
                        showThumbnail = false;

                    this.galleryOptions = [
                        {
                            width: '100%',
                            height: '700px',
                            thumbnails: showThumbnail,
                            thumbnailsColumns: 5,
                            imageAnimation: NgxGalleryAnimation.Slide,
                            preview: false,
                            //imageAutoPlay: true, imageAutoPlayPauseOnHover: true,
                        },
                        // max-width 800

                        {
                            breakpoint: 768,
                            width: '100%',
                            height: '100vh',
                            imagePercent: 80,
                            thumbnails: showThumbnail,
                            thumbnailsPercent: 20,
                            thumbnailsMargin: 20,
                            thumbnailMargin: 20
                        },
                        // max-width 400

                        {
                            thumbnails: showThumbnail,
                            breakpoint: 400,
                            //preview: false
                        }
                    ];

                    images.large.forEach(i => {

                        this.galleryImages.push(
                            {
                                small: i,
                                medium: i,
                                big: i
                            });
                    });

                }
            }
        }
        );
    }

    checkAvailabilityForAny(arr) {
        var status = false;
        if (arr.length > 0) {
            arr.forEach(x => {
                let duration: number = +x.endTime - +x.startTime;
                let minutes = (duration / (1000 * 60))
                if (minutes > environment.MinimumBookingDuration) {
                    status = true;
                }
            })
            return status
        } else {
            return status;
        }
    }


    //time picker section start
    dateChanged() {

        this.loadFacilityTimeslots();
        //this.apply();

        if (this.isValidStartTime && this.isValidEndTime) {
            var start = moment(this.StartTime.toISOString())
            var end = moment(this.EndTime.toISOString())
            this.childSearchEvent.next({ 'date': new Date(this.date.year(), this.date.month(), this.date.date()), 'StartTime': this.StartTime, 'EndTime': this.EndTime });

        }

        var dt = this.date.toDate();
        this.StartTime = Utility.changeDatePart(this.StartTime, this.date.toDate());
        //this.date.add(this.StartTime.getHours(), 'h').add(this.StartTime.getMinutes(), 'm').toDate();
        var today = new Date();
        if (!(dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
            dt.setHours(0, 0, 0);
            this.minStartDate = dt;
        } else {
            if (new Date(this.StartTime) < new Date()) {
                this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
            }
            else {
                let newstartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                newstartTime.setHours(this.StartTime.getHours());
                newstartTime.setMinutes(this.StartTime.getMinutes());
                this.StartTime = newstartTime;
            }
            this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        }

        this.maxStartDate = Utility.changeDatePart(new Date(this.maxStartDate), this.date.toDate());
        this.maxStartDate.setMinutes(this.maxStartDate.getMinutes() + 1);

        let newEndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
        newEndTime.setHours(this.EndTime.getHours());
        newEndTime.setMinutes(this.EndTime.getMinutes());

        this.EndTime = newEndTime;
        this.minEndDate = new Date(moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate());
        this.maxEndDate = new Date(this.minEndDate);
        this.maxEndDate.setHours(24, 0, 0);

    }

    startChange(event) {
        //if (event != null && this.isValidStart)
        //    this.StartTime = event;
    }
    endChange(event) {
        //if (event != null && this.isValidEnd)
        //    this.EndTime = event;
    }
    displayBumpOut(date: Date) {
        let tempDate = new Date(date);
        if (this.isTimeChanged) {
            tempDate = new Date(date);
        } else {
            tempDate = Utility.convertISOToDate(this.EndDateTime)
        }
        if (this.searchFacilities != undefined) {
            if (this.searchFacilities.length > 0 && this.searchFacilities[0] != undefined) {
                if (this.searchFacilities[0].configuration != undefined) {
                    if (this.searchFacilities[0].configuration.teardownTime != undefined) {
                        tempDate.setMinutes(tempDate.getMinutes() + this.searchFacilities[0].configuration.teardownTime)
                    }
                }
            }
        }

        return tempDate
    }

    displayBumpIn(date: Date) {
        let tempDate = new Date(date);
        if (this.isTimeChanged) {
            tempDate = new Date(date);
        } else {
            tempDate = Utility.convertISOToDate(this.StartDateTime)
        }
        if (this.searchFacilities != undefined) {
            if (this.searchFacilities.length > 0 && this.searchFacilities[0] != undefined) {
                if (this.searchFacilities[0].configuration != undefined) {
                    if (this.searchFacilities[0].configuration.setupTime != undefined) {
                        tempDate.setMinutes(tempDate.getMinutes() - this.searchFacilities[0].configuration.setupTime)
                    }
                }
            }
        }

        return tempDate
    }
    startTimeChanged() {
        if (this.StartTime != null) {
            var dtObj = Utility.roundToMinDuration(this.StartTime, environment.MinimumBookingDuration);
            if (dtObj.isDateChanged)
                this.StartTime = moment(dtObj.newDate).toDate();

            this.minEndDate = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

            if (moment(this.StartTime) >= moment(this.EndTime))
                this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();


            var dt = this.date.toDate();
            var today = new Date();
            if ((dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
                if (this.StartTime < today) {
                    this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                }
            }

        }
        //this.chRef.detectChanges();
    }
    endTimeChanged() {
        //var EndTime = this.EndTime;
        if (this.EndTime != null) {
            if (this.EndTime.getFullYear() == this.maxEndDate.getFullYear() &&
                this.EndTime.getMonth() == this.maxEndDate.getMonth() &&
                this.EndTime.getDate() == this.maxEndDate.getDate() &&
                this.EndTime.getHours() == this.maxEndDate.getHours() &&
                this.EndTime.getMinutes() == this.maxEndDate.getMinutes()
            ) {
                this.EndTime = moment(this.EndTime).add(-1, 'm').toDate();

            } else {

                var dtObj = Utility.roundToMinDuration(this.EndTime, environment.MinimumBookingDuration, true);
                if (dtObj.isDateChanged)
                    this.EndTime = moment(dtObj.newDate).toDate();

                if (moment(this.StartTime) >= moment(this.EndTime))
                    this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
            }
        }
        //this.chRef.detectChanges();
        return this.EndTime;
    }
    initializeTime(): void {
        //default start time: next rounded time based on the current time
        // this.StartTime = ;
        this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        this.maxStartDate = new Date();
        this.maxStartDate.setHours(24, 0, 0);
        this.maxStartDate = moment(this.maxStartDate).add(environment.MinimumBookingDuration * -1, 'm').toDate();

        this.minEndDate = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
        this.maxEndDate = new Date();
        this.maxEndDate.setHours(24, 0, 0);
        this.dateChanged();
    }

    //time picker section end


}

