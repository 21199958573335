import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';
import { BaseService } from '../services/base/base.service';

//Models
import { Login } from '../models/login/login';
import { Contact } from '../models/client/contact';
import { CorporateClient } from '../models/client/corporate-client';
import { ClientRegistration } from '../models/client/client-registration-config';
import { QASAddress } from '../models/client/qas-address';
import { Addresses, AddressDetail } from '../models/client/Address';
import { ReferenceData } from '../models/reference-data';
import { Client } from '../models/client/Client';
import { IndividualClient } from '../models/client/Individual-client';
import { AppSetting } from '../models/app-setting';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private _baseService: BaseService) {

    }
    clientLogin(postdata: Login): Observable<Contact> {
     
        return this._baseService.WebAPIPOST("api/V4.1/customers/login?include=client", JSON.stringify(postdata)).pipe(map(response => {
            var json = (new Deserializer().deserialize(response));
            var cnt = new Contact();            
            cnt.firstName = json.firstName;
            cnt.lastName = json.lastName;
            cnt.id = json.id;
            if (json.profilePicture != undefined)
                cnt.profilePicture = environment.WebApiUrl+json.profilePicture;
            if (json.client != undefined) {
                cnt.client = new Client();
                cnt.client.id = json.client.id;

                if (json.client.type != undefined) {
                    cnt.type = json.client.type;
                }

                if (json.client.clientName != undefined) {
                    cnt.client.clientName = json.client.clientName;
                }
            }
            //map type
            console.log("Deserialized web api output");
            console.log(cnt);
            return cnt;
        }));
    }
    getClientTiltles(): any {
        return this._baseService.WebAPIGet("api/V4.1/customers/titles");
    }
    getCommunicationTypes(): any {
        return this._baseService.WebAPIGet("api/V4.1/customers/communication-types?fields=Name,Format");
    }

    getClientRegistrationConfig(): Observable<AppSetting> { 
        return this._baseService.InternalWebAPIGET("api/Client/GetClientRegistrationConfigs");
    }

    clientSave(postdata: CorporateClient): Observable<CorporateClient> {    
        var json = new Serializer().serialize(postdata)
        return this._baseService.WebAPIPOST("api/V4.1/customers/clients?include=contacts", json).pipe(map(response => {
            var corporateClient = new CorporateClient();
            corporateClient = (new Deserializer().deserialize(response));
            return corporateClient;
        }));        
    }

    getQASAddress(postData: QASAddress): Observable<Addresses> {        
        return this._baseService.InternalWebAPIPOST("api/Client/QASAddressList", postData); 
    }

    sendForgetPasswordMail(email: object): Observable<any> {
        return this._baseService.WebAPIPOST("api/V4.1/customers/request-new-password?RequestType=2", JSON.stringify(email));        
    }

    individualClientSave(postdata: IndividualClient): Observable<IndividualClient> {
        var json = new Serializer().serialize(postdata)
        return this._baseService.WebAPIPOST("api/V4.1/customers/clients", json).pipe(map(response => {
            var corporateClient = new Contact();
            corporateClient = (new Deserializer().deserialize(response));
            return corporateClient;
        }));
    }
    

}
