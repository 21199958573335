﻿import { BaseResource } from '../base-resource';
import { Facility } from './../asset/facility';
import { jsonIgnoreReplacer, jsonIgnore } from 'json-ignore';


export class Asset extends BaseResource {
    constructor() {
        super();
        this.type = "Facility";
    }
}