import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { AssetService } from 'src/modules/services/asset.service';
import { PackageService } from 'src/modules/services/package.service';
import { BookingService } from 'src/modules/services/booking.service';
import { ItemService } from 'src/modules/services/item.service';
import { ScrollTopService } from 'src/modules/services/scroll-top.service';

import { Facility } from 'src/modules/models/asset/facility';
import { BookingFacility } from 'src/modules/models/asset/booking-facility';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from 'src/environments/environment';
import { BaseParam } from 'src/modules/models/base-param';

import { Allocations } from 'src/modules/models/booking/allocations';
import { Booking } from 'src/modules/models/booking/booking';
import { BespokePackage } from 'src/modules/models/asset/bespoke-package';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { Configuration } from 'src/modules/models/asset/configuration';
import { ItemSearchParams } from 'src/modules/models/item/item-search-params'
import { SystemConfig } from 'src/modules/system-configuration'
import { Timeslot,CalenderTimeslot } from 'src/modules/models/asset/timeslots';

import { Store, ICart, AddFacility, PackageSearch } from 'src/modules/store/index';
import { Observable } from "rxjs";
import { Utility } from 'src/modules/utility';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor'
import { ILoadingStatus } from 'src/modules/store/loading/status-store'
import { FacilitySearchLoading, FacilitySearchLoaded, BookingSaveStarted, BookingSaveCompleted} from 'src/modules/store/loading/actions'

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-facility-detail',
    templateUrl: './facility-detail.component.html',
    //styleUrls: ['facility-detail.component.css']
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class FacilityDetailComponent implements OnInit, OnDestroy {
    cart$: Observable<ICart>;
    cart_subscriber: any;

    loader$: Observable<ILoadingStatus>;
    facilityDetail: Facility;
    venueFacilities: Facility[];
    WebAPiBaseUrl: string;
    facilityId: string;
    startDate: string;
    endDate: string;
    isAvailable: boolean = false;
    isShowVirtualTour: boolean = false;
    searchFacilities: FacilitySummary[];
    isSummaryPopulated: boolean = false;
    attendees: number;
    time: string;
    isTimeAny: boolean;
    isTimeAnyAndChanged: boolean;
    dateInUrl
    //images: FacilityImages = new FacilityImages();

    // google map 
    // lat: number = 6.915338;
    // lng: number = 79.858557;
    lat: number;
    lng: number;
    zoom: number = 17;
    youtubeUrl: SafeResourceUrl;
    salesChannelId: number = environment.SalesChannel;
    constructor(private _assetService: AssetService,
                ///private _packageService: PackageService,
                private _itemService: ItemService,
               // private _bookingService: BookingService,
                private _sysConfig: SystemConfig,
                private route: ActivatedRoute,

                private scrollTopService: ScrollTopService,
                private store: Store<any>,
                private router: Router,
                private sanitizer: DomSanitizer
    )
    {
        this.WebAPiBaseUrl = environment.WebApiUrl;
        this.facilityDetail = new Facility();
        this.venueFacilities = [];
        this.cart$ = this.store.select('cart');
        this.isSummaryPopulated = false;
        this.loader$ = this.store.select('loader');
        this.scrollTopService.setScrollTop();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
    ngOnDestroy() {
        if (this.cart_subscriber)
            this.cart_subscriber.unsubscribe();
    }
    ngOnInit() {
        this.isTimeAny = false;
        this.isTimeAnyAndChanged = true;
        this.route.queryParamMap.subscribe(params => {
            this.facilityId = params.get("AssetId");
            if (this.facilityId == null) {
                this.facilityId = params.get("assetid");
            }
            this.startDate = params.get("StartDate");
            if (this.startDate == null) {
                this.startDate = params.get("startdate");
            }
            this.endDate = params.get("EndDate");
            if (this.endDate == null) {
                this.endDate = params.get("enddate");
            }
            this.attendees = +params.get("Attendees");
            if (this.attendees == null) {
                this.attendees = +params.get("attendees");
            }
            this.time = params.get("Time");
            if (this.time == null) {
                this.time = params.get("time");
            }
            this.dateInUrl = params.get("Date");
            if (this.dateInUrl == null) {
                this.dateInUrl = params.get("date");
            }
            this.dateInUrl = new Date(this.dateInUrl);
            this.dateInUrl.setHours(0, 0, 0);
        });

        if (!(this.startDate && this.endDate)) {
            let StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;

            StartTime.setDate(this.dateInUrl.getDate())
            StartTime.setMonth(this.dateInUrl.getMonth())
            StartTime.setFullYear(this.dateInUrl.getFullYear())

            this.startDate = Utility.convertToISO(StartTime);
            let EndTime = moment(StartTime).add(environment.MinimumBookingDuration, 'm').toDate();           
            this.endDate = Utility.convertToISO(EndTime);
        }               
        if (this.time=='Any') {
            this.isTimeAny = true;
            this.isTimeAnyAndChanged = false;
        }

      

        this.store.dispatch(new FacilitySearchLoading());
        //this._sysConfig.loadAppConfiguration().then(() =>
        {
            this.store.dispatch(new PackageSearch());
            
            let result = this._assetService.facilityDetail(this.facilityId,this.salesChannelId);
            result.subscribe(data => {
                this.facilityDetail = data;

                this.lng = +this.facilityDetail.venue.longitude;
                this.lat = +this.facilityDetail.venue.latitude;
                if (this.facilityDetail.url)
                    this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.facilityDetail.url.replace('watch?v=', 'embed/'));

                this.populateFacilitySummary();

                let venueFacilityResult = this._assetService.facilitySearchByVenue("?venueid=" + this.facilityDetail.venue.id + "&saleschannelId=" + this.salesChannelId +"&assetclassId=1&include=Facility.Amenities,Facility.configurations");
                venueFacilityResult.subscribe(faclitydata => {
                    let index = faclitydata.findIndex(x => x.id == this.facilityDetail.id);
                    if (index != undefined) {
                        faclitydata.splice(index, 1);
                    }
                    this.venueFacilities = faclitydata;
                    
                    var res = this._assetService.getVenueRoomsImages(faclitydata);
                    if (res != null) {
                        var resPromise=res.toPromise();
                        resPromise.then(data => {
                            this.venueFacilities = data;
                        });
                    }

                },
                    err => {
                        console.log("Error", err);
                    },
                    () => {

                    },
                );
               
            },
                err => {
                    console.log("Error", err);
                },
                () => {
                    this.store.dispatch(new FacilitySearchLoaded());
                },
            );
        }
       // );
       
        //this.loadImages();
    }

    populateFacilitySummary() {
       // debugger;
        let facilitySummary = new FacilitySummary();
        facilitySummary.id = this.facilityDetail.id;
        facilitySummary.name = this.facilityDetail.name;
        facilitySummary.type = this.facilityDetail.type;
        facilitySummary.tid = this.facilityDetail.tid;
        facilitySummary.description = this.facilityDetail.description;
        facilitySummary.directions = this.facilityDetail.directions;
        facilitySummary.venue = this.facilityDetail.venue;
        facilitySummary.capacity = this.facilityDetail.capacity;
        facilitySummary.attendees = this.attendees ? this.attendees:0;
        facilitySummary.configarations = [];
        //let config = new Configuration();
        //config.id = +this.facilityDetail.eventConfiguration.id;
        //config.default = true;
        facilitySummary.configarations = this.facilityDetail.configurations;
        facilitySummary.amenities = this.facilityDetail.amenities;
        facilitySummary.items = this.facilityDetail.items;
        facilitySummary.venue = this.facilityDetail.venue;
        facilitySummary.capacity = this.facilityDetail.capacity;
        this.searchFacilities = [];
        this.searchFacilities.push(facilitySummary);

      //  this.loadFacilityTimeslots();
        if (this.facilityDetail.items.length > 0) {
            let itemsearch = new ItemSearchParams();
            itemsearch.ItemTypeid = 1;

            itemsearch.itemidlist = this.facilityDetail.items.map(i => +i.id);
            itemsearch.FromDate = Utility.convertToISO(Utility.convertISOToDate(this.startDate));
            itemsearch.Todate = Utility.convertToISO(Utility.convertISOToDate(this.endDate));

            this._itemService.itemSearch(itemsearch).subscribe((data) => {
                this.cart_subscriber= this.cart$.subscribe(cart => {
                    if (!this.isSummaryPopulated) {
                        //debugger;
                        FacilityAdaptor.PopulateFacilitySummary(this.searchFacilities, data, cart.bookingPackage);
                        this.isSummaryPopulated = true;
                        this.validateAvailability(this.startDate, this.endDate);
                    }
                });
            },
                err => { },
                () => { }
            );
        }
        //this.validateAvailability(this.startDate, this.endDate);
    }

    getSerchbaleDatefomTime(SeletedDate :Date ,selectedTime: Date) {

        let searchabledate = new Date(SeletedDate.getFullYear(), SeletedDate.getMonth(), SeletedDate.getDate());
        searchabledate.setHours(selectedTime.getHours(), selectedTime.getMinutes(), selectedTime.getSeconds());
        return Utility.convertToISO(searchabledate);
    }
    bookingEnquiry(val) {

        // save to store 
        this.store.dispatch(new BookingSaveStarted()); 

        if (this.searchFacilities.length > 0) {
            //let bookingFacility = this.searchFacilities[0]; 
            this.searchFacilities[0].calenderTimeslots = val.timeslots;
            this.searchFacilities[0].timeslotLoaded = true;

            if (this.searchFacilities[0].startTime == undefined)
                this.searchFacilities[0].startTime = this.startDate;

            if (this.searchFacilities[0].endTime == undefined)
                this.searchFacilities[0].endTime = this.endDate;
            this.store.dispatch(new AddFacility(this.searchFacilities[0]));
        }
    }

    checkAvailablity(val) {
        let sDate = new Date(val.date);    
        let sTime = new Date(val.StartTime);
        let eTime = new Date(val.EndTime);
        
        this.isTimeAnyAndChanged = true;
        this.validateAvailability(this.getSerchbaleDatefomTime(val.date, sTime), this.getSerchbaleDatefomTime(val.date, eTime));
    }

    validateAvailability(startTime, endTime) {
        if (this.facilityDetail.items.length > 0 && this.searchFacilities[0].item) {
            this.startDate = startTime;
            this.endDate = endTime;
            if (this.searchFacilities.length > 0) {
                this.searchFacilities[0].startTime = this.startDate;
                this.searchFacilities[0].endTime = this.endDate;
            }

            var TimeWithBumpIn;
            var TimeWithBumpOut
            var startTimeN = startTime;
            var endTimeN = endTime;
            if (this.searchFacilities != undefined) {
                if (this.searchFacilities.length > 0 && this.searchFacilities[0] != undefined) {

                    if (this.searchFacilities[0].configuration != undefined) {
                        if (this.searchFacilities[0].configuration.setupTime != undefined) {
                            startTimeN = Utility.convertISOToDate(startTime)
                            startTimeN.setMinutes(startTimeN.getMinutes() - this.searchFacilities[0].configuration.setupTime)
                            //startTimeN = new Date(TimeWithBumpIn);
                            startTimeN = Utility.convertToISO(startTimeN)
                        }

                        if (this.searchFacilities[0].configuration.teardownTime != undefined) {
                            endTimeN = Utility.convertISOToDate(endTime)
                            endTimeN.setMinutes(endTimeN.getMinutes() + this.searchFacilities[0].configuration.teardownTime)
                            //endTimeN = new Date(TimeWithBumpOut);
                            endTimeN = Utility.convertToISO(endTimeN)
                        }
                    }
                }
            }

            //this.store.dispatch(new FacilitySearchLoaded());
            if (this.isTimeAnyAndChanged == true) {
                let availablityResult = this._assetService.facilityAvailablity(this.facilityId, "FromDate=" + startTimeN + "&toDate=" + endTimeN);
                //debugger;
                availablityResult.subscribe(data => {
                    this.isAvailable = (data.length < 1);
                },
                    err => {
                        console.log("Error", err);
                    },
                    () => {
                        //  this.store.dispatch(new FacilitySearchLoaded());
                    },
                );
            }
        } else {
            this.isAvailable = false;
        }
    }
    downloadDocument(name, path) {
        if (path != undefined && path != null) {
            let arr = path.split('/');
            let fileFullName = arr[arr.length - 1];
            let fileExtension = fileFullName.split('.');
            return name + "." + fileExtension[fileExtension.length - 1];
        }
    }

    changeAvailbleStatus(val) {
            this.isAvailable = val;
    }
   
  
}
