import { Component, OnInit, ViewChild, Output, Input, EventEmitter, ViewChildren, QueryList, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormBuilder, AbstractControl} from '@angular/forms';
import { CustomerService } from '../../../../services/customer.service';
import { SystemService } from '../../../../services/system.service';
import { QASIntegrationService } from '../../../../services/qas-integration.service';
import { Deserializer } from 'json-api-format';
import { IndividualClient } from './../../../../models/client/Individual-client';
import { ReferenceData } from './../../../../models/client/referenceData';
import { Contact } from './../../../../models/client/Contact';
import { CommunicationMethod } from './../../../../models/client/client';
import { Client,Address, User, Venue, Tax } from './../../../../models/client/client';
import { QASAddress } from './../../../../models/client/qas-address';
import { ClientRegistration } from './../../../../models/client/client-registration-config';
import { Addresses, AddressDetail } from './../../../../models/client/Address';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutePath } from '.././../../../../modules/booking-portal/route-path';
//import { Login } from './../../../../models/login/login';
import { Store, ICart, SetContact, Redirect, PatchBookingContact, DoBooking } from './../../../../store/index';
import { MatSnackBar } from '@angular/material';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { environment } from 'src/environments/environment'

import { Observable } from "rxjs";
import { map, startWith, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'
import { Utility } from 'src/modules/utility';
@Component({
  selector: 'opt-individual',
  templateUrl: './individual.component.html',
  //styleUrls: ['./individual.component.scss']
})
export class IndividualComponent implements OnInit, AfterViewInit {
    @Output() navigated: EventEmitter<number> = new EventEmitter<number>();
    @Input() isFromBooking: string;
    @Input() tabIndex: number;    
    individuallientSaveForm: FormGroup;
    private fileReader = new FileReader();
    Image: string;
    ImageEx: string;
    Email: string;
    SelectedTitleId: string;
    SelectedTitleName: string;
    SelectedPhoneTypeId: string;
    SelectedPhoneTypeName: string;
    SelectedEmailTypeId: string;
    SelectedEmailTypeName: string;
    SelectedComTypeId: string;
    SelectedComTypeName: string;
    addressSearchText: string;
    IsEnableManualAddress: boolean;
    PrivacyPolicyLink: string;
    TermsandConditionLink: string;
    IsFromLogin: boolean;
    IsFromBookingDetail: boolean;
    CurrentUrl: string;
    Address_Country: string;
    Address_Line1: string;
    Address_Line2: string;
    Address_Line3: string;
    Address_City: string;
    Address_StateCode: string;
    Address_PostCode: string;
    DefaultCountryId: number;

    loadingStyle: boolean;
    QASLoader: boolean;

    Client: IndividualClient;
    Titles: Array<ReferenceData>;
    Contact: Contact;
   // login: Login;
    CommunicationTypes: Array<ReferenceData>;
    CommunicationTypesPhone: Array<ReferenceData>;
    CommunicationTypesEmail: Array<ReferenceData>;
    CustomerRegConfig = new ClientRegistration();
    AddressList: Addresses;
    filteredOptions: AddressDetail[];
    TempAdd: Array<string>;

    acceptedImageTypes = { 'image/png': true, 'image/jpeg': true, 'image/gif': true };
    @ViewChild('imageDrop') imageDrop;
    @ViewChild('imageInput') el;
    @ViewChild('imageError') imageError
    url:string
   
    isChecked= false;
    isEmailSent: boolean;
    siteKey: string;
    size: string;
    lang: string;
    theme: string;
    type: string;
    recaptcha;
    isCaptchaVerified = false;
    isLoad = false;
    addressLoaded = false;
    defaultCurrencyId: number;
    emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    constructor(private _customerService: CustomerService, private _systemService: SystemService, private _qasIntegrationService: QASIntegrationService, private _formBuilder: FormBuilder, private route: Router, private store: Store<any>, public snackBar: MatSnackBar) {
        this.SelectedTitleId = '';
        this.SelectedTitleName = '';
        this.Client = new IndividualClient();
        this.Titles = new Array<ReferenceData>();
        this.CommunicationTypes = new Array<ReferenceData>();
        this.CommunicationTypesPhone = new Array<ReferenceData>();
        this.CommunicationTypesEmail = new Array<ReferenceData>();
        this.AddressList = new Addresses();
        this.Contact = new Contact();
        this.Contact.client = new Client();
        this.Image = '';
        this.ImageEx = '';
        this.IsFromBookingDetail = false;
        this.IsFromLogin = false;
        this.PrivacyPolicyLink = '';
        this.TermsandConditionLink = '';
        this.DefaultCountryId = 0;
        this.defaultCurrencyId = environment.DefaultCurrencyId;
        this.siteKey = environment.ReCaptchaSiteKey;
        this.size = 'Normal';
        this.lang = 'en';
        this.theme = 'Light';
        this.type = 'Image';
        this.recaptcha = 'recaptcha1'
    }

    ngOnInit() {
        
        if ((this.route.url).includes('%3F')) {
            this.CurrentUrl = ((this.route.url).split('%3F')[1]).split('%3D')[0];
            if (this.CurrentUrl == "fromLogin") {
                this.IsFromLogin = (((this.route.url).split('%3F')[1]).split('%3D')[1] == '1') ? true : false;
            }
            else if (this.CurrentUrl == "fromBookingDetail") {
                this.IsFromBookingDetail = (((this.route.url).split('%3F')[1]).split('%3D')[1] == '1') ? true : false;
            }
        } 

        this.QASLoader = false;

        this.individuallientSaveForm = this._formBuilder.group({
            FirstName: ['', [Validators.required, Validators.maxLength(50)]],
            LastName: ['', [Validators.required, Validators.maxLength(50)]],
            PersonalPhoneNumber: [''],
            PersonalEmail: ['', Validators.pattern(this.emailPattern)],
            Password: ['', [Validators.minLength(5), ConfPasswordValidator]],
            ConfirmPassword: ['', [PasswordValidator]],
            Address_Country: [''],
            Address_Line1: [''],
            Address_Line2: [''],
            Address_Line3: [''],
            Address_City: [''],
            Address_StateCode: [''],
            Address_PostCode: [''],            
            Address: [''],
            recaptcha1: ['', Validators.required],
        });
        this.GetConfigurationSettings();
        this.GetDropDownDetails();

        this.individuallientSaveForm.controls.Address.valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), mergeMap(input =>
            this.GetQASAddress(false, '', input)
        ))
            .subscribe(data => {
                if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {
                    this.AddressList = data;
                }
                else {
                    this.AddressList = new Addresses();
                }
                this.QASLoader = false;
            }); 

        this.url = environment.PrivacyPolicyLink; 
                   
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.isLoad = true;
        });
    }

    uploadClick() {
        let ele: HTMLElement = this.el.nativeElement as HTMLElement;
        ele.click();
        //this.el.getNativeElement().click();
    }

    GetConfigurationSettings() {
        let result = this._systemService.loadConfiguration();
        result.subscribe(data => {
            if (data != null) {
                this.CustomerRegConfig = data.ClientRegistrationConfig;
                this.PrivacyPolicyLink = data.ClientRegistrationConfig.PrivacyPolicyLink;
                this.TermsandConditionLink = data.ClientRegistrationConfig.TermsandConditionLink;
                this.DefaultCountryId = data.ClientRegistrationConfig.DefaultCountryId;
                this.IsEnableManualAddress = ((data.ClientRegistrationConfig.EnableManualAddress).toLowerCase() == "false") ? false : true;
                (this.IsEnableManualAddress) ? '' : this.DisableAddressFromField();
            }
        })
    }

    DisableAddressFromField() {
        this.individuallientSaveForm.controls['Address_Country'].disable();
        this.individuallientSaveForm.controls['Address_Line1'].disable();
        this.individuallientSaveForm.controls['Address_Line2'].disable();
        this.individuallientSaveForm.controls['Address_Line3'].disable();
        this.individuallientSaveForm.controls['Address_City'].disable();
        this.individuallientSaveForm.controls['Address_StateCode'].disable();
        this.individuallientSaveForm.controls['Address_PostCode'].disable();
    }

    OpenPrivacyPolicy() {
        window.open(this.PrivacyPolicyLink, "_blank");
    }
    OpenTermAndConditions() {
        window.open(this.TermsandConditionLink, "_blank");
    }

    SaveIndividualClient() {

        this.Client.type = "individualclient"
        this.Client.tid = "1";
        this.Client.firstName = this.individuallientSaveForm.controls.FirstName.value;
        this.Client.lastName = this.individuallientSaveForm.controls.LastName.value;
        this.Client.titleId = +this.SelectedTitleId;
        this.Client.title = this.SelectedTitleName;
        this.Client.clientCategoryId = this.CustomerRegConfig.IndividualClientConfig.ClientCategoryConfig.Id;
        this.Client.clientCategory = this.CustomerRegConfig.IndividualClientConfig.ClientCategoryConfig.Name;
        this.Client.clientTypeId = this.CustomerRegConfig.IndividualClientConfig.ClientTypeConfig.Id;
        this.Client.clientType = this.CustomerRegConfig.IndividualClientConfig.ClientTypeConfig.Name; 
        this.Client.active = true;
        this.Client.dateRegistered = new Date();
        this.Client.clientName = this.SelectedTitleName + ' ' + this.individuallientSaveForm.controls.FirstName.value + ' ' + this.individuallientSaveForm.controls.LastName.value;
        this.Client.creditLimit = 0;
        this.Client.birthYear = 0;
        this.Client.birthMonth = 0;
        this.Client.birthDay = 0;
        this.Client.anniversaryYear = 0;
        this.Client.anniversaryMonth = 0;
        this.Client.anniversaryDate = 0;
        this.Client.password = this.individuallientSaveForm.controls.Password.value;
        this.Client.userName = this.individuallientSaveForm.controls.PersonalEmail.value;
        this.Client.profilePicture = (this.Image != null && this.Image != "") ? (this.Image.split(',')[1]) + '.' + this.ImageEx.split('/')[1] : null; 
        this.Client.defaultCurrencyId = this.defaultCurrencyId;
        let accManager = new User();
        accManager.type = "user";
        accManager.id = (this.CustomerRegConfig.AccountManager.Id).toString();
        accManager.userName = this.CustomerRegConfig.AccountManager.Name;

        this.Client.accountManager = accManager;
        this.Client.creditLimit = 0;

        let defVenue = new Venue();
        defVenue.type = "venue";
        defVenue.id = (this.CustomerRegConfig.Venue.Id).toString();
        defVenue.name = this.CustomerRegConfig.Venue.Name;

        this.Client.defaultVenue = defVenue

        this.Client.tax = new Tax();
        //this.Client.tax.tid = "1";
        this.Client.tax.id = (this.CustomerRegConfig.Tax.Id).toString();
        this.Client.tax.name = this.CustomerRegConfig.Tax.Name;


        let addt = new Address();
        addt.tid = "1";
        addt.country = this.individuallientSaveForm.controls.Address_Country.value;
        addt.address1 = this.individuallientSaveForm.controls.Address_Line1.value;
        addt.address2 = this.individuallientSaveForm.controls.Address_Line2.value;
        addt.address3 = this.individuallientSaveForm.controls.Address_Line3.value;
        addt.countryID = this.DefaultCountryId;
        addt.city = this.individuallientSaveForm.controls.Address_City.value;
        addt.stateCode = this.individuallientSaveForm.controls.Address_StateCode.value;
        addt.postCode = this.individuallientSaveForm.controls.Address_PostCode.value;

        this.Client.address = addt;        

        this.Client.communicationMethods = new Array<CommunicationMethod>();
        if (this.individuallientSaveForm.controls.PersonalPhoneNumber.value != null && this.individuallientSaveForm.controls.PersonalPhoneNumber.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "3";
            comMethod.communicationTypeID = this.SelectedPhoneTypeId;
            comMethod.communicationType = this.SelectedPhoneTypeName;
            comMethod.value = this.individuallientSaveForm.controls.PersonalPhoneNumber.value;
            //contact.communicationMethods.push(comMethod);
            this.Client.communicationMethods.push(comMethod);
        }
        if (this.individuallientSaveForm.controls.PersonalEmail.value != null && this.individuallientSaveForm.controls.PersonalEmail.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "4";
            comMethod.communicationTypeID = this.SelectedEmailTypeId;
            comMethod.communicationType = this.SelectedEmailTypeName;
            comMethod.value = this.individuallientSaveForm.controls.PersonalEmail.value;
            //contact.communicationMethods.push(comMethod);
            this.Client.communicationMethods.push(comMethod);
        }

        //this.Client.contacts.push(contact);
        

        //this.Client.communicationMethods = new Array<CommunicationMethod>();
        //if (this.clientSaveForm.controls.CompanyPhoneNumber.value != null || this.clientSaveForm.controls.CompanyPhoneNumber.value == "") {
        //    let comMethod = new CommunicationMethod();
        //    comMethod.tid = "1";
        //    comMethod.communicationTypeID = this.CommunicationTypesPhone.find(f => f.id == "1").id;
        //    comMethod.communicationType = this.CommunicationTypesPhone.find(f => f.id == "1").name;
        //    comMethod.value = this.clientSaveForm.controls.CompanyPhoneNumber.value;
        //    this.Client.communicationMethods.push(comMethod);
        //}
        //if (this.clientSaveForm.controls.CompanyEmail.value != null || this.clientSaveForm.controls.CompanyEmail.value == "") {
        //    let comMethod = new CommunicationMethod();
        //    comMethod.tid = "2";
        //    comMethod.communicationTypeID = this.CommunicationTypesEmail.find(f => f.id == "5").id;
        //    comMethod.communicationType = this.CommunicationTypesEmail.find(f => f.id == "5").name;
        //    comMethod.value = this.clientSaveForm.controls.CompanyEmail.value;
        //    this.Client.communicationMethods.push(comMethod);
        //}

        if (this.individuallientSaveForm.invalid) {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ['Please check the indicated fields.'],
            });
        }
        else if (this.addressLoaded){
            let clientSaveResult = this._customerService.individualClientSave(this.Client);
            clientSaveResult.subscribe(data => {
                if (data != null) {
                    if (data.id != null) {
                        this.Contact.id = data.contacts[0].id;
                        this.Contact.type = data.type;
                        this.Contact.client.id = data.id;
                        this.Contact.title = data.title;
                        this.Contact.client.clientName = data.clientName;
                        this.Contact.firstName = this.individuallientSaveForm.controls.FirstName.value;
                        this.Contact.lastName = this.individuallientSaveForm.controls.LastName.value

                        if (data.profilePicture != undefined)
                            this.Contact.profilePicture = environment.WebApiUrl + data.profilePicture;
                        this.store.dispatch(new SetContact(this.Contact));
                        if (this.CurrentUrl == 'fromLogin' && this.IsFromLogin) {
                            if (Utility.urlHistorty.length > 0) {
                                var link = Utility.urlHistorty[Utility.urlHistorty.length - 1]
                                this.route.navigate([`${link.split('?')[0]}`], { queryParams: Utility.sortParams(link) })
                            } else {
                                this.route.navigate([RoutePath.Facility])
                            }
                        } else if (this.CurrentUrl == 'fromBookingDetail') {
                            this.route.navigate([RoutePath.BookingCart])
                        }
                        else {
                          //  this.store.dispatch(new BookingSaveStarted());
                            //this.store.dispatch(new PatchBookingContact());
                            this.store.dispatch(new DoBooking());
                          //  this.navigated.emit(3);
                        }
                    }
                }
            },
                err => {
                    let errorData = [];
                    let curErr
                    if (err)
                        curErr = err.error

                    if (err.status == 500) {
                        errorData.push(err.statusText);
                    }
                    else if (err.status == 400) {
                        if (curErr) {
                            if (curErr.errors != null && curErr.errors.length > 0) {
                                curErr.errors.forEach((error, i) => {
                                    errorData.push(error.Detail);
                                });
                            }
                        }
                    }
                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: errorData,
                    });
                }
            )
        }


    }

    GetDropDownDetails() {
        let titleResult = this._customerService.getClientTiltles();
        titleResult.subscribe(data => {
            if (data != null && data.errors == null) {
                for (let title of data.data) {
                    let ti = new ReferenceData();
                    ti.id = title.id;
                    ti.name = title.attributes.name;

                    this.Titles.push(ti);
                }
            }
            this.SelectedTitleId = this.Titles[0].id;
            this.SelectedTitleName = this.Titles[0].name;
        },
            err => {

            }
        );
        let communicationTypeResult = this._customerService.getCommunicationTypes();
        communicationTypeResult.subscribe(data => {
            if (data != null && data.errors == null) {
                for (let comType of data.data) {
                    if (comType.attributes.format == 1) {
                        let comPhone = new ReferenceData();
                        comPhone.id = comType.id;
                        comPhone.name = comType.attributes.name;
                        var isExist = comPhone.name.toLowerCase().search("office");
                        if (isExist == -1) {
                            this.CommunicationTypesPhone.push(comPhone);
                        }
                    }
                    else if (comType.attributes.format == 2) {
                        let comEmail = new ReferenceData();
                        comEmail.id = comType.id;
                        comEmail.name = comType.attributes.name;
                        var isExist = comEmail.name.toLowerCase().search("office");
                        if (isExist == -1) {
                            this.CommunicationTypesEmail.push(comEmail);
                        }
                    }
                    else {
                        let com = new ReferenceData();
                        com.id = comType.id;
                        com.name = comType.attributes.name;

                        this.CommunicationTypes.push(com);
                    }

                }
            }

            var CommunicationTypesMobileIndex = this.CommunicationTypesPhone.map(function (e) { return e.name; }).indexOf('Mobile');
            this.SelectedPhoneTypeId = this.CommunicationTypesPhone[CommunicationTypesMobileIndex].id;
            this.SelectedPhoneTypeName = this.CommunicationTypesPhone[CommunicationTypesMobileIndex].name;
            this.SelectedEmailTypeId = this.CommunicationTypesEmail[0].id;
            this.SelectedEmailTypeName = this.CommunicationTypesEmail[0].name;
            this.SelectedComTypeId = this.CommunicationTypes[0].id;
            this.SelectedComTypeName = this.CommunicationTypes[0].name;
        },
            err => {

            }
        );
    }//Get drop down details

    SelectTitle(selectedTitleId: string) {
        this.SelectedTitleId = selectedTitleId;
        this.SelectedTitleName = this.Titles.find(i => i.id === selectedTitleId).name;
    }

    SelectComType(selectedComTypeId: string, basicComType: number, isAdditional: boolean) {
        if (!isAdditional) {
            if (basicComType == 1) {
                this.SelectedPhoneTypeId = selectedComTypeId;
                this.SelectedPhoneTypeName = this.CommunicationTypesPhone.find(i => i.id === selectedComTypeId).name;
            }
            else if (basicComType == 2) {
                this.SelectedEmailTypeId = selectedComTypeId;
                this.SelectedEmailTypeName = this.CommunicationTypesEmail.find(i => i.id === selectedComTypeId).name;
            }
        }
        else {
            if (basicComType == 1) {
                this.SelectedPhoneTypeId = selectedComTypeId;
                this.SelectedPhoneTypeName = this.CommunicationTypesPhone.find(i => i.id === selectedComTypeId).name;
            }
            else if (basicComType == 2) {
                this.SelectedPhoneTypeId = selectedComTypeId;
                this.SelectedEmailTypeName = this.CommunicationTypesEmail.find(i => i.id === selectedComTypeId).name;
            }
        }

    }   

    GetQASAddress(isFormattedAddress: boolean, QASID: string, searchText: string) {
        (searchText == "") ? (this.QASLoader = false, this.AddressList = new Addresses()) : '';
        (!isFormattedAddress && searchText != "") ? (this.QASLoader = true) : (this.QASLoader = false);
        let qasAddressParam = new QASAddress();
        qasAddressParam.isFormattedAddress = isFormattedAddress;
        qasAddressParam.QASId = QASID;
        qasAddressParam.SearchText = searchText;

        return this._customerService.getQASAddress(qasAddressParam);
    }

    GetFormattedAddress(address: string) {
        let selectedAdd = this.AddressList.AddressDetails.find(f => f.Address == address);
        let postCode = selectedAdd.Postcode;
        this.GetQASAddress(true, selectedAdd.QASID, '')
            .subscribe(data => {
                this.AddressList = new Addresses();
                if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {
                    this.Address_Country = data.AddressDetails[0].Country;
                    this.Address_Line1 = data.AddressDetails[0].AddressLine1;
                    this.Address_Line2 = (data.AddressDetails[0].AddressLine2 != null) ? data.AddressDetails[0].AddressLine2 : ' ';
                    this.Address_Line3 = (data.AddressDetails[0].AddressLine3 != null) ? data.AddressDetails[0].AddressLine3 : ' ';
                    this.Address_City = data.AddressDetails[0].Locality;
                    this.Address_StateCode = data.AddressDetails[0].StateCode;
                    this.Address_PostCode = postCode;
                    this.addressLoaded = true;
                    this.loadingStyle = true;
                }                
            });
        this.AddressList = new Addresses();
        this.QASLoader = false;
    }//Format selected address

    checkfiles(files) {
        if (this.acceptedImageTypes[files[0].type] !== true) {
            this.imageError.nativeElement.innerHTML = "Not a valid image";
            this.imageDrop.nativeElement.style.display = 'none';
            this.imageError.nativeElement.style.display = 'block';
            this.Image = null;
            return;
        }
        else if (files.length > 1) {
            this.imageError.nativeElement.innerHTML = "Please choose 'One' Image";
            this.imageDrop.nativeElement.style.display = 'none';
            this.imageError.nativeElement.style.display = 'block';
            this.Image = null;
            return;
        }
        else {
            this.imageError.nativeElement.style.display = 'none';
            this.imageDrop.nativeElement.style.display = 'block';
            this.readfiles(files);
        }
    }//checkfiles

    readfiles(files) {          
        this.fileReader.readAsDataURL(files[0]);
        this.ImageEx = files[0].type;
        setTimeout(() => this.Image = this.fileReader.result.toString(), 500);  

    }//readfiles

    imageUploadSubmitted() {
        console.log('IMAGE VALUE SUBMIT = =  ', this.individuallientSaveForm.controls.imageInput.value);
    }

    allowDrop(e) {
        e.preventDefault();
    }

    drop(e) {
        //e.preventDefault();
        this.imageDrop.innerHTML = "";
        this.checkfiles(e.dataTransfer.files);
    }

    imageChange(event) {
        this.imageDrop.innerHTML = "";
        this.checkfiles(event.target.files);
    }//imageChange

    PasswordValidation() {
        let password = this.individuallientSaveForm.controls.Password.value;
        let confirmPassword = this.individuallientSaveForm.controls.ConfirmPassword.value;

        return password === confirmPassword ? null : { notSame: true }
    }

    Redirect(event, id, url) {
    
        //if (this.IsFromBookingDetail && this.CurrentUrl == "fromBookingDetail") {
        //    this.route.navigate([RoutePath.BookingCart]) 
        //}
        //else {
        event.preventDefault();
        if (url == 'fromBookingDetail') {
            return this.route.navigate([RoutePath.BookingCart])
        }
        else if (id==1)
            return this.navigated.emit(1);
        else
           return this.route.navigate([RoutePath.Facility]) 
        //}
    }

    onChange(event) {
        this.isChecked = !this.isChecked;
    }

    handleSuccess(e) {
        this.isCaptchaVerified = true;
    }

    handleExpire() {
        this.isCaptchaVerified = false;
    }

    handleLoad() {
        console.log('load')
    }


}

export function PasswordValidator(control: AbstractControl) {

    if (control !== null && control !== undefined) {
        const confirmPw = control.value;

        const pwConroller = control.root.get('Password');
        if (pwConroller) {
            const pw = pwConroller.value;

            if (pw !== confirmPw) {
                return {
                    isError: true
                }
            }
        }
    }
    return null;
}
export function ConfPasswordValidator(control: AbstractControl) {


    if (control !== null && control !== undefined) {
        const Pw = control.value;

        const confirmConroller = control.root.get('ConfirmPassword');
        if (Pw) {
            const confirmPw = confirmConroller.value;

            if (Pw !== confirmPw && confirmConroller.touched) {
                confirmConroller.setErrors({ 'incorrect': true });
            } else {
                confirmConroller.setErrors({ 'incorrect': null });
                confirmConroller.updateValueAndValidity();
            }
        }
    }
    return null;
}

