import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'

import { CustomerService } from '../../../services/customer.service';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';


import { Login } from './../../../models/login/login';

import { Store, ICart, SetContact, Redirect, PatchBookingContact, DoBooking} from '../../../store/index';
import { RoutePath } from '../../route-path';
import { FacilitySearchLoaded } from 'src/modules/store/loading/actions'
import { Utility } from 'src/modules/utility';
/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['../../../../sass/pages/_login.scss']
})
export class LoginComponent implements OnInit {
    @Input() isFromBooking: string
    @Output() LoggedIn: EventEmitter<number> = new EventEmitter<number>();
    @Output() LoadSignUp: EventEmitter<number> = new EventEmitter<number>();
    matcher = new MyErrorStateMatcher();

    rfLogin: FormGroup;
    login: Login;
    InvalidCredentials: string;
    DirectLoginFromBooking: boolean = false;
    //cart: ICart;
    emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    constructor(private store: Store<any>,
        private _customerService: CustomerService,
        private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder) {
        this.login = new Login();
    }


    ngOnInit() {

        this.route.queryParamMap.subscribe(params => {
            let from = params.get("DirectLoginFromBooking");
            if (from == "1") {
                this.DirectLoginFromBooking = true;
            }
        });
        this.store.dispatch(new FacilitySearchLoaded());
        //this.store.select('cart').subscribe((state => this.cart = state))
        this.rfLogin = this.formBuilder.group({
            password: ['', Validators.required],
            email: ['', Validators.pattern(this.emailPattern)],
        });      
        this.InvalidCredentials = '';

    }
    onSubmit() {
        if (!this.rfLogin.invalid) { 
            this.login.Email = this.rfLogin.controls.email.value;
            this.login.Password = this.rfLogin.controls.password.value;
            let result = this._customerService.clientLogin(this.login);
            result.subscribe(data => {
                this.store.dispatch(new SetContact(data));

                if (this.DirectLoginFromBooking) {
                    this.store.dispatch(new Redirect("CART"));
                }
                else if (this.isFromBooking == "1") {
                    this.store.dispatch(new DoBooking());
                    //this.store.dispatch(new BookingSaveStarted());
                    //this.store.dispatch(new PatchBookingContact());
                } else {
                    if (Utility.urlHistorty.length > 0) {
                        var link = Utility.urlHistorty[Utility.urlHistorty.length - 1]
                        this.router.navigate([`${link.split('?')[0]}`], { queryParams: Utility.sortParams(link) })
                    }
                    else {
                        this.store.dispatch(new Redirect("FACILITY"));
                    }
                }

                //this.router.navigate([RoutePath.Facility]);
            },
                err => {
                    console.log("Error", err);
                    //alert(err.message);
                    this.rfLogin.controls.password.setValue('');
                    this.InvalidCredentials = 'Invalid Credentials';
                },
                () => {
                    // Do stuff after completion
                },
            );


        } else {
            this.rfLogin.controls.password.setValue('');
            this.InvalidCredentials = 'Invalid Credentials';
        }
    }

    signupClick() {
        if (this.DirectLoginFromBooking) {
            this.store.dispatch(new Redirect("SIGNUPFROMBOOKINGDETAIL"));
        } else if (this.isFromBooking == "1") {

            this.store.dispatch(new Redirect("CART-SIGNUP"));
            //this.store.dispatch(new BookingSaveStarted());
            //this.store.dispatch(new PatchBookingContact());
        } else {
            this.store.dispatch(new Redirect("SIGNUP"));
        }
    }

    
}
