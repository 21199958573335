﻿import { Asset } from './asset';
import { Venue } from './../venue/venue';
import { Item } from './../item/item';
import { Amenity } from './../amenity/amenity';
import { EventConfiguration } from './../booking/event-configuration';
import { Configuration } from './configuration'
import { LinkedDocument } from './../linked-document/linked-document';

export class Facility extends Asset {
    constructor() {
        super();
        this.venue = new Venue();
        this.amenities = [];
        this.linkedDocuments = [];
        this.eventConfiguration = new EventConfiguration();
        this.items = [];
    }

    facilityTypeId: number;
    facilityType: string;
    facilityCategoryId: number;
    facilityCategory: string;
    capacity: string;
    unitOfMesurementId: number;
    unitOfMesurement: string;
    openingTime: string;
    closingTime: string;
    url: string;
    keywords: string;
    directions: string;
    venue: Venue;
    eventConfiguration:EventConfiguration
    items: Item[];
    amenities: Amenity[];
    linkedDocuments: LinkedDocument[];
    configurations: Configuration[]
}
