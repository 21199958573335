﻿export class ClientRegistration {
    public EnableManualAddress: string;
    public PrivacyPolicyLink: string;
    public TermsandConditionLink: string;
    public DefaultCountryId: number;
    public QASIntegrationConfig: QASIntegration;
    public AccountManager: AccountManager;
    public Venue: Venue;
    public Tax: Tax;
    public CorporateClientConfig: CorporateClient;
    public IndividualClientConfig: IndividualClient;
    public ReCaptchaSiteKey: string;
    public Currency: BaseConfig;

}

export class QASIntegration {
    public Url: string;
    public Username: string;
    public Password: string;
}

export class BaseConfig {
    public Id: number;
    public Name: string;

}

export class AccountManager extends BaseConfig { }

export class Venue extends BaseConfig { }

export class Tax extends BaseConfig{ }


export class CorporateClient {    
    public ClientCategoryConfig: ClientCategory;
    public ClientTypeConfig: ClientType;
}

export class IndividualClient {    
    public ClientCategoryConfig: ClientCategory;
    public ClientTypeConfig: ClientType;
}

export class ClientCategory extends BaseConfig { }


export class ClientType extends BaseConfig { }

