﻿import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {AppSetting } from 'src/modules/models/app-setting'
import { ReferenceData } from 'src/modules/models/reference-data'
import { Deserializer, Serializer } from 'json-api-format';


@Injectable({
    providedIn: 'root'
})
export class SystemService {
    
    constructor(private _baseService: BaseService) {
            }
  
    loadConfiguration(): Observable<AppSetting> {
        return this._baseService.InternalWebAPIGET("api/system/app-settings").pipe(
            map((response) => {
                return response as AppSetting;
            })
        );
    }


    loadBusinessAreas(): Observable<ReferenceData[]> {
        return this._baseService.WebAPIGet("api/V4.1/products/business-areas").pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                console.log("Deserialized web api output");
                console.log(json);
                console.log("Convert to object");
                var lst: ReferenceData[] = [];
                json.forEach((c, i) => {
                    let refData = new ReferenceData();
                    refData.Id = c.id;
                    refData.Name = c.name;
                    lst.push(refData);
                });
                return lst
            })
        );
    }
}
