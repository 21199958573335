import { Component, OnInit,OnDestroy } from '@angular/core';
import { Store, ICart,CompleteBooking } from '../../store/index';
import { Observable } from "rxjs";

import { CustomerService } from '../../services/customer.service';
import { SystemService } from '../../services/system.service';



@Component({
  selector: 'opt-mmc-enquiry',
  templateUrl: './mmc-enquiry.component.html',
 // styleUrls: ['./mmc-enquiry.component.scss']
})
export class MmcEnquiryComponent implements OnInit, OnDestroy {
    cart$: Observable<ICart>;
    client: string;
    contact: string;
    IsCorporateClient: boolean;
    ClientRef: string;
    cart_subscriber: any;
    submitEnquiryMessage: string;
    constructor(private store: Store<any>, private _customerService: CustomerService, private _systemService: SystemService)
    {
        if (this.cart_subscriber) {
            this.cart_subscriber.unsubscribe();
        }
        //this.store.dispatch(new CompleteBooking());
    }
    ngOnDestroy() {
        if (this.cart_subscriber) {
            this.cart_subscriber.unsubscribe();
        }
        //this.store.dispatch(new CompleteBooking());
    }
    ngOnInit() {
        this.GetConfigurationSettings();
        this.cart$ = this.store.select('cart');        
    }

    GetConfigurationSettings() {
        let result = this._systemService.loadConfiguration();
        result.subscribe(data => {
            if (data != null) {
                this.submitEnquiryMessage = data.SubmitEnquiryConfig.Message;                
            }
        })
    }

}
