﻿/*
facility search view model 
*/
import { FacilitySummary } from './facility-summary'

export class FacilitySerachResult {

    facilities: FacilitySummary[];
    totalFacilities: number;
    itemList: number[]


}

