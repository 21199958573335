﻿import { CartActionTypes, CartActions } from "./actions";
import { ICart } from "./cart";

import { Contact } from '../models/client/contact';
import { Booking } from '../models/booking/booking';
import { environment } from 'src/environments/environment'
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { PackageFilter } from '../models/booking/common';

//set default values
let defaultContact = new Contact();
defaultContact.firstName = "";
defaultContact.profilePicture = "";

export let initialState: ICart =
{
    contact: defaultContact,
    booking: new Booking(),
    bespokePackages: [],
    defaultPackage: null,
    addedFacilites: [],
    bookingPackage: null,
    packageFilter: null,
    AddedDocuments: []
};

export function cartReducer(state = initialState, action: CartActions) {
    switch (action.type) {
        case CartActionTypes.REDIRECT:
        case CartActionTypes.PACKAGE_SEARCH:
        case CartActionTypes.POST_BOOKING:
        case CartActionTypes.DO_BOOKING:
            return state;

        case CartActionTypes.SET_CONTACT:
            return { ...state, contact: action.payload };
        case CartActionTypes.SIGNOUT:
            return { ...state, contact: new Contact() };



        case CartActionTypes.ADD_DOCUMENTS:
            var ad = state.AddedDocuments == undefined ? [] : state.AddedDocuments;

            var exsitingFile = ad.find(o => o == action.payload);
            if (exsitingFile == undefined) {
                ad.push(action.payload);
            }
            return { ...state, AddedDocuments: ad };

        case CartActionTypes.DELETE_DOCUMENTS:

            var ad = state.AddedDocuments == undefined ? [] : state.AddedDocuments;
            let indexnum = ad.findIndex(x => x == action.payload);
            if (indexnum != undefined) {
                ad.splice(indexnum, 1);
            }

            if (ad.length == 0) {
                ad = [];
            }
            return { ...state, AddedDocuments: ad };


        case CartActionTypes.ADD_FACILITY:
            var fc = state.addedFacilites == undefined ? [] : state.addedFacilites;
            if (state.addedFacilites.length == 0) {
                let bk = new Booking();
            }
            var exsitingItem = fc.find(o => o.id == action.payload.id && o.startTime == action.payload.startTime && o.endTime == action.payload.endTime);
            if (exsitingItem == undefined) {
                action.payload.index = fc.length > 0 ? (Math.max.apply(Math, fc.map(function (o) { return o.index; }))) + 1 : 0;
                fc.push(action.payload);
            }
            if (state.addedFacilites.length == 0) {
                let bk = new Booking();
                return { ...state, addedFacilites: fc, booking: bk };
            }
            return { ...state, addedFacilites: fc };
        case CartActionTypes.DELETE_FACILITY:
            var fc = state.addedFacilites == undefined ? [] : state.addedFacilites;
            let index = fc.findIndex(x => x.index == action.payload);
            if (index != undefined) {
                fc.splice(index, 1);
            }
            return { ...state, addedFacilites: fc };
        case CartActionTypes.UPDATE_FACILITY:
            var fc = state.addedFacilites == undefined ? [] : state.addedFacilites;
            let obj = fc.find(x => x.index == action.payload.index);
            if (obj != undefined) {

            }
            return { ...state, addedFacilites: fc };

        case CartActionTypes.UPDATE_FACILITIES:
            return { ...state, addedFacilites: action.payload };

        case CartActionTypes.UPDATE_PACKAGES:
            var defP = action.payload.find(p =>
                p.businessAreaId == environment.DefaultBussinessAreaId &&
                p.packageClassId == environment.DefaultPackageClassId);

            var packageFilter = new PackageFilter();
            packageFilter.BussinessAreaId = environment.DefaultBussinessAreaId;
            packageFilter.PackageClass = environment.DefaultPackageClassId;
            var pkf = (state.packageFilter == null) ? packageFilter : state.packageFilter;
            return { ...state, bespokePackages: action.payload, defaultPackage: defP, bookingPackage: defP, packageFilter: pkf };

        case CartActionTypes.ERROR_LOG:
            console.log(action.payload)
            return state;

        case CartActionTypes.UPDATE_CART:
            return { ...state, booking: action.payload };
        case CartActionTypes.COMPLETE_BOOKING:
            let bk = new Booking();
            bk.bookingReference = state.booking.bookingReference;
            return { ...state, booking: bk, addedFacilites: [], packageFilter: null, AddedDocuments: [] };


        case CartActionTypes.UPDATE_BOOKING_EVENT_NAME:
            var pkf = (state.packageFilter == null) ? (new PackageFilter()) : state.packageFilter;
            pkf.EventName = action.payload;
            return { ...state, packageFilter: pkf };

        case CartActionTypes.UPDATE_BOOKING_PAKAGE:
            var ErrMsgs = [];
            var defBPs = state.bespokePackages.filter(p =>
                p.businessAreaId == action.payload.BussinessAreaId &&
                p.packageClassId == action.payload.PackageClass);
            var defBP = state.bookingPackage;
            var facilites = state.addedFacilites == undefined ? [] : state.addedFacilites;

            if (defBPs != undefined && defBPs.length > 0) {
                defBP = null;
                defBPs.forEach(function (obj) {
                    if (defBP == null && obj.salesCategories != undefined)
                        defBP = obj;
                    if (defBP.salesCategories != undefined && obj.salesCategories != undefined && obj.salesCategories.length > defBP.salesCategories.length) {
                        defBP = obj;
                    }
                });
                if (facilites.length > 0) {
                    facilites.forEach(function (fc) {
                        fc.package = null;
                        fc.item.salesCategories.forEach((item, i) => {
                            if (fc.package == null) {
                                if (defBP.salesCategories.includes(item.id)) {
                                    fc.package = defBP;
                                }
                            }
                        });
                        if (fc.package == null) {
                            fc.item.salesCategories.forEach((item, i) => {
                                if (fc.package == null) {
                                    defBPs.forEach((bespokePackage, i) => {
                                        if (bespokePackage.salesCategories.includes(item.id)) {
                                            fc.package = bespokePackage;
                                        }
                                    });
                                }
                            });
                        }
                        if (fc.package == null) {
                            ErrMsgs.push(fc.name + ", doesn't have bespoke package with the Selected criteria");
                        }
                    });
                }
            } else {
                ErrMsgs.push("Bespoke Packages not there for selected criteria");
            }
            if (ErrMsgs.length > 0) {
                action.payload.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ErrMsgs,
                });
            }
            var packageFilter = new PackageFilter();
            packageFilter.BussinessAreaId = action.payload.BussinessAreaId;
            packageFilter.PackageClass = action.payload.PackageClass
            packageFilter.EventName = action.payload.EventName
            return { ...state, bookingPackage: defBP, addedFacilites: facilites, packageFilter: packageFilter };
        default:
            return state;
    }
}