﻿import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SystemService } from 'src/modules/services/system.service';

@Injectable()
export class SystemConfig {

    constructor(public _systemService: SystemService) {

    }
    async loadAppConfiguration() {
    
        if (!environment.ConfigurationLoaded) {
            return await this._systemService.loadConfiguration().toPromise().then            
                (res => {
                    console.log('loadAppConfiguration');
                    console.log(res);
                    environment.SalesChannel = res.BookingConfig.SalesChannel;
                    environment.Currency = res.BookingConfig.Currency;
                    environment.BookingStatus = res.BookingConfig.BookingStatus;
                    environment.BookingExpireStatus = res.BookingConfig.BookingExpireStatus;
                    

                    environment.PriceDisplayOption = res.BookingConfig.PriceDiaplayOption;
                    environment.PriceMinConsessionId = res.BookingConfig.PriceMinConsessionId;
                    environment.PriceMaxConsessionId = res.BookingConfig.PriceMaxConsessionId;
                    environment.DefaultBussinessAreaId = res.BookingConfig.DefaultBussinessAreaId;
                    environment.DefaultPackageClassId = res.BookingConfig.DefaultPackageClassId;
                    environment.PriceTypeId = res.BookingConfig.PriceTypeId;

                    environment.BookingEntityConfigurationProfileId = res.BookingConfig.BookingEntityConfigurationProfileId.toString();
                    environment.PackageEntityConfigurationProfileId = res.BookingConfig.PackageEntityConfigurationProfileId.toString();
                    environment.ItemEntityConfigurationProfileId = res.BookingConfig.ItemEntityConfigurationProfileId.toString();

                    environment.GoogleAPIKEY = res.BookingConfig.GoogleAPIKEY;
                    environment.ConfigurationLoaded = true;
                    environment.PackageClasses = res.BookingConfig.PackageClases;
                    environment.MinimumBookingDuration = res.BookingConfig.MinimumBookingDuration;
                    environment.BookingSearchDescriptionTextLimitHigh = res.BookingConfig.BookingSearchDescriptionTextLimitHigh
                    environment.BookingSearchDescriptionTextLimitMedium = res.BookingConfig.BookingSearchDescriptionTextLimitMedium
                    environment.BookingSearchDescriptionTextLimitLow = res.BookingConfig.BookingSearchDescriptionTextLimitLow
                    environment.IsDisableInputsInPayment = res.IsDisableInputsInPayment
                    environment.PrivacyPolicyLink = res.ClientRegistrationConfig.PrivacyPolicyLink;
                    environment.TermsandConditionLink = res.BookingConfig.TermsandConditionLink;
                    environment.ReCaptchaSiteKey = res.ClientRegistrationConfig.ReCaptchaSiteKey;
                    environment.DefaultCurrencyId = res.ClientRegistrationConfig.Currency.Id
                });
        }
    }
}