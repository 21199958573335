import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
  selector: 'opt-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {

    }

  ngOnInit() {
  }
    close() { }
}
