﻿import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptFormModules } from 'src/modules/shared/form.modules';
import { OptCommonModules } from 'src/modules/shared/common.modules';

import { CorporateComponent } from './setup/corporate/corporate.component';
import { IndividualComponent } from './setup/individual/individual.component';
import { LoginComponent } from './login/login.component';
import { NgxCaptchaModule } from 'ngx-captcha';
@NgModule({
    imports: [
        CommonModule, OptFormModules, OptCommonModules, NgxCaptchaModule
     
    ],
    exports: [        
        CommonModule,
        CorporateComponent,
        IndividualComponent,
        LoginComponent       
    ],
    declarations: [
        CorporateComponent,
        IndividualComponent,
        LoginComponent    
    ],
})
export class CusModules { }
