﻿import { BaseParam } from '../base-param';
import { environment } from 'src/environments/environment';
export class Assetsearchparams extends BaseParam {

    searchpharese: string;
    starttime: string;
    endtime: string;
    pagenumber: number;
    pagesize: number;
    filterattendens: string;
    filterfacilites: string;
    filtersuberbs: string;
    sort: string;
    assetclass: number = 1;
    activeStatus: number = 1;
    salesChannelId: number = environment.SalesChannel;
    assetname: string = "";
    duration: number;
    timelable: string;

    geturlforsearch() {
        //if (this.timelable == "Any" && this.searchpharese === undefined) {
        //    return "page.number=" + this.pagenumber + "&page.size=" + this.pagesize +
        //        "&StartTime=" + this.starttime +
        //        "&EndTime=" + this.endtime +
        //        "&capacityFrom=" + this.filterattendens +
        //       // "&capacityTo=" + this.filterattendens +
        //        "&sort=" + this.sort +
        //        "&assetClassId=" + this.assetclass +
        //        "&activeStatus=" + this.activeStatus +
        //        "&duration=" + this.duration +
        //        (this.assetname.length > 0 ? ("&assetName=" + this.assetname) : "") +
        //        "&saleschannelId=" + this.salesChannelId +
        //        "&include=venue,Facility.Amenities,Facility.configurations";
        //}
        //else
            if (this.searchpharese === undefined) {
            return "page.number=" + this.pagenumber + "&page.size=" + this.pagesize +
                "&StartTime=" + this.starttime +
                "&EndTime=" + this.endtime +
                "&capacityFrom=" + this.filterattendens +
              //  "&capacityTo=" + this.filterattendens +
                "&sort=" + this.sort +
                "&assetClassId=" + this.assetclass +
                "&activeStatus=" + this.activeStatus +
                (this.timelable == "Any" ? ("&duration=" + this.duration):"" )+
                (this.assetname.length > 0 ? ("&assetName=" + this.assetname):"") +
                "&saleschannelId=" + this.salesChannelId +
                "&include=venue,Facility.Amenities,Facility.configurations";

        } else
        {
            return "page.number=" + this.pagenumber +
                "&page.size=" + this.pagesize +
                "&SearchText=" + this.searchpharese +
                "&StartTime=" + this.starttime +
                "&EndTime=" + this.endtime +
                "&capacityFrom=" + this.filterattendens +
               // "&capacityTo=" + this.filterattendens +
                "&sort=" + this.sort +   
                (this.assetname.length > 0 ? ("&assetName=" + this.assetname) : "") +             
                "&assetClassId=" + this.assetclass +
                "&activeStatus=" + this.activeStatus +
                ( this.timelable == "Any" ? ("&duration=" + this.duration) : "" )+
                "&saleschannelId=" + this.salesChannelId+
                "&include=venue,Facility.Amenities,Facility.configurations";
        }
        
    }

}