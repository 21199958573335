import { Component, OnInit, EventEmitter, Output, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { RoutePath } from '../../route-path';

import { SystemService } from '../../../services/system.service';
import { EventCategory } from './../../../models/event/event-category';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

import { Booking } from './../../../models/booking/booking';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document'
import { DomSanitizer } from '@angular/platform-browser';


import { Store, ICart, UpdateFacilities, DeleteFacility, UpdateBookingPackage, UpdateBookingEventName, AddDocument, DeleteDocument } from '../../../store/index';
import { Observable } from "rxjs";
import { FacilitySummary } from 'src/modules/models/asset/facility-summary'

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EditModalComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/edit-modal.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { AssetService } from 'src/modules/services/asset.service';
import { ReferenceData } from 'src/modules/models/reference-data'
import { environment } from 'src/environments/environment';
import { SystemConfig } from 'src/modules/system-configuration'
import { PackageFilter } from 'src/modules/models/booking/common'
import { DocModalComponent } from 'src/modules/booking-portal/booking-cart/doc-modal/doc-modal.component'
import { MatSnackBar } from '@angular/material';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';


@Component({
    selector: 'opt-mmc-cart',
    templateUrl: './mmc-cart.component.html',
    //styleUrls: ['./mmc-cart.component.scss']
})
export class MmcCartComponent implements OnInit, OnDestroy {
    @ViewChild('fileInputTag') fileInputTagVariable: ElementRef;    status: boolean = false;
    value: boolean = false;
    clickEvent() {
        this.status = !this.status;
        this.value = false;
    }
    clickEvent2() {
        this.value = !this.value;
    }
    responsive() {
        //this.status = !this.status;
    }
    protected aFormGroup: FormGroup;

    @Output() navigated: EventEmitter<number> = new EventEmitter<number>();
    cart$: Observable<ICart>;
    cart_subscriber: any;
    businessAreas: ReferenceData[];
    selectedBusinessArea: string;
    booking = new Booking;
    facilitySummaries: FacilitySummary[] = [];
    packageClasses = [];
    public files: UploadFile[] = [];
    public fileSelector: ElementRef;
    selectedClass: number;
    defaultEventName: string;
    IsBussinessAreaLoaded: boolean = false;
    selectedFiles: AdHocDocument[] = [];
    getImages = true;
    constructor(private cd: ChangeDetectorRef,private router: Router, private _systemService: SystemService, private store: Store<any>, public dialog: MatDialog, private _sysConfig: SystemConfig, private _assetService: AssetService, public snackBar: MatSnackBar, private sanitizer: DomSanitizer, private formBuilder: FormBuilder) {
        this.cart$ = this.store.select('cart');
        this.booking = new Booking();
    }
    ngOnDestroy() {
        this.cart_subscriber.unsubscribe();
    }

    updateMyTextModel(v) {      
        this.aFormGroup.controls.EventName.markAsTouched();
        this.snackBar.openFromComponent(AlertMessageComponent, {
            duration: 3000,
            verticalPosition: 'top',
            data: ["Your request could not be processed. Event name is mandatory.  Please enter the event name."],
        });
    }
    ngOnInit() {
        this.aFormGroup = this.formBuilder.group({
            EventName: ['', Validators.required],          
        });
        this._sysConfig.loadAppConfiguration().then(() => {
            this.packageClasses = environment.PackageClasses;
            //this.selectedBusinessArea = String( environment.DefaultBussinessAreaId);

            //if (environment.PackageClasses != undefined && environment.PackageClasses.length > 0) {
            //    this.selectedClass = environment.PackageClasses[0].Id;
            //}

            this.bindEventCategory();
            this.cart_subscriber =
                this.cart$.subscribe(state => {
                    this.booking = state.booking;
                    this.selectedFiles = state.AddedDocuments != undefined ? state.AddedDocuments : [];
                    this.facilitySummaries = state.addedFacilites;
                    if (state.packageFilter != undefined && state.packageFilter != null && state.packageFilter.BussinessAreaId > 0 && state.packageFilter.PackageClass > 0) {
                        this.selectedBusinessArea = state.packageFilter.BussinessAreaId.toString();
                        this.selectedClass = state.packageFilter.PackageClass;
                        this.defaultEventName = state.packageFilter.EventName;
                    } else {
                        this.selectedBusinessArea = environment.DefaultBussinessAreaId.toString();
                        this.selectedClass = environment.DefaultPackageClassId;
                        if (state.packageFilter != undefined && state.packageFilter != null) {
                            this.defaultEventName = state.packageFilter.EventName;
                        }
                    }

                    if (this.getImages) {
                        if (this.facilitySummaries != undefined && this.facilitySummaries.length > 0) {
                            var res = this._assetService.getVenueRoomsImagesForCart(this.facilitySummaries).toPromise();
                            if (res != null) {
                                res.then(data => {
                                    this.facilitySummaries = data;
                                });
                            }
                        }
                        this.getImages = false;
                    }
                });
        });
    }

    eventNameChange(val) {
        if (val != undefined && val != null) {
            this.store.dispatch(new UpdateBookingEventName(val));
        }
    }
    packageSelectorChange() {
        let filter = new PackageFilter();
        filter.BussinessAreaId = +this.selectedBusinessArea;
        filter.PackageClass = this.selectedClass;
        filter.snackBar = this.snackBar;
        filter.EventName = this.defaultEventName;
        this.store.dispatch(new UpdateBookingPackage(filter));
    }

    bindEventCategory() {
        let result = this._systemService.loadBusinessAreas();
        result.subscribe(data => {
            this.businessAreas = data;
            this.cart_subscriber =
                this.cart$.subscribe(state => {
                    if (!this.IsBussinessAreaLoaded) {

                        let bA = state.bespokePackages.map(b => b.businessAreaId.toString());
                        let DeleteableBA = []
                        this.businessAreas.forEach((fc, i) => {
                            if (bA.indexOf(fc.Id) == -1) {
                                DeleteableBA.push(fc);
                            }
                        });
                        if (DeleteableBA.length > 0) {
                            DeleteableBA.forEach((fc, i) => {
                                let index = this.businessAreas.findIndex(b => b.Id == fc.Id);
                                this.businessAreas.splice(index, 1);
                            });
                        }
                        this.IsBussinessAreaLoaded = true;
                    }
                });
        },
            err => {
            },
            () => {
            },
        );
    }
    clickSubmitEnquiry(val) {
        this.navigated.emit(val);
    }

    facilitySearch() {
        this.router.navigate([RoutePath.Facility]);
    }

    // File upload function  start


    public dropped(event: UploadEvent) {
        this.files = event.files;
        let snack = this.snackBar;
        for (const droppedFile of event.files) {

            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    if (this.checkFileExtesion(file.name)) {

                        if (file.size < 3000000) {

                            var myReader: FileReader = new FileReader();
                            myReader.onloadend = (e) => {
                                let aHD = new AdHocDocument();
                                //  aHD.id = "-1";
                                aHD.name = file.name;
                                aHD.fileSize = this.bytesToSize(file.size);
                                let stringdata = myReader.result.toString().split(',');
                                aHD.documentFile = stringdata[stringdata.length - 1];
                                aHD.file = file;
                                aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                                this.store.dispatch(new AddDocument(aHD));
                            };
                            myReader.readAsDataURL(file);
                        }
                        else {
                            snack.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: ["Please upload a file less than 3MB"],
                            });
                        }
                    }
                    else {
                        snack.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: ["You have uploaded an invalid document file type"],
                        });
                    }
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            }
        }
    }

    onFileChanged(event) {
        if (this.checkFileExtesion(event.target.files[0].name)) {
            if (event.target.files[0].size < 3000000) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    let aHD = new AdHocDocument();
                    aHD.file = event.target.files[0];
                    aHD.name = event.target.files[0].name;
                    aHD.fileSize = this.bytesToSize(event.target.files[0].size);
                    let stringdata = myReader.result.toString().split(',');
                    aHD.documentFile = stringdata[stringdata.length - 1];
                    aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                    this.store.dispatch(new AddDocument(aHD));
                    this.fileInputTagVariable.nativeElement.value = "";
                };
                myReader.readAsDataURL(event.target.files[0]);
            }
            else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Please upload a file less than 3MB"],
                });
            }
        }
        else {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["You have uploaded an invalid document file type"],
            });
        }
    }

    public fileOver(event) {
        console.log(event);
    }

    public fileLeave(event) {
        console.log(event);
    }

    removeFile(file: AdHocDocument) {
        this.store.dispatch(new DeleteDocument(file));
    }

    bytesToSize(bytes) {
        if (bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return 'n/a';
            var i = +(Math.floor(Math.log(bytes) / Math.log(1024)));
            if (i == 0) return bytes + ' ' + sizes[i];
            return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
        }
    }

    // file upload function end

    public openEditPopup(val: FacilitySummary) {

        let dialogRef =this.dialog.open(EditModalComponent, {
            data: { facility: val },
            panelClass: ['custom-dialog-container', 'w60modal'],
            height: 'auto',
            width: '60%'
        });

        dialogRef.afterClosed().subscribe(result => {
            this.cd.detectChanges();
        });

    }

    get64Stringdata(file: AdHocDocument) {
        if (file && file.file && file.file.size) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file.file));
        }
        else {
            return null;
        }
        // return this.sanitizer.bypassSecurityTrustResourceUrl(file.documentFileOriginal + file.documentFile);
        //console.log(file.documentFileOriginal + file.documentFile);
        //return ();

    }
    checkFileExtesion(name: string) {
        let supportTypes = ["doc", "docx", "pdf", "txt", "xls", "xlsx", "jpeg","jpg", "png","bmp"];
        let fileExtensionArray = name.split('.');
        let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
        return supportTypes.includes(fileExtension.toLowerCase())
    }
    get64String(file: File) {

        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
            let data = myReader.result.toString();
            return data;                       
        }
        myReader.readAsDataURL(file);

    }

    deleteItem(obj: FacilitySummary) {
        let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
            data: {
                message: ' Are you sure you need to delete this venue?',
                yesText: 'YES',
                noText: 'NO'
            },
            panelClass: ['custom-dialog-container', 'w30modal'],
            height: 'auto',
            width: '30%'
            // DialogPosition : top

        });
        const sub = dialogRef.componentInstance.onAdd.subscribe((data: any) => {
            if (data == true) {
                this.store.dispatch(new DeleteFacility(obj.index));
            }
        });
    }

    DocModelOpen() {
        this.dialog.open(DocModalComponent, {
            panelClass: ['custom-dialog-container', 'w60modal'],
            height: 'auto',
            width: '60%'
        });
    }
    ChangeStatus(state) {
        this.value = state;
        this.status = false;
    }

}
