import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Layout
import { LayoutModule, LayoutComponent } from '../layout/layout';

//Component
import { FacilitySearchComponent} from './facility/facility-search/facility-search.component';
import { FacilityDetailComponent } from './facility/facility-detail/facility-detail.component';
import { ModalWindowComponent } from './test-component/modal-window/modal-window.component';
import { BookingCartComponent } from './booking-cart/booking-cart.component';
import { MmcLoaderComponent } from '../shared/mmc-loader/mmc-loader.component';
import { AlertMessageComponent } from '../shared/alert-message/alert-message.component';
import { ToastMessageComponent } from '../shared/toast-message/toast-message.component';
import { NotFoundComponent } from '../shared/not-found/not-found.component';
import { ComingSoonComponent } from '../shared/coming-soon/coming-soon.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',               
        redirectTo: 'facility'
    },
    {
        path: 'facility',                
        component: LayoutComponent,
        children: [
            { path: '', component: FacilitySearchComponent, pathMatch: 'full' },
        ]
    },
    {
        path: '404',
        component: ComingSoonComponent,
        
    },
    {
        path: 'facility-detail',
        component: LayoutComponent,
        children: [
            { path: '', component: FacilityDetailComponent, pathMatch: 'full' },
        ]
    },
    {
        path: 'booking-cart',
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: BookingCartComponent,
              
            },
                      
        ]
    },
    {
        path: 'login',
        outlet: 'client',
        loadChildren: 'src/modules/booking-portal/client/client.module#ClientModule'
    },
    {
        path: 'setup',
        outlet: 'client',
        loadChildren: 'src/modules/booking-portal/client/client.module#ClientModule#setup'
    },
    {
        path: 'modal',
        children: [
            { path: '', component: ModalWindowComponent, pathMatch: 'full' },
        ]
    },
    {
        path: 'loader',
        children: [
            { path: '', component: MmcLoaderComponent, pathMatch: 'full' },
        ]
    },
    {
        path: 'alert',
        children: [
            { path: '', component: ToastMessageComponent, pathMatch: 'full' },
        ]
    },
    {
        path: 'client',        
        loadChildren: 'src/modules/booking-portal/client/client.module#ClientModule'

    },
    {
        path: 'payment',
        loadChildren: 'src/modules/payment-portal/payment.module#PaymentModule'
      
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true//,
        //enableTracing: true
    }),
              LayoutModule],
    exports: [RouterModule]
})
export class BookingRoutingModule { }
