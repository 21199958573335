﻿import { BaseResource } from '../base-resource';
import { PaymentTerm } from './payment-term';
import { BookingStatus } from './booking-status';
import { BookingPackages } from './booking-packages';
import { BookingClient } from './booking-client';
import { BookingContact } from './booking-contact';
import { PriceConcession } from './price-group';
import { jsonIgnoreReplacer, jsonIgnore } from 'json-ignore';
import { environment } from 'src/environments/environment';

export class Booking extends BaseResource {

    constructor() {
        super();        
        this.type = "booking";
        this.confirmed = false;       
        this.salesChannelId = environment.SalesChannel;           
    }
    bookingReference: string;
    attendees: number;
    //bookingDate: string;
    //confirmedDate: string;
    cancelled: boolean;
    confirmed: boolean;
    margin: number;
    netAmount: number;
    note: string;
    poReference: string;
    salesChannelId: number;
    bookingPackages: BookingPackages[];   
    @jsonIgnore() entityConfigurationProfileId: number;
   // client: BookingClient
    contact: BookingContact
    priceConcession: PriceConcession;
    eventName: string;
    temporary: boolean;
    // eventName: string;


    // optional  Param 
    //paymentTerm: PaymentTerm;
     bookingStatus: BookingStatus;
    //bookingSourceId: number;
    //bookingReasonId: number;
    //actualCost: number;
    //agentBooking: boolean;
    //waitlistBooking: boolean;
    //VersionNo: number;
    //EntityConfigurationProfileId: number;
    //currencyExchangeRate: number;
    //deliveryCharge: number;
    //discountAmount: number;
    //dueAmount: number;
    //estimatedCost: number;
    //exported: boolean;
    //grossAmount: number;
    //invoicedAmount: number;
    //paidAmount: number;
    //paymentInProgress: boolean;
    //paymentOnCollection: boolean;
    //taxAmount: number;
    //temporary: boolean;
}
