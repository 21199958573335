﻿import { enableProdMode } from '@angular/core'
import { environment } from '../environments/environment';
import { SystemConfig } from 'src/modules/system-configuration';
import { SystemService } from 'src/modules/services/system.service';
import { BaseService } from 'src/modules/services/base/base.service';
import { HttpClient, HttpHandler, HTTP_INTERCEPTORS, HttpRequest, HttpEvent, HttpInterceptor, HttpXhrBackend} from '@angular/common/http';
import { Injector } from "@angular/core";
import { OptHttpProvider ,APIRequestHandler} from 'src/modules/services/base/api-request-handler';
import { Observable, throwError } from 'rxjs';


//export let InjectorInstance: Injector;

//export class OptModule {
//    constructor(public injector: SystemService) {
//        InjectorInstance = this.injector;
//    }
//}

export function Init() {
        // enableProdMode();
        // if (environment.ApiUrl.length == 0)
     
            if (window["ApiUrl"])
                environment.ApiUrl = window["ApiUrl"];
            if (window["WebAPIBaseUrl"])
                environment.WebApiUrl = window["WebAPIBaseUrl"];
            if (window["Token"])
                environment.Token = window["Token"];
   
        const injector = Injector.create({
            providers: [
                { provide: HttpClient, deps: [HttpHandler] },
                { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest }) },
            ],
        });
        const httpClient: HttpClient = injector.get(HttpClient);
         var _sysConfig = new SystemConfig(new SystemService(new BaseService(httpClient)));
          return _sysConfig.loadAppConfiguration();

        //if (window["PackageEntityConfigurationProfileId"])
        //    environment.PackageEntityConfigurationProfileId = window["PackageEntityConfigurationProfileId"];
        //if (window["BookingEntityConfigurationProfileId"])
        //    environment.BookingEntityConfigurationProfileId = window["BookingEntityConfigurationProfileId"];
        //if (window["ItemEntityConfigurationProfileId"])
        //    environment.ItemEntityConfigurationProfileId = window["ItemEntityConfigurationProfileId"];
        //if (window["GoogleAPIKEY"])
        //    environment.GoogleAPIKEY = window["GoogleAPIKEY"];
        //if (window["BookingStatus"])
        //    environment.BookingStatus = window["BookingStatus"];

        //console.log("set environment variable api url" + environment.ApiUrl);
    
    
}
