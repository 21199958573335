﻿export class Utility
{
    
    static convertToISO(dt: Date) {       
        //2018-04-23T10:26:00
        var month = (dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1);

        return dt.getFullYear() + '-' + this.generateTwoDigitNo(dt.getMonth() + 1) + '-' + this.generateTwoDigitNo(dt.getDate()) + 'T' + this.generateTwoDigitNo(dt.getHours()) + ':' + this.generateTwoDigitNo(dt.getMinutes()) + ':' + this.generateTwoDigitNo(dt.getSeconds());
    }

    static convertISOToDate(dt: any) {
        if (!(dt instanceof Date)) {
            if (dt != undefined) {
                var arr = dt.split(/[-T:]/);
                return new Date(+arr[0], (+arr[1]) - 1, +arr[2], +arr[3], +arr[4], +arr[5]);
            } else {
                return undefined;
            }
        } else {
            return dt; 
        }
        
        
    }

    private static generateTwoDigitNo(no: number): string {
        return no < 10 ? ('0' + no) : no.toString().substring(0, 2);
    }

     static GetClosestHalfHour (d) {
        d.setMilliseconds(0);
        d.setSeconds(0);
        var minutes = d.getMinutes();
        var neededMin;
        if (minutes < 30) {
            neededMin = 30 - minutes;
        } else {
            neededMin = 60 - minutes;
        }
        d.setMinutes(d.getMinutes() + neededMin)
        return d;
    }

    static setMinTime (dt) {
        var min = dt.getMinutes();
        dt.setMinutes(min + 1);
        return this.GetClosestHalfHour(dt);
    }

    static convertToDate(dt: Date) {
        //2018-04-23T10:26:00
        var month = (dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1);

        return dt.getFullYear() + '-' + this.generateTwoDigitNo(dt.getMonth() + 1) + '-' + this.generateTwoDigitNo(dt.getDate());
    }

   static changeDatePart(currentDt: Date, newDt: Date) {
        var hr = currentDt.getHours();
        var min = currentDt.getMinutes();
        currentDt = newDt;
        currentDt.setHours(hr);
        currentDt.setMinutes(min);
        currentDt.setSeconds(0);
        return currentDt;
    }

    static roundToMinDuration(dt: Date, MinimumBookingDuration:number,isEndTime = false, isStartTime = false): any {
        var isDateChange: Boolean = false;
        dt.setSeconds(0);
        var hr = dt.getHours();
        var min = dt.getMinutes();
        if (hr == 23 && min == 59) {
        }
        else {
            var totMin = hr * 60 + min
            var mxMin = 23 * 60 + 59;

            var minBookingDuration = MinimumBookingDuration;
            if (mxMin >= totMin) {

                if ((min % MinimumBookingDuration) > 0) {
                    var roundMin = (parseInt((min / minBookingDuration).toString()) + 1) * minBookingDuration;
                    dt.setMinutes(roundMin);
                    isDateChange = true;
                    //if (isEndTime)
                    //    this.EndTime = moment(dt).toDate();
                    //if (isStartTime)
                    //    this.StartTime = moment(dt).toDate();
                }

            }
        }

        return {
            newDate: dt, isDateChanged: isDateChange
        };
    }

    static urlHistorty: string[] = [];

    static sortParams(link) {
        link = decodeURIComponent(link);
        let queryParams = link.split('?')[1];
        let params
        if (queryParams)
            params = queryParams.split('&');

        let pair = null;
        let data = {};
        if (params && params.length > 0) {
            params.forEach((d) => {
                pair = d.split('=');
                data[`${pair[0]}`] = pair[1];
            });
        }
        console.log(data)
        return data;
    }
}