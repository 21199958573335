import { Component, Inject, OnInit, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ModalContentComponent } from "../modal-content/modal-content.component";
import { CalenderTimeslot } from 'src/modules/models/asset/timeslots'

@Component({
  selector: 'opt-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  //styleUrls: ['./calendar-modal.component.scss']
})
export class CalendarModalComponent implements OnInit {
    //@Input() 
    slots: CalenderTimeslot[];
    availableSlots: CalenderTimeslot[];
    isPopup: boolean;
    @Input() inputSlots: CalenderTimeslot[];
    @Input() inputAvailableSlots: CalenderTimeslot[];
    constructor(
        public matDialogRef: MatDialogRef<ModalContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.slots = (data.slots != undefined) ? data.slots:[];
        this.availableSlots = (data.availableSlots != undefined) ? data.availableSlots : [];
        this.isPopup = (data.availableSlots != undefined) ? true : false;
    }

    public close() {
        this.matDialogRef.close();
    }
    
    ngOnInit() {
        //this.slots = [
        //    {
        //        start: 2.25,
        //        end: 3.75,
        //    },
        //    {
        //        start: 13.00,
        //        end: 15.00,
        //    },
        //    {
        //        start: 18.00,
        //        end: 19.75,
        //    },
        //]
    }
    ngOnChanges(changes: SimpleChanges) {
        const dataInputSlots: SimpleChange = changes.inputSlots;
        const dataInputAvailableSlots: SimpleChange = changes.inputAvailableSlots;
        if (dataInputSlots.currentValue != undefined) {
            this.slots = dataInputSlots.currentValue as CalenderTimeslot[];           
        }
        if (dataInputAvailableSlots.currentValue != undefined) {
            this.availableSlots = dataInputAvailableSlots.currentValue as CalenderTimeslot[];
        }       
    }
}
