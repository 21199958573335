import { Injectable } from '@angular/core';

import { BaseService } from './base/base.service';

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';

import { BaseParam } from '../models/base-param';
import { Item } from '../models/item/item'
import { ItemSearchParams } from '../models/item/item-search-params'


@Injectable({
  providedIn: 'root'
})
export class ItemService {

    constructor(private _baseService: BaseService) {

    }

    itemSearch(baseParam: ItemSearchParams): Observable<Item []> {
        return this._baseService.WebAPIGet("api/v4.1/products/items?" + baseParam.geturlforsearch()).pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                console.log("Deserialized web api output item");
                console.log(json);
                console.log("Convert to object");
                var lst: Item[] = [];
                json.forEach((c, i) => {
                    lst.push((c as Item));
                });
                return lst
            })
        );
    }    
}
