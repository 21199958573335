﻿import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, filter, scan, switchMap, catchError, withLatestFrom } from "rxjs/operators";


import { Store, ICart, AppState, PostBooking, AddFacility, UpdateCart, LogError, UpdatePackages,  PatchBookingContact, CompleteBooking, Redirect } from 'src/modules/store/index'
import { BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'
import { CartActionTypes } from 'src/modules/store/actions'

import { BookingService } from 'src/modules/services/booking.service'
import { PackageService } from 'src/modules/services/package.service'
import { Booking } from 'src/modules/models/booking/booking'
import { BookingPackages } from 'src/modules/models/booking/booking-packages';
import { BespokePackage } from 'src/modules/models/asset/bespoke-package'
import { Allocations } from 'src/modules/models/booking/allocations'
import { BookingItems } from 'src/modules/models/booking/booking-items'
import { BookingFacility } from 'src/modules/models/asset/booking-facility'
import { FacilitySummary } from 'src/modules/models/asset/facility-summary'
import { RoutePath } from 'src/modules/booking-portal/route-path'
import { ErrorState } from 'src/modules/models/error/error'
import { environment } from 'src/environments/environment';
import { Utility } from 'src/modules/utility';
import { BookingStatus } from 'src/modules/models/booking/booking-status'
import { BookingClient } from 'src/modules/models/booking/booking-client';
import { BookingContact } from 'src/modules/models/booking/booking-contact';
import { PriceConcession } from 'src/modules/models/booking/price-group'
import { MatSnackBar } from '@angular/material';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { Contact } from 'src/modules/models/client/contact';
import { SystemConfig } from 'src/modules/system-configuration'
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document';


@Injectable()
export class FacilityEffects {

    constructor(private actions$: Actions,
        private store: Store<ICart>,
        private bookingService: BookingService,
        private packageService: PackageService,
        private router: Router,
        public snackBar: MatSnackBar,
        private _sysConfig: SystemConfig,
    ) {
    }


    @Effect({ dispatch: false })
    addFacility$ = this.actions$.pipe(
        ofType(CartActionTypes.ADD_FACILITY),
        map((cart) => {
            console.log('ADD_FACILITY');
            console.log(cart);
            this.store.dispatch(new BookingSaveCompleted());
            this.router.navigate([RoutePath.BookingCart])
        })

    );

    @Effect({ dispatch: false })
    doBooking$ = this.actions$.pipe(
        ofType(CartActionTypes.DO_BOOKING),
        withLatestFrom(this.store.source),
        map(([cart, storeState]) => {
            var store = storeState as AppState;
            var cartData = store.cart;

            if ((cartData.contact.client != undefined && cartData.contact.client != null)) {
                var bk = new Booking();
                bk.bookingStatus = new BookingStatus();
                bk.bookingStatus.id = String(environment.BookingStatus);
                bk.salesChannelId = environment.SalesChannel;
                bk.eventName = cartData.packageFilter.EventName;
                this.store.dispatch(new BookingSaveStarted());
                this.store.dispatch(new PostBooking(bk));
            } else {
                this.store.dispatch(new Redirect("CART-LOGIN"));
            }
        })

    );

    @Effect({})
    postBooking$ = this.actions$.pipe(
        ofType('POST_BOOKING'),
        withLatestFrom(this.store.source),
        switchMap(([action, storeState]) => {
            //generate booking structure from BookingFacility
            console.log('booked facility')
            var bk = (action as PostBooking).payload as Booking;
            var store = storeState as AppState;
            var cart = store.cart;

            bk.id = "0";
            bk.attendees = 1;
            bk.cancelled = false;
            bk.netAmount = 0;
            bk.margin = 0;
            bk.bookingPackages = [];

          

            if (cart.contact.client != undefined) {
                bk.contact = new BookingContact();
                bk.contact.id = cart.contact.id;
                bk.confirmed = true;
                bk.temporary = false;
            } else {
                bk.confirmed = false;
                bk.temporary = true;
            }
            let tid = 1;
            cart.addedFacilites.forEach((fc, i) => {

                if (fc.package != null && fc.package != undefined) {
                    // bk.attendees = (bk.attendees + fc.attendees);
                    let isNewPackage = false;
                    let bkpackages = new BookingPackages();
                    bkpackages.attendees = 1;
                    if (bk.bookingPackages.length > 0) {
                        bkpackages = bk.bookingPackages.find(p => p.package.id == fc.package.id) as BookingPackages;
                        if (bkpackages == undefined || bkpackages == null) {
                            isNewPackage = true;
                        }
                    }
                    else {
                        isNewPackage = true;
                    }

                    if (isNewPackage) {
                        bkpackages.package.id = fc.package.id;
                        bkpackages.name = bk.eventName;
                        bkpackages.package.name = fc.package.name;
                        bkpackages.priceTypeId = fc.package.priceTypeId;
                        bkpackages.event.id = fc.package.eventId.toString();
                        bkpackages.quantity = "1";
                        bkpackages.taxRate = 0;
                        bkpackages.margin = 0;
                        bkpackages.attendees = 1;
                        bkpackages.singleDayPackage = false;
                        bkpackages.id = ((bk.bookingPackages.length + 1) * (-1)).toString();
                        bkpackages.bookingItems = [];
                    }
                    bk.attendees = (fc.attendees > bk.attendees) ? fc.attendees : bk.attendees;
                    bkpackages.attendees = (fc.attendees > bkpackages.attendees) ? fc.attendees : bkpackages.attendees;

                    var allocations = new Allocations();
                    allocations.attendees = fc.attendees;
                    allocations.timeslot.asset.id = fc.id;
                    allocations.timeslot.startTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                    allocations.timeslot.endTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                    allocations.timeslot.eventConfiguration.id = fc.configuration.id.toString();

                    // populate BookingItems
                    var bookingItem = new BookingItems();
                    bookingItem.quantity = 1;
                    bookingItem.item.id = fc.item.id;
                    bookingItem.startTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                    bookingItem.endTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                    bookingItem.upsell = true;
                    bookingItem.name = fc.item.name;

                    // populate suitable package 

                    if (fc.itemPrice != undefined && !(isNaN(fc.itemPrice.concessionId))) {
                        bk.priceConcession = new PriceConcession();
                        bk.priceConcession.id = fc.itemPrice.concessionId.toString();
                        bookingItem.priceTypeId = fc.itemPrice.priceTypeId;
                    }

                    // populate BookingPackages

                    let packageStartTime = null;
                    let packageEndTime = null;


                    if (isNewPackage) {
                        packageStartTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                        packageEndTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                    }
                    else {
                        if (Utility.convertISOToDate(fc.startTime) < Utility.convertISOToDate(bkpackages.startDate)) {
                            packageStartTime = Utility.convertToISO(Utility.convertISOToDate(fc.startTime));
                        }
                        else {
                            packageStartTime = Utility.convertToISO(Utility.convertISOToDate(bkpackages.startDate));
                        }

                        if (Utility.convertISOToDate(fc.endTime) > Utility.convertISOToDate(bkpackages.endDate)) {
                            packageEndTime = Utility.convertToISO(Utility.convertISOToDate(fc.endTime));
                        }
                        else {
                            packageEndTime = Utility.convertToISO(Utility.convertISOToDate(bkpackages.endDate));
                        }
                    }

                    bkpackages.startDate = packageStartTime;
                    bkpackages.endDate = packageEndTime;


                    allocations.id = (tid * (-1)).toString();
                    allocations.timeslot.id = (tid * (-1)).toString();
                    bookingItem.id = (tid * (-1)).toString();
                    bookingItem.allocations = allocations;

                    // populate Booking

                    bkpackages.bookingItems.push(bookingItem);

                    tid++;
                    if (isNewPackage) {
                        bk.bookingPackages.push(bkpackages);
                    }
                }
            });
            var bkService = this.bookingService;
            let bookingResult = this.bookingService.BookingSave(bk);
            return bookingResult.pipe(
                map((bookingdata) => {
                    let booking = (bookingdata as Booking);
                    if (cart.AddedDocuments != undefined && cart.AddedDocuments.length > 0) {
                        let DocumentCount = cart.AddedDocuments.length;
                        let UloadedDocumentCount = 0;
                        cart.AddedDocuments.forEach(function (file) {
                            let documentResult = bkService.BookingDocumentsSave(booking.id, file);
                            documentResult.subscribe(data => {
                            },
                                err => {
                                    console.log("Error", err);
                                },
                                () => {
                                    //UloadedDocumentCount++
                                    //if (UloadedDocumentCount == DocumentCount) {
                                    //    this.store.dispatch(new BookingSaveCompleted());
                                    //    return { type: CartActionTypes.UPDATE_CART, payload: booking }
                                    //}
                                },
                            );


                        });
                    }

                    this.store.dispatch(new BookingSaveCompleted());
                    return { type: CartActionTypes.UPDATE_CART, payload: booking }



                })
                , catchError((error) => {
                    this.store.dispatch(new BookingSaveCompleted());
                    let errorData = [];
                    if (error.status == 500) {
                        if (error.statusText)
                            errorData.push(error.statusText);
                        if (error.Detail)
                            errorData.push(error.Detail);
                    }

                    if (error.error != undefined && error.error.errors != null && error.error.errors.length > 0) {
                        error.error.errors.forEach((error, i) => {
                            if (error.Detail)
                                errorData.push(error.Detail);
                        });
                    }

                    console.log(errorData);
                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: errorData,
                    });
                    return of(new LogError(error));
                })
            );
        }));



    // navigate user to cart page
    @Effect({ dispatch: false })
    updateCartSuccess$ = this.actions$.pipe(
        ofType(CartActionTypes.UPDATE_CART),
        withLatestFrom(this.store.source),
        map(([cart, storeState]) => {
            var store = storeState as AppState;
            var cartData = store.cart;
            console.log('UPDATE_CART');

            if ((cartData.contact.client != undefined)) {
                if (cartData.booking.bookingStatus.id == environment.BookingExpireStatus) {

                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: ["Your Booking Enquiry session has expired. Please try again."],
                    });
                    this.store.dispatch(new CompleteBooking());
                    this.store.dispatch(new Redirect("FACILITY"));
                } else {
                    this.store.dispatch(new CompleteBooking());
                    this.store.dispatch(new Redirect("CART-COMPLETE"));
                }
            }
            else {
                this.store.dispatch(new Redirect("CART-LOGIN"));
            }
        })
    );

    @Effect({})
    patchBookingContact$ = this.actions$.pipe(
        ofType('PATCH_BOOKING_CONTACT'),
        withLatestFrom(this.store.source),
        switchMap(([action, storeState]) => {

            var store = storeState as AppState;
            var cart = store.cart;
            var bk = new Booking();
            bk.id = cart.booking.id;
            bk.salesChannelId = environment.SalesChannel;
            if (cart.contact.client != undefined) {
                bk.contact = new BookingContact();
                bk.contact.id = cart.contact.id;
                bk.confirmed = true;
                bk.temporary = false;
            }
            bk.entityConfigurationProfileId = +environment.BookingEntityConfigurationProfileId;
            let bookingResult = this.bookingService.BookingPatch(bk.id, bk);
            return bookingResult.pipe(
                map((bookingdata) => {
                    let booking = (bookingdata as Booking);
                    this.store.dispatch(new BookingSaveCompleted());
                    return { type: CartActionTypes.UPDATE_CART, payload: booking }
                })
                , catchError((error) => {
                    this.store.dispatch(new BookingSaveCompleted());
                    let errorData = [];
                    if (error.status == 500) {
                        if (error.statusText)
                            errorData.push(error.statusText);
                        if (error.Detail)
                            errorData.push(error.Detail);
                    }

                    if (error.error != undefined && error.error.errors != null && error.error.errors.length > 0) {
                        error.error.errors.forEach((error, i) => {
                            if (error.Detail)
                                errorData.push(error.Detail);
                        });
                    }

                    console.log(errorData);
                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: errorData,
                    });
                    return of(new LogError(error));
                })
            );
        }));


    //navigate user to cart page
    @Effect({ dispatch: false })
    facilityAddSuccess$ = this.actions$.pipe(
        ofType(CartActionTypes.UPDATE_CART),
        map((cart) => {
            console.log('UPDATE_CART');
            console.log(cart);
            this.store.dispatch(new BookingSaveCompleted());
            this.router.navigate([RoutePath.BookingCart])
        })

    );

    @Effect()
    packageSearch$ = this.actions$.pipe(
        ofType(CartActionTypes.PACKAGE_SEARCH),
        withLatestFrom(this.store),
        switchMap((state) => {
            console.log('PACKAGE_SEARCH');
            console.log(state);

            var cart = state[1] as ICart;
            //parameter todo
            //ex:event dates

            if (cart.bespokePackages == undefined || cart.bespokePackages.length == 0) {
                return this.packageService.privatePackages()
                    .pipe(
                        map((res) => {
                            console.log(res);
                            return { type: CartActionTypes.UPDATE_PACKAGES, payload: res }
                            //return of(new UpdatePackages(res));
                        })
                        , catchError((error) => {
                            var er = new ErrorState();
                            er.action = 'PACKAGE_SEARCH: packageSearch$ ';
                            er.error = error;

                            return of(new LogError(er));
                        })
                    );
            }
        })

    );
}