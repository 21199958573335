import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BookingPackages } from 'src/modules/models/booking/booking-packages';
import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import {
    Store, ICart, UpdateCart, UpdateFacilities,
    CompleteBooking, Redirect, DeleteFacility, PostBooking, DoBooking
} from 'src/modules/store/index';
import { Observable } from "rxjs";
import { Booking } from 'src/modules/models/booking/booking';
import { BookingClient } from 'src/modules/models/booking/booking-client';
import { BookingContact } from 'src/modules/models/booking/booking-contact';
import { environment } from 'src/environments/environment';
import { Utility } from 'src/modules/utility';
import { BookingService } from 'src/modules/services/booking.service';
import { ILoadingStatus } from 'src/modules/store/loading/status-store'
import { BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'
import { BookingStatus } from 'src/modules/models/booking/booking-status'
import { Allocations } from 'src/modules/models/booking/allocations'
import { BookingItems } from 'src/modules/models/booking/booking-items'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EditModalComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/edit-modal.component';
import { SystemConfig } from 'src/modules/system-configuration'
import { MatSnackBar } from '@angular/material';
import { PriceConcession } from 'src/modules/models/booking/price-group'
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { DecimalPipe } from '@angular/common'

@Component({
    selector: 'opt-cart-panel',
    templateUrl: './cart-panel.component.html',
    //styleUrls: ['./cart-panel.component.scss']
})
export class CartPanelComponent implements OnInit, OnDestroy {
    value: boolean = false;    
    @Input() facilitySummaries: FacilitySummary[];
    @Input() defaultEventName: string;
    @Output() submitted: EventEmitter<number> = new EventEmitter<number>();
    @Output() makeEventNameValidation: EventEmitter<number> = new EventEmitter<number>();
    @Output() ChangedStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
    cart$: Observable<ICart>;
    cart_subscriber: any;
    isAgreed: boolean = false;
    isUpdated: boolean = false;
    isSaved: boolean = false;
    url: string
    constructor(private store: Store<any>, private _bookingService: BookingService, public dialog: MatDialog, private _sysConfig: SystemConfig, public snackBar: MatSnackBar) {
        this.cart$ = this.store.select('cart');

    }
    ngOnDestroy() {
        if (this.cart_subscriber)
            this.cart_subscriber.unsubscribe();
    }
    ngOnInit() {
        this.url = environment.TermsandConditionLink;
    }

    IsValidFacilitySummaries(facilitySummaries: FacilitySummary[]) {
        let isvalid = (facilitySummaries != undefined) && (facilitySummaries.length > 0);
        let isAttendeesValid = true;
        if (isvalid) {
            facilitySummaries.forEach(function (item) {
                if (isvalid) {
                    isvalid = (item.package != undefined && item.package != null);
                }
                if (isAttendeesValid) {
                    isAttendeesValid = (item.attendees != undefined && !isNaN(item.attendees)  && item.attendees!=0)
                }
            });
        }
        return {
            isvalid: isvalid, isAttendeesValid: isAttendeesValid
        };
    }
    public openEditPopup(val: FacilitySummary) {

        this.dialog.open(EditModalComponent, {
            data: { facility: val },
            panelClass: ['custom-dialog-container', 'w60modal'],
            height: 'auto',
            width: '60%'
            // DialogPosition : top
        });
    }

    clickEvent2() {
        //this.value = !this.value;
        this.ChangedStatus.emit(true);
    }

    deleteItem(obj: FacilitySummary) {
        let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
            data: {
                message: ' Are you sure you need to delete this venue?',
                yesText: 'YES',
                noText: 'NO'
            },
            panelClass: ['custom-dialog-container', 'w30modal'],
            height: 'auto',
            width: '30%'
        });
        const sub = dialogRef.componentInstance.onAdd.subscribe((data: any) => {
            if (data == true) {
                this.store.dispatch(new DeleteFacility(obj.index));
            }
        });
    }

    clickSubmitEnquiry() {
        let ValidFacilitySummary = this.IsValidFacilitySummaries(this.facilitySummaries);
        if (this.isAgreed && ValidFacilitySummary.isvalid) {            
            if (ValidFacilitySummary.isAttendeesValid) {
                if (this.defaultEventName != null && this.defaultEventName != "" && this.defaultEventName != undefined) {

                    this._sysConfig.loadAppConfiguration().then(() => {
                        //var bk = new Booking();
                        //bk.bookingStatus = new BookingStatus();
                        //bk.bookingStatus.id = String(environment.BookingStatus);
                        //bk.salesChannelId = environment.SalesChannel;
                        //bk.eventName = this.defaultEventName;
                        //this.store.dispatch(new BookingSaveStarted());
                        //this.store.dispatch(new PostBooking(bk));
                        this.store.dispatch(new DoBooking());
                    });
                } else {
                    this.makeEventNameValidation.emit(1);
                }
            } else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Please specify the number of attendees"],
                });
            }
        }
    }

   

    isPriceRange(minPrice, maxPrice) {
        var min = minPrice != undefined ? minPrice!.priceIncludingTax : 0;
        var max = maxPrice != undefined ? maxPrice!.priceIncludingTax : 0;
       // return min != max;
        var dp: DecimalPipe = new DecimalPipe('en');
        return dp.transform(min, '1.2-2') != dp.transform(max, '1.2-2');
    }

    

  
    signUp() {
        this.store.dispatch(new Redirect("SIGNUPFROMBOOKINGDETAIL"));
    }

    Login() {
        this.store.dispatch(new Redirect("DirectLoginFromBooking"));
    }    
}
