import { Component, OnInit, ViewChild, Output, EventEmitter, ViewChildren, QueryList, ElementRef, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormBuilder, AbstractControl } from '@angular/forms';
import { CustomerService } from '../../../../services/customer.service';
import { SystemService } from '../../../../services/system.service';
import { QASIntegrationService } from '../../../../services/qas-integration.service';
import { Deserializer } from 'json-api-format';
import { CorporateClient } from './../../../../models/client/corporate-client';
import { ReferenceData } from './../../../../models/client/referenceData';
import { Contact } from './../../../../models/client/Contact';
import { CommunicationMethod } from './../../../../models/client/client';
import { Client, Address, User, Venue, Tax } from './../../../../models/client/client';
import { QASAddress } from './../../../../models/client/qas-address';
import { ClientRegistration } from './../../../../models/client/client-registration-config';
import { Addresses, AddressDetail } from './../../../../models/client/Address';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutePath } from '.././../../../../modules/booking-portal/route-path';
import { Store, ICart, SetContact, Redirect, PatchBookingContact, DoBooking } from './../../../../store/index';
import { MatSnackBar } from '@angular/material';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'

import { Observable } from "rxjs";
import { map, startWith, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment'
import { Utility } from 'src/modules/utility';
@Component({
    selector: 'opt-corporate',
    templateUrl: './corporate.component.html',
    //styleUrls: ['./corporate.component.scss']
})
export class CorporateComponent implements OnInit, AfterViewInit {
    @Output() navigated: EventEmitter<number> = new EventEmitter<number>();
    @Output() LoggedIn: EventEmitter<number> = new EventEmitter<number>();
    @Output() loadSignUp: EventEmitter<number> = new EventEmitter<number>();
    @Input() isFromBooking: string;
    @Input() tabIndex: number;
    clientSaveForm: FormGroup;
    //passwordFormGroup: FormGroup;

    //public base64Files: string[] = [];
    //private files: any[] = [];
    private fileReader = new FileReader();
    Image: string;
    ImageEx: string;

    Email: string;
    SelectedTitleId: string;
    SelectedTitleName: string;
    SelectedPhoneTypeId: string;
    SelectedPhoneTypeName: string;
    SelectedEmailTypeId: string;
    SelectedEmailTypeName: string;
    SelectedAdditionalPhoneTypeId: string;
    SelectedAdditionalPhoneTypeName: string;
    SelectedAdditionalEmailTypeId: string;
    SelectedAdditionalEmailTypeName: string;
    //SelectedComTypeId: string;
    //SelectedComTypeName: string;
    addressSearchText: string;
    IsEnableManualAddress: boolean;
    IsFromLogin: boolean;
    CurrentUrl: string;
    PasswordVal: string;

    Address_Country: string;
    DefaultCountryId: number;
    Address_Line1: string;
    Address_Line2: string;
    Address_Line3: string;
    Address_City: string;
    Address_StateCode: string;
    Address_PostCode: string;

    QASLoader: boolean;

    Client: CorporateClient;
    Contact: Contact;
    Titles: Array<ReferenceData>;
    CommunicationTypes: Array<ReferenceData>;
    CommunicationTypesPhone: Array<ReferenceData>;
    CommunicationTypesEmail: Array<ReferenceData>;
    CustomerRegConfig = new ClientRegistration();
    AddressList: Addresses;
    filteredOptions: AddressDetail[];
    TempAdd: Array<string>;

    acceptedImageTypes = { 'image/png': true, 'image/jpeg': true, 'image/gif': true };
    @ViewChild('imageDrop') imageDrop;
    @ViewChild('imageError') imageError
    @ViewChild('imageInput') el;
    url: string;
    isChecked = false;
    siteKey: string;
    size: string;
    lang: string;
    theme: string;
    type: string;
    recaptcha;
    isCaptchaVerified = false;
    isLoad = false;
    defaultCurrencyId: number;

    loadingStyle: boolean;
    showImage = true;
    showError = false;
    addressLoaded = false;
    emailPattern  = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    constructor(private _customerService: CustomerService, private _systemService: SystemService, private _qasIntegrationService: QASIntegrationService, private fb: FormBuilder, private route: Router, private store: Store<any>, public snackBar: MatSnackBar) {
        this.SelectedTitleId = '';
        this.SelectedTitleName = '';
        this.Client = new CorporateClient();
        this.Titles = new Array<ReferenceData>();
        this.CommunicationTypes = new Array<ReferenceData>();
        this.CommunicationTypesPhone = new Array<ReferenceData>();
        this.CommunicationTypesEmail = new Array<ReferenceData>();
        this.CustomerRegConfig = new ClientRegistration();
        this.addressSearchText = '';
        this.AddressList = new Addresses();
        this.IsEnableManualAddress = true;
        this.Contact = new Contact();
        this.Contact.client = new Client();
        this.Image = '';
        this.ImageEx = '';
        this.DefaultCountryId = 0;
        this.defaultCurrencyId = environment.DefaultCurrencyId;
        this.siteKey = environment.ReCaptchaSiteKey;
        this.size = 'Normal';
        this.lang = 'en';
        this.theme = 'Light';
        this.type = 'Image';
        this.recaptcha = 'recaptcha'
    }



    ngOnInit() {
        if ((this.route.url).includes('%3F')) {
            this.CurrentUrl = ((this.route.url).split('%3F')[1]).split('%3D')[0];
            this.IsFromLogin = (((this.route.url).split('%3F')[1]).split('%3D')[1] == '1') ? true : false;
        }

        this.QASLoader = false;

        this.clientSaveForm = this.fb.group({
            CompanyName: ['', Validators.maxLength(50)],
            FirstName: ['', Validators.maxLength(50)],
            LastName: ['', Validators.maxLength(50)],
            CompanyPhoneNumber: [''],
            Password: ['', [Validators.minLength(5), ConfPasswordValidator]],
            ConfirmPassword: ['', [PasswordValidator]],
            CompanyEmail: ['', Validators.pattern(this.emailPattern)],
            RegistrationName: [''],
            PersonalPhoneNumber_1: [''],
            PersonalEmail_1: ['', Validators.pattern(this.emailPattern)],
            PersonalPhoneNumber_2: [''],
            PersonalEmail_2: ['', Validators.pattern(this.emailPattern)],
            Address_Country: [''],
            Address_Line1: [''],
            Address_Line2: [''],
            Address_Line3: [''],
            Address_City: [''],
            Address_StateCode: [''],
            Address_PostCode: [''],
            //ImageInput: [''],
            myControl: [''],
            recaptcha: ['', Validators.required],
        });
        this.GetConfigurationSettings();
        this.GetDropDownDetails();

        this.clientSaveForm.controls.myControl.valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), mergeMap(input =>
            this.GetQASAddress(false, '', input)
        ))
            .subscribe(data => {
                if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {
                    this.AddressList = data;
                }
                else {
                    this.AddressList = new Addresses();
                }
                this.QASLoader = false;
            });
        this.url = environment.PrivacyPolicyLink;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.isLoad = true;
        });
    }

    GetFormattedAddress(address: string) {
        let selectedAdd = this.AddressList.AddressDetails.find(f => f.Address == address);
        let postCode = selectedAdd.Postcode;
        this.GetQASAddress(true, selectedAdd.QASID, '')
            .subscribe(data => {
                if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {
                    this.Address_Country = data.AddressDetails[0].Country;
                    this.Address_Line1 = data.AddressDetails[0].AddressLine1;
                    this.Address_Line2 = (data.AddressDetails[0].AddressLine2 != null) ? data.AddressDetails[0].AddressLine2 : ' ';
                    this.Address_Line3 = (data.AddressDetails[0].AddressLine3 != null) ? data.AddressDetails[0].AddressLine3 : ' ';
                    this.Address_City = data.AddressDetails[0].Locality;
                    this.Address_StateCode = data.AddressDetails[0].StateCode;
                    this.Address_PostCode = postCode;
                    this.addressLoaded = true;
                    this.loadingStyle = true;
                }

            });
        this.AddressList = new Addresses();
        this.QASLoader = false;
    }//Format selected address

    checkfiles(files) {
        if (this.acceptedImageTypes[files[0].type] !== true) {
            this.imageError.nativeElement.innerHTML = "Not a valid image";
            this.imageDrop.nativeElement.style.display = 'none';
            this.imageError.nativeElement.style.display = 'block';
            this.Image = null;
            return;
        }
        else if (files.length > 1) {
            this.imageError.nativeElement.innerHTML = "Please choose 'One' Image";
            this.imageDrop.nativeElement.style.display = 'none';
            this.imageError.nativeElement.style.display = 'block';
            this.Image = null;
            return;
        }
        else {
            this.imageError.nativeElement.style.display = 'none';
            this.imageDrop.nativeElement.style.display = 'block';
            this.readfiles(files);
        }
    }//checkfiles

    readfiles(files) {
        this.fileReader.readAsDataURL(files[0]);
        this.ImageEx = files[0].type;
        setTimeout(() => this.Image = this.fileReader.result.toString(), 500);

    }//readfiles

    imageUploadSubmitted() {
        console.log('IMAGE VALUE SUBMIT = =  ', this.clientSaveForm.controls.imageInput.value);
    }

    allowDrop(e) {
        e.preventDefault();
    }

    drop(e) {
        //e.preventDefault();
        //this.clientSaveForm.controls.ImageInput.reset();
        this.imageDrop.innerHTML = "";

        this.checkfiles(e.dataTransfer.files);
    }

    imageChange(event) {
        this.imageDrop.innerHTML = "";
        this.checkfiles(event.target.files);
    }//imageChange    

    GetConfigurationSettings() {
        let result = this._systemService.loadConfiguration();
        result.subscribe(data => {
            if (data != null) {
                this.CustomerRegConfig = data.ClientRegistrationConfig;
                this.DefaultCountryId = data.ClientRegistrationConfig.DefaultCountryId;
                this.IsEnableManualAddress = ((data.ClientRegistrationConfig.EnableManualAddress).toLowerCase() == "false") ? false : true;
                (this.IsEnableManualAddress) ? '' : this.DisableAddressFromField();
            }
        })
    }
    DisableAddressFromField() {
        this.clientSaveForm.controls['Address_Country'].disable();
        this.clientSaveForm.controls['Address_Line1'].disable();
        this.clientSaveForm.controls['Address_Line2'].disable();
        this.clientSaveForm.controls['Address_Line3'].disable();
        this.clientSaveForm.controls['Address_StateCode'].disable();
        this.clientSaveForm.controls['Address_City'].disable();
        this.clientSaveForm.controls['Address_PostCode'].disable();
    }

    SaveCorporateClient() {

        this.Client.type = "corporateclient"
        this.Client.tid = "1";
        this.Client.companyName = this.clientSaveForm.controls.CompanyName.value;
        this.Client.clientCategoryId = this.CustomerRegConfig.CorporateClientConfig.ClientCategoryConfig.Id;
        this.Client.clientCategory = this.CustomerRegConfig.CorporateClientConfig.ClientCategoryConfig.Name;
        this.Client.clientTypeId = this.CustomerRegConfig.CorporateClientConfig.ClientTypeConfig.Id;
        this.Client.clientType = this.CustomerRegConfig.CorporateClientConfig.ClientTypeConfig.Name;
        this.Client.companyRegistrationNo = this.clientSaveForm.controls.RegistrationName.value;
        this.Client.legalName = this.clientSaveForm.controls.CompanyName.value;
        this.Client.active = true;
        this.Client.dateRegistered = new Date();
        this.Client.headOffice = true;
        this.Client.defaultCurrencyId = this.defaultCurrencyId;

        let accManager = new User();
        accManager.type = "user";
        accManager.id = (this.CustomerRegConfig.AccountManager.Id).toString();
        accManager.userName = this.CustomerRegConfig.AccountManager.Name;

        this.Client.accountManager = accManager;
        this.Client.creditLimit = 0;

        let defVenue = new Venue();
        defVenue.type = "venue";
        defVenue.id = (this.CustomerRegConfig.Venue.Id).toString();
        defVenue.name = this.CustomerRegConfig.Venue.Name;

        this.Client.defaultVenue = defVenue

        this.Client.tax = new Tax();
        //this.Client.tax.tid = "1";
        this.Client.tax.id = (this.CustomerRegConfig.Tax.Id).toString();
        this.Client.tax.name = this.CustomerRegConfig.Tax.Name;
        this.Client.profilePicture = (this.Image != null && this.Image != "") ? (this.Image.split(',')[1]) + '.' + this.ImageEx.split('/')[1] : null;

        let addt = new Address();
        addt.tid = "1";
        addt.country = this.clientSaveForm.controls.Address_Country.value;
        addt.countryID = this.DefaultCountryId;
        addt.address1 = this.clientSaveForm.controls.Address_Line1.value;
        addt.address2 = this.clientSaveForm.controls.Address_Line2.value;
        addt.address3 = this.clientSaveForm.controls.Address_Line3.value;
        addt.city = this.clientSaveForm.controls.Address_City.value;
        addt.stateCode = this.clientSaveForm.controls.Address_StateCode.value;
        addt.postCode = this.clientSaveForm.controls.Address_PostCode.value;

        this.Client.address = addt;

        this.Client.contacts = new Array<Contact>();
        let contact = new Contact();
        contact.tid = "1";
        contact.firstName = this.clientSaveForm.controls.FirstName.value;
        contact.lastName = this.clientSaveForm.controls.LastName.value;
        contact.titleId = +this.SelectedTitleId;
        contact.title = this.SelectedTitleName;
        contact.accountManager = accManager;
        contact.defaultVenue = defVenue;
        contact.decisionMaker = false;
        contact.birthYear = 0;
        contact.birthMonth = 0;
        contact.birthDay = 0;
        contact.anniversaryYear = 0;
        contact.anniversaryMonth = 0;
        contact.anniversaryDay = 0;
        contact.active = true;
        contact.password = this.clientSaveForm.controls.Password.value;
        contact.userName = this.clientSaveForm.controls.PersonalEmail_1.value;
        contact.profilePicture = (this.Image != null && this.Image != "") ? (this.Image.split(',')[1]) + '.' + this.ImageEx.split('/')[1] : null;
        contact.address = addt;

        contact.communicationMethods = new Array<CommunicationMethod>();
        if (this.clientSaveForm.controls.PersonalPhoneNumber_1.value != null && this.clientSaveForm.controls.PersonalPhoneNumber_1.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "3";
            comMethod.communicationTypeID = this.SelectedPhoneTypeId;
            comMethod.communicationType = this.SelectedPhoneTypeName;
            comMethod.value = this.clientSaveForm.controls.PersonalPhoneNumber_1.value;
            contact.communicationMethods.push(comMethod);
        }
        if (this.clientSaveForm.controls.PersonalEmail_1.value != null && this.clientSaveForm.controls.PersonalEmail_1.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "4";
            comMethod.communicationTypeID = this.SelectedEmailTypeId;
            comMethod.communicationType = this.SelectedEmailTypeName;
            comMethod.value = this.clientSaveForm.controls.PersonalEmail_1.value;
            contact.communicationMethods.push(comMethod);
        }
        if (this.clientSaveForm.controls.PersonalPhoneNumber_2.value != null && this.clientSaveForm.controls.PersonalPhoneNumber_2.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "5";
            comMethod.communicationTypeID = this.SelectedAdditionalPhoneTypeId;
            comMethod.communicationType = this.SelectedAdditionalPhoneTypeName;
            comMethod.value = this.clientSaveForm.controls.PersonalPhoneNumber_2.value;
            contact.communicationMethods.push(comMethod);
        }
        if (this.clientSaveForm.controls.PersonalEmail_2.value != null && this.clientSaveForm.controls.PersonalEmail_2.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "6";
            comMethod.communicationTypeID = this.SelectedAdditionalEmailTypeId;
            comMethod.communicationType = this.SelectedAdditionalEmailTypeName;
            comMethod.value = this.clientSaveForm.controls.PersonalEmail_2.value;
            contact.communicationMethods.push(comMethod);
        }

        this.Client.contacts.push(contact);

        this.Client.communicationMethods = new Array<CommunicationMethod>();
        if (this.clientSaveForm.controls.CompanyPhoneNumber.value != null && this.clientSaveForm.controls.CompanyPhoneNumber.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "1";
            comMethod.communicationTypeID = this.CommunicationTypesPhone.find(f => f.id == "1").id;
            comMethod.communicationType = this.CommunicationTypesPhone.find(f => f.id == "1").name;
            comMethod.value = this.clientSaveForm.controls.CompanyPhoneNumber.value;
            this.Client.communicationMethods.push(comMethod);
        }
        if (this.clientSaveForm.controls.CompanyEmail.value != null && this.clientSaveForm.controls.CompanyEmail.value != "") {
            let comMethod = new CommunicationMethod();
            comMethod.tid = "2";
            comMethod.communicationTypeID = this.CommunicationTypesEmail.find(f => f.id == "5").id;
            comMethod.communicationType = this.CommunicationTypesEmail.find(f => f.id == "5").name;
            comMethod.value = this.clientSaveForm.controls.CompanyEmail.value;
            this.Client.communicationMethods.push(comMethod);
        }



        if (this.clientSaveForm.invalid) {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ['Please check the indicated fields.'],
            });
        }
        else if (this.clientSaveForm.controls.CompanyEmail.value == this.clientSaveForm.controls.PersonalEmail_1.value || this.clientSaveForm.controls.CompanyEmail.value == this.clientSaveForm.controls.PersonalEmail_2.value) {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ['Company email and Contact email cannot be the same.'],
            });
        } else if (this.addressLoaded) {
            let clientSaveResult = this._customerService.clientSave(this.Client);
            clientSaveResult.subscribe(data => {
                if (data != null) {
                    if (data.id != null) {
                        // debugger;
                        this.Contact.id = data.contacts[0].id;
                        this.Contact.type = data.type;
                        this.Contact.client.id = data.id;
                        this.Contact.client.clientName = data.clientName;
                        this.Contact.firstName = this.clientSaveForm.controls.FirstName.value;
                        this.Contact.lastName = this.clientSaveForm.controls.LastName.value

                        if (data.profilePicture != undefined)
                            this.Contact.profilePicture = environment.WebApiUrl + data.profilePicture;
                        this.store.dispatch(new SetContact(this.Contact));
                        //this.loadSignUp.emit(0);                
                        //(this.CurrentUrl == 'fromLogin' && this.IsFromLogin) ? this.route.navigate([RoutePath.Login]) : this.LoggedIn.emit(0); 
                        if (this.CurrentUrl == 'fromLogin' && this.IsFromLogin) {
                            if (Utility.urlHistorty.length > 0) {
                                var link = Utility.urlHistorty[Utility.urlHistorty.length - 1]
                                this.route.navigate([`${link.split('?')[0]}`], { queryParams: Utility.sortParams(link) })
                            } else {
                                this.route.navigate([RoutePath.Facility])
                            }
                        } else if (this.CurrentUrl == 'fromBookingDetail') {
                            this.route.navigate([RoutePath.BookingCart])
                        }
                        else {
                           // this.store.dispatch(new BookingSaveStarted());
                            //this.store.dispatch(new PatchBookingContact());
                            this.store.dispatch(new DoBooking());
                           // this.navigated.emit(3);
                        }
                    }
                }
            },
                err => {
                    let errorData = [];
                    let curErr
                    if (err)
                        curErr = err.error

                    if (err.status == 500) {
                        errorData.push(err.statusText);
                    }
                    else if (err.status == 400) {
                        if (curErr) {
                            if (curErr.errors != null && curErr.errors.length > 0) {
                                curErr.errors.forEach((error, i) => {
                                    errorData.push(error.Detail);
                                });
                            }
                        }
                    }
                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: errorData,
                    });
                }

            )
        }

    }



    GetDropDownDetails() {
        let titleResult = this._customerService.getClientTiltles();
        titleResult.subscribe(data => {
            if (data != null && data.errors == null) {
                for (let title of data.data) {
                    let ti = new ReferenceData();
                    ti.id = title.id;
                    ti.name = title.attributes.name;

                    this.Titles.push(ti);
                }
            }
            this.SelectedTitleId = this.Titles[0].id;
            this.SelectedTitleName = this.Titles[0].name;
        },
            err => {

            }
        );
        let communicationTypeResult = this._customerService.getCommunicationTypes();
        communicationTypeResult.subscribe(data => {
            if (data != null && data.errors == null) {
                for (let comType of data.data) {
                    if (comType.attributes.format == 1) {
                        let comPhone = new ReferenceData();
                        comPhone.id = comType.id;
                        comPhone.name = comType.attributes.name;

                        this.CommunicationTypesPhone.push(comPhone); //Get phone com types
                    }
                    else if (comType.attributes.format == 2) {
                        let comEmail = new ReferenceData();
                        comEmail.id = comType.id;
                        comEmail.name = comType.attributes.name;

                        this.CommunicationTypesEmail.push(comEmail); //Get email com types
                    }
                    else {
                        let com = new ReferenceData();
                        com.id = comType.id;
                        com.name = comType.attributes.name;

                        this.CommunicationTypes.push(com); //Get other com types
                    }

                }
            }
            this.SelectedPhoneTypeId = this.CommunicationTypesPhone[0].id;
            this.SelectedPhoneTypeName = this.CommunicationTypesPhone[0].name;
            this.SelectedEmailTypeId = this.CommunicationTypesEmail[0].id;
            this.SelectedEmailTypeName = this.CommunicationTypesEmail[0].name;
            this.SelectedAdditionalPhoneTypeId = this.CommunicationTypesPhone[0].id;
            this.SelectedAdditionalPhoneTypeName = this.CommunicationTypesPhone[0].name;
            this.SelectedAdditionalEmailTypeId = this.CommunicationTypesEmail[0].id;
            this.SelectedAdditionalEmailTypeName = this.CommunicationTypesEmail[0].name;
        },
            err => {

            }
        );
    }

    SelectTitle(selectedTitleId: string) {
        this.SelectedTitleId = selectedTitleId;
        this.SelectedTitleName = this.Titles.find(i => i.id === selectedTitleId).name;
    }

    uploadClick() {
        let ele: HTMLElement = this.el.nativeElement as HTMLElement;
        ele.click();
        //this.el.getNativeElement().click();
    }
    SelectComType(selectedComTypeId: string, basicComType: number, isAdditional: boolean) {
        if (!isAdditional) {
            if (basicComType == 1) {
                this.SelectedPhoneTypeId = selectedComTypeId;
                this.SelectedPhoneTypeName = this.CommunicationTypesPhone.find(i => i.id === selectedComTypeId).name;
            }
            else if (basicComType == 2) {
                this.SelectedEmailTypeId = selectedComTypeId;
                this.SelectedEmailTypeName = this.CommunicationTypesEmail.find(i => i.id === selectedComTypeId).name;
            }
        }
        else {
            if (basicComType == 1) {
                this.SelectedAdditionalPhoneTypeId = selectedComTypeId;
                this.SelectedAdditionalPhoneTypeName = this.CommunicationTypesPhone.find(i => i.id === selectedComTypeId).name;
            }
            else if (basicComType == 2) {
                this.SelectedAdditionalEmailTypeId = selectedComTypeId;
                this.SelectedAdditionalEmailTypeName = this.CommunicationTypesEmail.find(i => i.id === selectedComTypeId).name;
            }
        }

    }

    GetQASAddress(isFormattedAddress: boolean, QASID: string, searchText: string) {
        (searchText == "") ? (this.QASLoader = false, this.AddressList = new Addresses()) : '';
        (!isFormattedAddress && searchText != "") ? (this.QASLoader = true) : (this.QASLoader = false);
        let qasAddressParam = new QASAddress();
        qasAddressParam.isFormattedAddress = isFormattedAddress;
        qasAddressParam.QASId = QASID;
        qasAddressParam.SearchText = searchText;

        return this._customerService.getQASAddress(qasAddressParam);
    }

    //RedirectToBookingDetail() {
    //    this.navigated.emit(2);
    //}
    Redirect(event, id, url) {

        event.preventDefault();
        if (url == 'fromBookingDetail') {
            return this.route.navigate([RoutePath.BookingCart])
        } else if (id == 1)
            return this.navigated.emit(1);
        else
            return this.route.navigate([RoutePath.Facility])

    }

    onChange(event) {
        this.isChecked = !this.isChecked;
    }
    handleSuccess(e) {
        this.isCaptchaVerified = true;
    }

    handleExpire() {
        this.isCaptchaVerified = false;
    }

    handleLoad() {
        console.log('load')
    }
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

        return (invalidCtrl || invalidParent);
    }
}


export function PasswordValidator(control: AbstractControl) {

    if (control !== null && control !== undefined) {
        const confirmPw = control.value;

        const pwConroller = control.root.get('Password');
        if (pwConroller) {
            const pw = pwConroller.value;

            if (pw !== confirmPw) {
                return {
                    isError: true
                }
            }
        }
    }
    return null;
}
export function ConfPasswordValidator(control: AbstractControl) {


    if (control !== null && control !== undefined) {
        const Pw = control.value;

        const confirmConroller = control.root.get('ConfirmPassword');
        if (Pw) {
            const confirmPw = confirmConroller.value;

            if (Pw !== confirmPw && confirmConroller.touched) {
                confirmConroller.setErrors({ 'incorrect': true });
            } else {
                confirmConroller.setErrors({ 'incorrect': null });
                confirmConroller.updateValueAndValidity();
            }
        }
    }
    return null;
}

export function EmailValidator(control: AbstractControl) {


    if (control !== null && control !== undefined) {
        const email = control.value;
        if (email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
            if (re.test(String(email).toLowerCase())) {
                control.setErrors({ 'incorrect': null });
                control.updateValueAndValidity();
                return {
                    isError: false
                }
            } else {
                return {
                    isError: true
                }
            }
        }
        else {
            return {
                isError: true
            }
        }
    }
    else {
        return {
            isError: true
        }
    }
    return null;
}