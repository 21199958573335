import {
    Component, OnInit, ViewChild, Input,
    OnDestroy, Inject, HostListener, AfterViewInit, ViewChildren,
    ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material';
import { PageEvent, MatPaginator } from '@angular/material';
import { environment } from 'src/environments/environment'
import { Observable, forkJoin, from,Subject } from "rxjs";
import { SystemConfig } from 'src/modules/system-configuration'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DOCUMENT } from '@angular/common'; 

//store
import { Store, ICart, AddFacility, PackageSearch } from 'src/modules/store/index';
import { ILoadingStatus } from 'src/modules/store/loading/status-store'
import { FacilitySearchLoading, FacilitySearchLoaded,BookingSaveStarted } from 'src/modules/store/loading/actions'
//service
import { AssetService } from 'src/modules/services/asset.service';
import { PackageService } from 'src/modules/services/package.service';
import { ItemService } from 'src/modules/services/item.service';

import { BaseParam } from 'src/modules/models/base-param';
import { Assetsearchparams } from 'src/modules/models/asset/asset-search-params'
import { ItemSearchParams } from 'src/modules/models/item/item-search-params'

import { FacilitySummary } from 'src/modules/models/asset/facility-summary'
import { BookingFacility } from 'src/modules/models/asset/booking-facility'
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor'
import { CalendarModalComponent } from 'src/modules/booking-portal/common/calendar-modal/calendar-modal.component';
import { CalenderTimeslot, Timeslot } from 'src/modules/models/asset/timeslots'

import {CurrencyFormat } from 'src/modules/currency-format';
import { Utility } from 'src/modules/utility';
import { MomentDateAdapter } from '../../../common/date-time-adapter/moment-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';

import { EditModalComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/edit-modal.component';
import { ScrollTopService } from 'src/modules/services/scroll-top.service';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

import { TimepickerConfig } from 'ngx-bootstrap/timepicker';

//// such override allows to keep some initial values

//export function getTimepickerConfig(): TimepickerConfig {
//    return Object.assign(new TimepickerConfig(), {
//        hourStep: 1,
//        minuteStep: environment.MinimumBookingDuration,
//        showMeridian: true,
//        meridians :['am', 'pm'],
//        readonlyInput: false,
//        //mousewheel: true,
//        showMinutes: true,
//        showSeconds: false
        
//    });
//}



@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
       // { provide: TimepickerConfig, useFactory: getTimepickerConfig }
    ],

})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {

    date = moment()
    cart$: Observable<ICart>;
    loader$: Observable<ILoadingStatus>;
    cart_subscriber: any;
    searchstring: string;
    seletedStartTime: Date;
    seletedEndTime: Date;
    selectAttendees: number=1;
    selectDate= moment() 

    //myTime: Date = new Date();
    ///showMin: boolean = true;
    //showSec: boolean = false;
    //mstep = 30;
   // meridians = ['am', 'pm'];  
    StartTime: Date;
    EndTime: Date;
    minDate: Date = new Date();//for date picker
    minStartDate: Date;
    maxStartDate: Date;
    minEndDate: Date;
    maxEndDate: Date;
    isValidStart: boolean;
    isValidEnd: boolean;

    searchFacilities: FacilitySummary[];
    recordCount: number = 0;   
    pageSize = 5;
    pagenumber = 0;// this is for search   
    pageSizeOptions: number[] = [5, 10, 25, 100];

    isAssending: boolean = true;
    sortingLable: string = "VENUE";
    sortingiconname: string = "mmc icon-sort";
    
    advancedfilterAtenndence: string = "0";
    advanceSelectedFacility: string = "";
    FacilityListForDropdown: any[] = [];
    baseurl: string = environment.WebApiUrl;
    
    
    itemidlist: number[]    
    timelable: string = "Any";
    
    // MatPaginator Output

    //
    @ViewChild('facilityPaginator') paginator: MatPaginator;
    public innerWidth: any;
    textLimit;
    //toggleMinutes(): void {
    //    this.showMin = !this.showMin;
    //}

    //toggleSeconds(): void {
    //    this.showSec = !this.showSec;
    //}

    
    @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
    options: FormGroup;
    Date: Date;
    hideme = [];
    constructor(fb: FormBuilder,
        private dialog: MatDialog,
        private _assetService: AssetService,
        private _packageService: PackageService,
        private store: Store<any>,
        private _sysConfig: SystemConfig,
        private _itemService: ItemService,
        private scrollTopService: ScrollTopService,
        @Inject(DOCUMENT) document,
        private chRef: ChangeDetectorRef,
        public snackBar: MatSnackBar
    )
    {
        this.options = fb.group({
            color: 'primary',
            fontSize: [16, Validators.min(10)],
        });
       
        
        this.cart$ = this.store.select('cart');
        this.loader$ = this.store.select('loader');

        
        this.initializeTime();
        this.store.dispatch(new PackageSearch());
       
        this.loadFacilityList();

        this.onsearch();
       
    }
    ngAfterViewInit(): void {
       
    }
    //time picker section start
    dateChanged()
    {
        var dt = this.date.toDate();
        this.StartTime = Utility.changeDatePart(this.StartTime, this.date.toDate());
        var today = new Date();
        if (!(dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate()))
        {
            this.minStartDate = dt;
        } else {
            if (new Date(this.StartTime) < new Date()) {
                this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
            }
            else {
                let newstartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                newstartTime.setHours(this.StartTime.getHours());
                newstartTime.setMinutes(this.StartTime.getMinutes());
                this.StartTime = newstartTime;
            }
            this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        }
        
        this.maxStartDate = Utility.changeDatePart(new Date(this.maxStartDate), this.date.toDate());
        this.maxStartDate.setMinutes(this.maxStartDate.getMinutes() + 1);
        let newEndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
        newEndTime.setHours(this.EndTime.getHours());
        newEndTime.setMinutes(this.EndTime.getMinutes());

        this.EndTime = newEndTime;
        this.minEndDate = new Date(moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate());
        this.maxEndDate = new Date(this.minEndDate);
        this.maxEndDate.setHours(24, 0, 0);
     
    }
    
    startChange(event) {
        //if (event != null && this.isValidStart)
        //    this.StartTime = event;
    }
    endChange(event) {
        //if (event != null && this.isValidEnd)
        //    this.EndTime = event;
    }
    startTimeChanged() {
        if (this.StartTime != null) {
            if (this.StartTime.getHours() == 23 && this.StartTime.getMinutes() > 30) {
                this.StartTime.setMinutes(30);
                this.StartTime = moment(this.StartTime).toDate();
            }
            var dtObj = Utility.roundToMinDuration(this.StartTime, environment.MinimumBookingDuration);
            if (dtObj.isDateChanged)
                this.StartTime = moment(dtObj.newDate).toDate();

            this.minEndDate = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

            if (moment(this.StartTime) >= moment(this.EndTime))
                this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

            var dt = this.date.toDate();
            var today = new Date();
            if ((dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
                if (this.StartTime < today) {
                    this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                }
            } 
        }
       
    }
    endTimeChanged() {
   
        if (this.EndTime != null) {
            if (this.EndTime.getFullYear() == this.maxEndDate.getFullYear() &&
                this.EndTime.getMonth() == this.maxEndDate.getMonth() &&
                this.EndTime.getDate() == this.maxEndDate.getDate() &&
                this.EndTime.getHours() == this.maxEndDate.getHours() &&
                this.EndTime.getMinutes() == this.maxEndDate.getMinutes()
            ) {
                this.EndTime = moment(this.EndTime).add(-1, 'm').toDate();

            } else {

                var dtObj = Utility.roundToMinDuration(this.EndTime, environment.MinimumBookingDuration, true);
                if (dtObj.isDateChanged)
                    this.EndTime = moment(dtObj.newDate).toDate();

                if (moment(this.StartTime) >= moment(this.EndTime))
                    this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

            }
        }
      
        return this.EndTime;
    }
    initializeTime(): void
    {
        this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        this.maxStartDate = new Date(this.StartTime);
        this.maxStartDate.setHours(24, 0, 0);
        this.maxStartDate = moment(this.maxStartDate).add(environment.MinimumBookingDuration * -1, 'm').toDate();
        if (this.StartTime.getHours() == 23 && this.StartTime.getMinutes() == 30) {
            this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration-1, 'm').toDate();
        } else {
            this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
        }
        this.minEndDate = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
        this.maxEndDate = new Date(this.EndTime);
        this.maxEndDate.setHours(24, 0, 0);
        let dtnow = new Date();
        if (dtnow.getHours() == 23 && dtnow.getMinutes() > 29) {
            dtnow.setDate(dtnow.getDate() + 1);
            this.minDate = dtnow;
            this.date = moment(dtnow);
        }
    }
    //time picker section end
    getFontSize() {
        return Math.max(10, this.options.value.fontSize);
    }

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        if (innerWidth >= 768) {
            this.textLimit = environment.BookingSearchDescriptionTextLimitHigh
        } else if (innerWidth < 768 && innerWidth > 400) {
            this.textLimit = environment.BookingSearchDescriptionTextLimitMedium
        } else {
            this.textLimit = environment.BookingSearchDescriptionTextLimitLow
        }
        
    }
    ngOnDestroy() {
      
        if (this.cart_subscriber)
        this.cart_subscriber.unsubscribe();
    }

    pageclickevent(pageEvent: PageEvent) {
        this.pageSize = pageEvent.pageSize;
        this.pagenumber = pageEvent.pageIndex; 
        this.onsearch()
    }
    onEnter() {
        if (this.searchstring != undefined || this.searchstring !="") {
            this.onsearch() 
        }
    }
    searchClick()
    {
        //if (this.advancedfilterAtenndence == "0" || this.advancedfilterAtenndence == "") {
        //    this.snackBar.openFromComponent(AlertMessageComponent, {
        //        duration: 3000,
        //        verticalPosition: 'top',
        //        data: ["Please specify the number of attendees"]
        //    });
        //} else {
            this.pagenumber = 0;
            this.onsearch()
        //}
         
    }
    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
    onsearch() {
        this.hideme = [];
        let searchParam = new Assetsearchparams();
        searchParam.pagesize = this.pageSize;
        searchParam.searchpharese = this.searchstring;
        searchParam.pagenumber = this.pagenumber+1;      
        searchParam.starttime = this.getDatefomTime(this.StartTime);
        searchParam.endtime = this.getDatefomTime(this.EndTime);
        if (this.timelable == 'Any') {
            let dtS = new Date(this.date.year(), this.date.month(), this.date.date());
            let dtE = new Date(this.date.year(), this.date.month(), this.date.date());
            dtE.setHours(23);
            dtE.setMinutes(59);
            dtE.setSeconds(0); 
            searchParam.starttime = this.getDatefomTime(dtS);
            searchParam.endtime = this.getDatefomTime(dtE);
        }       
        searchParam.duration = environment.MinimumBookingDuration;
        searchParam.filterattendens = this.advancedfilterAtenndence;
        searchParam.assetname = this.advanceSelectedFacility;
        searchParam.timelable = this.timelable;
        if (this.isAssending) {
            searchParam.sort = "Name";
        } else {
            searchParam.sort = "-Name";
        }

        this.seletedStartTime = this.StartTime;
        this.seletedEndTime = this.EndTime;
        this.selectDate = this.date;
        try {

            if (this.advancedfilterAtenndence != "" &&  this.advancedfilterAtenndence != null && this.advancedfilterAtenndence != undefined) {
                this.selectAttendees = parseInt(this.advancedfilterAtenndence);
            }
            else {
                this.selectAttendees = 0;
            }
        } catch (e) {
            this.selectAttendees = 0;
        }

        this.store.dispatch(new FacilitySearchLoading());
        let availablityResult = this._assetService.facilittySearch(searchParam);
        availablityResult.subscribe((data) => {
            /*
            get the responce and assending ling oder by name 
            and set length for pagination and view
            
            */
            // console.log("recived data", data);
            //this.subjectF.next(data.facilities);
            this.searchFacilities = (data.facilities)  
           
            this.recordCount = data.totalFacilities;
               
            this.itemidlist = data.itemList;
        },
            err => {                
                this.recordCount = 0;
                this.searchFacilities =[]
                this.itemidlist=[]
            },
            () => {
                this.scrollTopService.scrollPageTop();
                this.store.dispatch(new FacilitySearchLoaded());     
                this.searchItem();
                this.loadFacilityTimeslots();
                var res = this._assetService.getFacilityThumbnails(this.searchFacilities);
                if (res != null) {
                    var resPromise = res.toPromise();
                    resPromise.then(data => {
                        this.searchFacilities = data;
                    });
                }
            });

    }


    searchItem() {
        if (this.itemidlist.length > 0) {
            let itemsearch = new ItemSearchParams();
            itemsearch.ItemTypeid = 1;

            itemsearch.itemidlist = this.itemidlist;
            itemsearch.FromDate = this.getDatefomTime(this.seletedStartTime);
            itemsearch.Todate = this.getDatefomTime(this.seletedEndTime);

            this._itemService.itemSearch(itemsearch).subscribe((data) => {
                this.cart_subscriber = this.cart$.subscribe(cart => {
                    FacilityAdaptor.PopulateFacilitySummary(this.searchFacilities, data, cart.bookingPackage);
                    
                });


            },
                err => { },
                () => { }
            )

        }
    }

   
    populateItemdDetails() {
    }


    getSerchbaleDatefomTime(selected: Date) {
        /*
        this will combine data picker date anda time form time piker and return Iso date string.
        the this.date refere to moment date object
        */
        let searchabledate = new Date(this.selectDate.year(), this.selectDate.month(), this.selectDate.date());
        searchabledate.setHours(selected.getHours(), selected.getMinutes(), selected.getSeconds());
        //return searchabledate.toISOString();
        return Utility.convertToISO(searchabledate);
    }

    getDatefomTime(selected: Date) {
        /*
        this will combine data picker date anda time form time piker and return Iso date string.
        the this.date refere to moment date object
        */
        let searchabledate = new Date(this.date.year(), this.date.month(), this.date.date());
        searchabledate.setHours(selected.getHours(), selected.getMinutes(), selected.getSeconds());
        //return searchabledate.toISOString();
        return Utility.convertToISO(searchabledate);
    }
    settimelable() {
        var start = moment(this.StartTime.toISOString())
        var end = moment(this.EndTime.toISOString())
       
        this.timelable = start.format('h:mm a') + "-" + end.format('h:mm a')
        
    }
  
    toggleSort()
    {
        
        this.isAssending = !this.isAssending 
        this.sortingiconname = this.isAssending ? "mmc icon-sort" : "mmc icon-sort-1";
        this.pagenumber = 0;
        this.onsearch();   
    }
    onsortAssending() {
        //this.isAssending = true;
       // this.sortingLable = "VENUE";
       // this.sortingiconname = "mmc icon-sort";
        //this.toggleSort();  
    }

    onsortDessendin() {
        //this.isAssending = false;
        //this.sortingLable = "VENUE";
       // this.sortingiconname = "mmc icon-sort-1";
        //this.toggleSort();
        //this.onsearch();     
    }

    advancedfilterSearch() {
        //if (this.advancedfilterAtenndence == "0" || this.advancedfilterAtenndence == "") {
        //    this.snackBar.openFromComponent(AlertMessageComponent, {
        //        duration: 3000,
        //        verticalPosition: 'top',
        //        data: ["Please specify the number of attendees"]
        //    });
        //} else {
            this.pagenumber = 0;
            this.onsearch()
        //}
    }
    loadFacilityList() {
        this._assetService.facilityList().subscribe(res => {
            this.FacilityListForDropdown = res;          
        });
    }
    bookingEnquiry(facility: FacilitySummary) {
        //Add facility validation
        var fc = new FacilitySummary();
        fc.id = facility.id;
        fc.item = facility.item;
        fc.items = facility.items;
        fc.itemPrice = facility.itemPrice;
        fc.configuration = facility.configuration;
        fc.itemPrice = facility.itemPrice;
        fc.package = facility.package;
        fc.startTime = this.getSerchbaleDatefomTime(this.seletedStartTime);
        fc.endTime = this.getSerchbaleDatefomTime(this.seletedEndTime);
        fc.name = facility.name;
        fc.minPrice = facility.minPrice;
        fc.maxPrice = facility.maxPrice;
        fc.capacity = facility.capacity;  
        fc.attendees = this.selectAttendees; 
        
        var startTime = this.getSerchbaleDatefomTime(this.seletedStartTime);
        var endTime = this.getSerchbaleDatefomTime(this.seletedEndTime);
        if (facility.getAvailabilityLabel() == "PARTIALLY AVAILABLE" && this.timelable == "Any" || (this.selectAttendees == 0)) {
            
            this.dialog.open(EditModalComponent, {
                data: { facility: fc, isaddfacility:true},
                panelClass: ['custom-dialog-container', 'w60modal'],
                height: 'auto',
                width: '60%'
                // DialogPosition : top

            });
        } else if (this.timelable != "Any"){
            if (facility.configuration != undefined) {
                var tempInDate = new Date(this.seletedStartTime)
                var tempOutDate = new Date(this.seletedEndTime)
                if (facility.configuration.setupTime != undefined) {
                    
                    tempInDate.setMinutes(tempInDate.getMinutes() - facility.configuration.setupTime)
                    startTime = this.getSerchbaleDatefomTime(tempInDate);
                }
                if (facility.configuration.teardownTime != undefined) {
                    
                    tempOutDate.setMinutes(tempOutDate.getMinutes() + facility.configuration.teardownTime)
                    endTime = this.getSerchbaleDatefomTime(tempOutDate);
                }

                if (facility.configuration.setupTime != 0 || facility.configuration.teardownTime != 0) {
                    let availablityResult = this._assetService.timeslotSearch(fc.id, startTime, endTime);

                    availablityResult.subscribe(res => {
                        //this.isAvailable = (res.length < 1);
                        if (res.length < 1) {
                            this.store.dispatch(new AddFacility(fc));
                        } else {
                            var message = 'Timeslot unavailable: ' + tempInDate.toLocaleTimeString() + ' - ' + tempOutDate.toLocaleTimeString()
                            this.snackBar.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: [message]
                            });
                        }
                        //this.showUnavailableMessage = !(res.length < 1);
                    },
                        err => {
                            console.log("Error", err);
                            this.snackBar.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: []
                            });
                        },
                        () => { },
                    );
                } else {
                    this.store.dispatch(new AddFacility(fc));
                }
            } else {
                this.store.dispatch(new AddFacility(fc));
            }
        } else {
            this.store.dispatch(new AddFacility(fc));
        }

    }
   
    errorHandler(event) {       
        event.target.src = environment.ApiUrl +"dist/assets/images/no-image.png";
    }
    loadFacilityTimeslots() {

        if (this.searchFacilities.length > 0) {
            var fc = "";
            this.searchFacilities.forEach(f => {
                if (fc.length > 0)
                    fc += ",";
                fc += f.id;
            });
            let dtS = new Date(this.date.year(), this.date.month(), this.date.date());
            let dtE = new Date(this.date.year(), this.date.month(), this.date.date());
            dtE.setHours(23);
            dtE.setMinutes(59);            
            dtE.setSeconds(0);   
            this._assetService.timeslotSearch(fc, Utility.convertToISO(dtS), Utility.convertToISO(dtE)).subscribe(res => {
                if (res) {
                    res.forEach(t => {
                        if (t.asset.id) {
                            var facility = this.searchFacilities.find(f => f.id == t.asset.id);
                            if (facility) {
                                if (!facility.calenderTimeslots)
                                    facility.calenderTimeslots = [];
                                facility.timeslotLoaded = true;
                                var arr: Timeslot[] = [];
                                arr.push(t);
                                var ts = FacilityAdaptor.TimeslotPopulate(arr);
                                if (ts) {
                                    ts.forEach(x => {
                                        
                                        facility.calenderTimeslots.push(x);
                                    });
                                    
                                }
                            }
                        }
                    });
                    FacilityAdaptor.populateAvailableTimeslots(this.searchFacilities, dtS);                   
                }
            });
        }
    }
    openTimePopup(slots: CalenderTimeslot[], available_slots: CalenderTimeslot[]) {
        //var arr: CalenderTimeslot[] = [];       
        //arr.push({ start: 5.5, end: 7.25 });
        this.dialog.open(CalendarModalComponent, {
            panelClass: ['modal-width'],
            height: 'auto',
            //width: '60%',
            data: { slots: slots, availableSlots: available_slots },
            // DialogPosition : top

        });

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        if (innerWidth >= 768) {
            this.textLimit = environment.BookingSearchDescriptionTextLimitHigh
        } else if (innerWidth < 768 && innerWidth > 400) {
            this.textLimit = environment.BookingSearchDescriptionTextLimitMedium
        } else {
            this.textLimit = environment.BookingSearchDescriptionTextLimitLow
        }
    }

    toggelReadMore(obj, id) {
        if (document.getElementById(id + "-expand").style.display === "none") {
            document.getElementById(id + "-preview").style.display = 'none';
            document.getElementById(id + "-expand").style.display = 'block';
            document.getElementById(id + "-linkshow").style.display = 'none';
            document.getElementById(id + "-linkhide").style.display = 'block';
        } else {
            document.getElementById(id + "-preview").style.display = 'block';
            document.getElementById(id + "-expand").style.display = 'none';
            document.getElementById(id + "-linkshow").style.display = 'block';
            document.getElementById(id + "-linkhide").style.display = 'none';
        }
        
        //@ViewChild('myDiv') myDiv: ElementRef;
    }

    modelChanged(newObj) {
        this.pagenumber = 0;
    }
}

