import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OptFormModules } from 'src/modules/shared/form.modules';
import { OptCommonModules } from 'src/modules/shared/common.modules';


//import { RecaptchaModule } from 'angular-google-recaptcha';
import { BookingRoutingModule } from './booking-routing.module';
import { OptHttpProvider } from '../services/base/api-request-handler';
import { OptMaterialModule } from '../shared/opt-material/opt-material.module';

//Layout
import { LayoutModule } from '../layout/layout';

//components
import { MainComponent } from './main.component';
import { FacilitySearchComponent } from './facility/facility-search/facility-search.component';
import { DocModalComponent } from './booking-cart/doc-modal/doc-modal.component';
import { CalendarModalComponent } from './common/calendar-modal/calendar-modal.component';
import { EditModalComponent } from './booking-cart/edit-modal/edit-modal.component';
import { ModalContentComponent } from './common/modal-content/modal-content.component';

import { ModalWindowComponent } from './test-component/modal-window/modal-window.component';


import { FacilityDetailComponent } from './facility/facility-detail/facility-detail.component';
import { SearchComponent } from './facility/facility-search/search/search.component';

import { BookingCartComponent } from './booking-cart/booking-cart.component';
import { MmcCartComponent } from './booking-cart/mmc-cart/mmc-cart.component';
import { MmcEnquiryComponent } from './mmc-enquiry/mmc-enquiry.component';
import { CartPanelComponent } from './booking-cart/cart-panel/cart-panel.component';

//need to remove

//import { CorporateComponent } from './client/setup/corporate/corporate.component';
//import { IndividualComponent } from './client/setup/individual/individual.component';
import { QASIntegrationService } from '../services/qas-integration.service';

//Html plugins
import { NguCarouselModule } from '@ngu/carousel';
import { MmcCarouselComponent } from './facility/facility-detail/mmc-carousel/mmc-carousel.component';
import { SimpleComponent } from './facility/facility-detail/mmc-carousel/simple/simple.component';
import { NestedComponent } from './facility/facility-detail/mmc-carousel/nested/nested.component';
import { MmcSliderComponent } from './facility/facility-detail/mmc-slider/mmc-slider.component';

//import { TimepickerModule } from 'ngx-bootstrap/timepicker';
// such override allows to keep some initial values
import { TimepickerConfig } from 'opt-timepicker';
export function getTimepickerConfig(): TimepickerConfig {
    return Object.assign(new TimepickerConfig(), {
        hourStep: 1,
        minuteStep: environment.MinimumBookingDuration,
        showMeridian: true,
        meridians: ['am', 'pm'],
        readonlyInput: false,
        //mousewheel: true,
        showMinutes: true,
        showSeconds: false

    });
}


import { TimepickerModule } from 'opt-timepicker';
import { NgxGalleryModule } from 'ngx-gallery';

// Services
import { AssetService } from '../services/asset.service';
import { EventService } from '../services/event.service';
import { ItemService } from '../services/item.service';
import { PackageService } from '../services/package.service';
import { BookingService } from '../services/booking.service';
import { ScrollTopService } from 'src/modules/services/scroll-top.service';
import { SystemService } from 'src/modules/services/system.service';
import { SystemConfig } from 'src/modules/system-configuration';

import { FormsModule } from '@angular/forms';


// Google Map

import { AgmCoreModule,LAZY_MAPS_API_CONFIG,LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { GoogleMapsConfig} from  'src/modules/google-maps-config'

// File Drop 
import { HttpClientModule } from '@angular/common/http';
import { FileDropModule } from 'ngx-file-drop';


//Pipe
//import { CurrencyPipe } from '@angular/common';
//import { CurrencyFormat } from 'src/modules/currency-format';


//Store
import { StoreModule, ActionReducerMap, ActionReducer, MetaReducer } from "@ngrx/store";
import { statusReducer } from '../store/loading/reducer';
import { LoadingActions } from '../store/loading/actions';

import { cartReducer } from '../store/cart-reducer';
import { CartActionTypes } from '../store/actions';
import { ICart, AppState } from '../store/cart';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from '../store/effects/login-effects';
import { FacilityEffects } from '../store/effects/facility-effects';
import { environment } from 'src/environments/environment';
import { NgxCaptchaModule  } from 'ngx-captcha';


const reducers: ActionReducerMap<AppState> = { cart: cartReducer, loader: statusReducer};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: ['cart'], rehydrate: true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

import { CusModules } from './client/cus.modules';

@NgModule({
    //exports: [CurrencyFormat],
  imports: [
      OptFormModules,
      BrowserModule,      
      BrowserAnimationsModule,
      BookingRoutingModule,
      LayoutModule,
      OptMaterialModule,
      TimepickerModule.forRoot(),
      NgxGalleryModule,
      OptCommonModules,
      CusModules,
      //RecaptchaModule.forRoot({
      //    siteKey: '6LdTrYUUAAAAADe9h2maO1tgmvDByfVDXGbh8RN-',
      //}),
      NguCarouselModule,  
      //StoreModule.forRoot({cart: cartReducer }),
      //StoreModule.forRoot(reducers, { metaReducers }),
      //StoreModule.forRoot({}),
      StoreModule.forRoot(reducers, { metaReducers }),
      EffectsModule.forRoot([LoginEffects,  FacilityEffects]),
      //CommonModule,
      FormsModule,
      AgmCoreModule.forRoot({
          apiKey: environment.GoogleAPIKEY
      }),
      HttpClientModule,
      FileDropModule,
      NgxCaptchaModule
  ],
    declarations: [
        MainComponent,
        FacilitySearchComponent,
        SearchComponent,
       // FilterComponent,
        //SearchResultComponent,
        FacilityDetailComponent,
        MmcCarouselComponent,
        SimpleComponent,
        NestedComponent,
        //SubHeaderComponent,
        MmcSliderComponent,
        ModalWindowComponent,
        DocModalComponent,
        ModalContentComponent,
        BookingCartComponent,
        MmcCartComponent,
        CalendarModalComponent,
        EditModalComponent,
        MmcEnquiryComponent,
        CartPanelComponent,        
        //CurrencyFormat 
        //SetupComponent,
        //CorporateComponent,
        //IndividualComponent,
        //LoginComponent,
    ],
    entryComponents: [
        ModalWindowComponent,
        DocModalComponent,
        CalendarModalComponent,
        EditModalComponent
    ],

    providers: [OptHttpProvider,
        AssetService, EventService, ItemService, PackageService, SystemService, SystemConfig,
        BookingService, QASIntegrationService,
        ScrollTopService,
        //CurrencyPipe,
        //CurrencyFormat
        { provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig },
        { provide: TimepickerConfig, useFactory: getTimepickerConfig }
    ],
    bootstrap: [MainComponent]
})
export class BookingModule { }

