import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef,   ChangeDetectionStrategy  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScrollTopService } from 'src/modules/services/scroll-top.service';

import { ILoadingStatus } from 'src/modules/store/loading/status-store'
import { FacilitySearchLoading, FacilitySearchLoaded, BookingSaveStarted, BookingSaveCompleted } from 'src/modules/store/loading/actions'
import { Store, ICart } from 'src/modules/store/index';
import { Observable } from "rxjs";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'opt-booking-cart',
    templateUrl: './booking-cart.component.html',
    //styleUrls: ['./booking-cart.component.scss']
})
export class BookingCartComponent implements OnInit {
    selectedIndex: number;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    loader$: Observable<ILoadingStatus>;
    displayLogin: boolean = true;
    isLinear: boolean = false;
    cart$: Observable<ICart>;
    isCoparateTab = false;
    isIndividualTab = false;
    tabIndex = 0;
    selectedCusIndex = 1;
    constructor(private _formBuilder: FormBuilder, private scrollTopService: ScrollTopService, private store: Store<any>, private route: ActivatedRoute, private cd: ChangeDetectorRef
    ) {
        this.loader$ = this.store.select('loader');

        this.scrollTopService.setScrollTop();
        this.selectedIndex = 1;
        this.cart$ = this.store.select('cart');
    }
    ngAfterViewInit() {
        this.cd.detectChanges();
    }
    ngOnDestroy() {

    }
    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            let tabIndex = params.get("index");
            if (tabIndex != null) {
                this.selectedIndex = +tabIndex;
            }
            if (params.get("displayLogin") != null) {
                if (params.get("displayLogin") == 'false') {
                    this.displayLogin = false;
                }
            }
        });
        this.firstFormGroup = this._formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            secondCtrl: ['', Validators.required]
        });
    }
    setTabIndex(index: number) {

        this.selectedIndex = index;
    }
    loadSignUp() {
        this.displayLogin = false;
    }

    tabChange(e) {
        if (e.index == 0) {
            this.isCoparateTab = true;
            this.isIndividualTab = false;
            this.tabIndex = 0;
        } else if (e.index == 1) {
            this.isIndividualTab = true;
            this.isCoparateTab = false;
            this.tabIndex = 1;
        }
    }

    OpenTab(val) {
        if (val == 0) {
            this.isCoparateTab = true;
            this.isIndividualTab = false;
            this.tabIndex = 0;
            this.selectedCusIndex = 0;
        } else if (val == 1) {
            this.isIndividualTab = true;
            this.isCoparateTab = false;
            this.tabIndex = 1;
            this.selectedCusIndex = 1;
        }
    }
} 
