import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";

//Store
import { Store, ICart } from '../../../store/index';

@Component({
  selector: 'app-facility-search',
  templateUrl: './facility-search.component.html',
  //styleUrls: ['./facility-search.component.scss']
})
export class FacilitySearchComponent implements OnInit {
    

    constructor(private store: Store<any>) {
    }

    ngOnInit() {
       
    }

}
