import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { CurrencyPipe } from '@angular/common';

//Store
import { Store, ICart } from '../../store/index';
import { SetContact,SignOut } from '../../store/index';
import { Contact } from '../../models/client/contact';
import { RoutePath } from '../../booking-portal/route-path';
import { Router } from '@angular/router';
import { FacilitySearchLoading } from 'src/modules/store/loading/actions'
import { Utility } from 'src/modules/utility';

@Component({
  selector: 'opt-sub-header',
  templateUrl: './sub-header.component.html'
})
export class SubHeaderComponent implements OnInit {
    cart$: Observable<ICart>;
    minAmount: number;
    maxAmount: number;
    imgUrl;
    constructor(private store: Store<any>, private router: Router, private currencyPipe: CurrencyPipe) {

    }

    ngOnInit() {
        this.cart$ = this.store.select('cart');//.subscribe((state => this.cart = state))    

        this.cart$.subscribe(state => {
            console.log(state)
            this.imgUrl = state.contact.profilePicture;
            if (this.imgUrl == undefined || state.contact.profilePicture=='')
                this.imgUrl = 'dist/assets/images/user-icon.png'
            //debugger;
            let minAmount = 0;
            let maxAmount = 0;
            if (state.addedFacilites != undefined && state.addedFacilites.length > 0) {
                state.addedFacilites.forEach(function (fc) {
                    minAmount += (fc.minPrice != undefined)?fc.minPrice!.priceIncludingTax :0;
                    maxAmount += (fc.maxPrice != undefined)?fc.maxPrice!.priceIncludingTax:0;
                })
            }
            this.minAmount = parseFloat(this.currencyPipe.transform(minAmount, '','', '1.2-2'));
            this.maxAmount = parseFloat(this.currencyPipe.transform(maxAmount,'','', '1.2-2'));
        });
    }
    cartClick() {
       
        this.router.navigate(['/booking-cart']);

    }
    signOut()
    {
        this.store.dispatch(new SignOut());
    }
    testClick() {
        var cn = new Contact();
        cn.firstName = "AAAAA";
        this.store.dispatch(new SetContact(cn));
        this.router.navigate(['/facility-detail']);

    }

    linkClick(event) {
        this.store.dispatch(new FacilitySearchLoading());
        Utility.urlHistorty.push(this.router.url);
    }
}
