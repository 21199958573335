﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';
import { BaseService } from '../services/base/base.service';

@Injectable({
    providedIn: 'root'
})
export class QASIntegrationService {

    constructor(private _baseService: BaseService) {

    }
    
    //getQASIntegration(): any {
    //    return this._baseService.QASIntegration();
    //}    

}