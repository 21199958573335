﻿import { BaseResource } from '../base-resource';
export class Timeslot extends BaseResource {
    preliminary: boolean;
    multidayId: number;
    capacity: number;
    allocatedQuantity: number;
    startTime: Date;
    endTime: Date;
    reserved: false;
    asset: BaseResource;
    eventConfiguration: BaseResource;
    privateEventInstance: BaseResource;
    owner: Timeslot;
}

export class CalenderTimeslot {
    start: number;
    end: number;
    startTime: Date;
    endTime: Date;
}