import { Injectable } from '@angular/core';

import { BaseService } from './base/base.service';

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';

import { EventCategory } from '../models/event/event-category';

@Injectable({
  providedIn: 'root'
})
export class EventService {

    constructor(private _baseService: BaseService) {

    }
    eventCategorySearch(): Observable<EventCategory[]> {
        return this._baseService.WebAPIGet("/api/V4.1/events/categories").pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);
                console.log("Deserialized web api output");
                console.log(json);
                console.log("Convert to object");
                var lst: EventCategory[] = [];
                json.forEach((c, i) => {
                    lst.push((c as EventCategory));
                });              
                return lst
            })
        );
    }
}
