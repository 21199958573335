﻿import { Client, User, Venue, CommunicationMethod, Address } from './../../../../src/modules/models/client/client';
import { BaseResource } from './../../../modules/models/base-resource';

export class Contact extends BaseResource{    
    public ref: string;
    public client: Client;
    public title: string;
    public titleId: number;
    public firstName: string;
    public lastName: string;
    public active: boolean;
    public profilePicture: string;
    public accountManager: User;
    public address: Address;
    public position: string;
    public userName: string;
    //public bool ? MarketingAllowed { get; set; }
    //public List < ContactMethod > PreferredContactMethod { get; set; }
    public decisionMaker: boolean;
    public defaultVenue: Venue;
    ////public int? DefaultVenueId { get; set; }
    //public List < Note > Notes { get; set; }
    //public int ? Gender { get; set; }
    public birthDay: number;
    public birthMonth: number;
    public birthYear: number;
    //public int ? AgeId { get; set; }
    //public string Age { get; set; }
    public anniversaryYear: number;
    public anniversaryMonth: number;
    public anniversaryDay: number;
    public dateRegistered: Date;
    //public bool ? Suspended { get; set; }
    //public string SuspendedComment { get; set; }
    //public Address InvoiceAddress { get; set; }
    //public Address MailingAddress { get; set; }
    //public Address DeliveryAddress { get; set; }
    public communicationMethods: CommunicationMethod[];
    //public int ? InvoiceEmailAddressId { get; set; }
    //public string InvoiceEmailAddress { get; set; }
    ///// <summary>A list of hobbies, which are user-defined.</summary>
    //public List < Hobby > Hobbies { get; set; }
    ///// <summary>A list of preferences, which are user-defined.</summary>
    //public List < Preference > Preference { get; set; }
    public password: string;
    //public List < Document > Documents { get; set; }
    ////[DataMember]
    ////[JsonApiSerializer.Custom.Link(new[]{"related:api/v4.1/bookings/#{booking.id}#"})]
    ////public List<Booking.V1.Booking> Booking { get; set; }
    ////[DataMember]
    ////[JsonApiSerializer.Custom.Link(new[]{"related:api/v4.1/enquiries/#{enquiry.id}#"})]
    //public List < Enquiry > Enquiry { get; set; }
    ////[DataMember]
    ////[JsonApiSerializer.Custom.Link(new[]{"related:api/v4.1/customers/contacts/#{contact.id}#/tasks"})]
    //public List < Task > Task { get; set; }
}