﻿import { BaseResource } from '../base-resource';

export class BespokePackage extends BaseResource {

    name: string;
    businessAreaId: number;
    businessArea: string;
    packageClassId: number;
    priceTypeId: number
    priceType: string;
    taxId: number;
    eventId: number;
    salesCategories: string[];
    price: number;
}