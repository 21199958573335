import { Component, OnInit } from '@angular/core';
import { environment} from 'src/environments/environment';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  //styleUrls: ['./site-header.component.scss']
    //styleUrls: ['../../../../sass/module /_header.scss']
  
})
export class SiteHeaderComponent implements OnInit {
  imagepath: string = environment.ApiUrl + environment.publishFolder;
  constructor() { }

  ngOnInit() {
  }

}
