﻿import { Venue } from '../venue/venue'
import { BookingFacility } from './booking-facility'
import { Amenity } from '../amenity/amenity'
import { ItemPriceGroup } from '../item/item-price-group'
import { Item } from '../item/item'
import { environment } from '../../../environments/environment'
import { Configuration } from './configuration'
import { ItemFacility } from './item-facility'
import { CalenderTimeslot } from './timeslots'
import { BespokePackage } from './bespoke-package'
import { DecimalPipe } from '@angular/common'

export class FacilitySummary extends BookingFacility {
    index: number;
    active: boolean;
    classId: number;
    description: string;
    name: string;
    operationalMinutes: number;

    directions: string;
    capacity: string;  //need to remove
    venue: Venue;
    amenities: Amenity[] = [];
    
    minPrice: ItemPriceGroup;
    maxPrice: ItemPriceGroup;
   
    configarations: Configuration[]=[];
    items: Item[]=[];    
    package: BespokePackage;

    isPriceRange() {
        var min = this.minPrice != undefined ? this.minPrice!.priceIncludingTax : 0;
        var max = this.maxPrice != undefined ? this.maxPrice!.priceIncludingTax : 0;
        var dp: DecimalPipe = new DecimalPipe('en');
        return dp.transform(min, '1.2-2') != dp.transform(max, '1.2-2');
    }
    getimageurl() {
        return environment.ApiUrl + "Private/Facility/Thumbnail/" + this.id + ".jpg";
    }   

    calenderTimeslots: CalenderTimeslot[]=[];//unavailable slots
    availableTimeslots: CalenderTimeslot[]=[];//available slots
    timeslotLoaded: boolean = false;
    availabilityLabel: string = "";
    url: string = environment.ApiUrl + "dist/assets/images/no-image.png";
    getAvailabilityLabel()
    {
        var available = false;
        //if (this.timeslotLoaded) {
        if (this.calenderTimeslots == undefined || this.calenderTimeslots.length == 0)
            return "FULLY AVAILABLE";
        else if (this.availableTimeslots == undefined || this.availableTimeslots.length == 0) {
            return "NOT AVAILABLE";
        } else if (this.availableTimeslots != undefined && this.availableTimeslots.length > 0) {
            return "PARTIALLY AVAILABLE";
        }
       // }
        return "";
    }
}