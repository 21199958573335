﻿import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpRequest, HttpHandler, HttpEvent,
    HttpInterceptor,
    HttpResponse, HttpErrorResponse, HTTP_INTERCEPTORS
} from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { map, catchError, switchMap,tap,mergeMap } from 'rxjs/operators';

@Injectable()
export class APIRequestHandler implements HttpInterceptor {

    constructor(private _http: HttpClient) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({
            setHeaders: {
                'Token': environment.Token,
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }
        });
    
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {

            if (event instanceof HttpResponse) {
                // do stuff with response if you want

            }
            return event;
        }, ((err: any) => {
            return (err);

            })), catchError((err) => {
                
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {

                        return this._http.get((environment.ApiUrl + "/api/system/renew-token?token")).pipe(mergeMap(res => {
                            environment.Token = res.toString();
                            request = request.clone({
                                setHeaders: {
                                    'Token': res.toString(),
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                }
                            });
                            return next.handle(request);
                        }));
                    } else {
                        return throwError(err);
                    }

                } else {
                    return throwError(err);
                }
            })
        );
       
    }

}

export const OptHttpProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: APIRequestHandler,
    multi: true
};
