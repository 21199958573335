import { Component, Inject, OnInit, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { ModalContentComponent } from "../modal-content/modal-content.component";
import { FacilitySummary } from 'src/modules/models/asset/facility-summary'
import { FormControl } from '@angular/forms';
import { AssetService } from 'src/modules/services/asset.service';
import { Utility } from 'src/modules/utility';
import { Store, ICart, UpdateFacilities, AddFacility,UpdateFacility} from 'src/modules/store/index';
import { Observable } from "rxjs";
import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor'
import { CalenderTimeslot, Timeslot } from 'src/modules/models/asset/timeslots'
import { environment } from 'src/environments/environment'
import { Booking } from 'src/modules/models/booking/booking';


import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};


//import { TimepickerConfig } from 'ngx-bootstrap/timepicker';

//export function getTimepickerConfig(): TimepickerConfig {
//    return Object.assign(new TimepickerConfig(), {
//        hourStep: 1,
//        minuteStep: environment.MinimumBookingDuration,
//        showMeridian: true,
//        meridians: ['am', 'pm'],
//        readonlyInput: false,
//        //mousewheel: true,
//        showMinutes: true,
//        showSeconds: false

//    });
//}
@Component({
    selector: 'opt-edit-modal',
    templateUrl: './edit-modal.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        //{ provide: TimepickerConfig, useFactory: getTimepickerConfig }
    ],

    //styleUrls: ['./edit-modal.component.scss']
})
export class EditModalComponent implements OnInit, OnDestroy {
     
    cart$: Observable<ICart>;
    cart_subscriber: any;
    // options: FormGroup;
    date = moment();
    // serializedDate = new FormControl(new Date());
    facilitySummary: FacilitySummary;
    booking = new Booking;
    
    StartTime: Date;
    EndTime: Date;

    // time picker validation 
    minDate: Date = new Date();//for date picker
    minStartDate: Date;
    maxStartDate: Date;
    minEndDate: Date;
    maxEndDate: Date;
    isValidStart: boolean;
    isValidEnd: boolean;

    attendees: number;
    isAvailable: boolean = false;
    showUnavailableMessage: boolean = false;
    isAddFacility = false;
    DisplayBumpInBumpOut = false;
    TimeWithBumpIn:Date;
    TimeWithBumpOut: Date;
    isCheckAvailabilityTriggered: boolean = false;
    IsUpdateTimeSlotCalendar: boolean = true;
    constructor(public dialogRef: MatDialogRef<EditModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _assetService: AssetService, private store: Store<any>) {
        
        this.facilitySummary = data.facility;
        this.facilitySummary.timeslotLoaded = false;
        this.StartTime = Utility.convertISOToDate(this.facilitySummary.startTime);
        this.EndTime = Utility.convertISOToDate(this.facilitySummary.endTime);
        this.attendees = this.facilitySummary.attendees;
        this.date = moment(this.facilitySummary.startTime);
        this.cart$ = this.store.select('cart');
        this.booking = new Booking();
        if (data.isaddfacility != null || data.isaddfacility != undefined) {
            this.isAddFacility = data.isaddfacility;
        }
        this.initializeTime();
    }

    public close() {
        this.dialogRef.close();
    }
    //timepicker



    //input
    //getFontSize() {
    //    return Math.max(10, this.options.value.fontSize);
    //}

    public attendeesIsValid() {
        try {

            if (this.facilitySummary.capacity != "" && this.facilitySummary.capacity != null && this.facilitySummary.capacity != undefined ) {
                let capacity = parseInt(this.facilitySummary.capacity);
                return (capacity >= this.attendees)
            }
            else {
                return false;
            }
        } catch (e) {
            return false;

        }
    }

    public attendeesCountIsValid() {
        try {
            return (this.attendees != 0 && (!isNaN(this.attendees)) && this.attendees != null);

        } catch (e) {
            return false;

        }
    }

    ngOnDestroy() {
        if (this.cart_subscriber)
            this.cart_subscriber.unsubscribe();
    }

    ngOnInit() {
        this.validateAvailability();

        this.cart_subscriber =
            this.cart$.subscribe(state => {
                this.booking = state.booking;
            });

    }

    refreshBooking(facility: FacilitySummary) {

        if (this.isAvailable && this.attendeesIsValid() && this.attendeesCountIsValid()) {

            facility.attendees = this.attendees;
            facility.startTime = this.getSerchbaleDatefomTime(this.StartTime);
            facility.endTime = this.getSerchbaleDatefomTime(this.EndTime);

           // this.store.dispatch(new UpdateFacility(facility));
            this.dialogRef.close();
           
            //let Isupdate = false;
            //this.cart_subscriber = this.cart$.subscribe(cart => {
            //    if (!Isupdate) {
            //        let obj = cart.addedFacilites.find(o => o.index == this.facilitySummary.index);
            //        if (obj != undefined) {
            //            obj.attendees = this.attendees;
            //            obj.startTime = this.getSerchbaleDatefomTime(this.StartTime);
            //            obj.endTime = this.getSerchbaleDatefomTime(this.EndTime);
            //            Isupdate = true;
            //            this.store.dispatch(new UpdateFacilities(cart.addedFacilites));
            //            this.dialogRef.close();
            //        }  
            //    }                             
            //});
        }       
    }
    checkAvailability() {
        if (this.isValidStart && this.isValidEnd) {
            this.isCheckAvailabilityTriggered = true;
            this.validateAvailability();
        }
    }

   
    validateAvailability() {
        let fcArr = [];
        fcArr.push(this.facilitySummary);
        let startTime = this.getSerchbaleDatefomTime(this.StartTime);
        let dtS = new Date(startTime);
        dtS.setHours(0);
        dtS.setMinutes(0);
        dtS.setSeconds(0);
        let dtE = new Date(startTime);
        dtE.setHours(23);
        dtE.setMinutes(59);
        dtE.setSeconds(0);
        let endTime = this.getSerchbaleDatefomTime(this.EndTime);

        if (this.facilitySummary.configuration != undefined) {
            if (this.facilitySummary.configuration.setupTime != undefined) {
                this.TimeWithBumpIn = new Date(this.StartTime)
                this.TimeWithBumpIn.setMinutes(this.TimeWithBumpIn.getMinutes() - this.facilitySummary.configuration.setupTime)
                startTime = this.getSerchbaleDatefomTime(this.TimeWithBumpIn);
            }
            if (this.facilitySummary.configuration.teardownTime != undefined) {
                this.TimeWithBumpOut = new Date(this.EndTime)
                this.TimeWithBumpOut.setMinutes(this.TimeWithBumpOut.getMinutes() + this.facilitySummary.configuration.teardownTime)
                endTime = this.getSerchbaleDatefomTime(this.TimeWithBumpOut);
            }

            //if (this.facilitySummary.configuration.setupTime != 0 || this.facilitySummary.configuration.teardownTime != 0) {
            //    this.DisplayBumpInBumpOut = true;
            //} else {
            //    this.DisplayBumpInBumpOut = false;
            //}
        }
        let availablityResult = this._assetService.timeslotSearch(this.facilitySummary.id, startTime, endTime);

        availablityResult.subscribe(res => {
            let bookingData = this.booking;
            if (this.booking.id != null && this.booking.id != undefined && (+this.booking.id) > 0) {
                if (res.length < 1) {
                    this.isAvailable = (res.length < 1);
                    this.showUnavailableMessage = !(res.length < 1);
                }
                else {
                    let isAvailable = true;
                    res.forEach(function (slot) {
                        if (slot.privateEventInstance.id != bookingData.id) {
                            isAvailable = false;
                        }                        
                    });
                    this.isAvailable = isAvailable;
                    this.showUnavailableMessage =(!isAvailable);
                }
            }
            else {
                this.isAvailable = (res.length < 1);
                this.showUnavailableMessage = !(res.length < 1);
            }            
        },
            err => {
                console.log("Error", err);
                this.showUnavailableMessage = true;
            },
            () => { },
        );
        if (this.IsUpdateTimeSlotCalendar) {
            this.facilitySummary.availableTimeslots = [];
            this.facilitySummary.calenderTimeslots = [];
            let availablityTimeSlotResult = this._assetService.timeslotSearch(this.facilitySummary.id, this.getSerchbaleDatefomTime(dtS), this.getSerchbaleDatefomTime(dtE));
            availablityTimeSlotResult.subscribe(res => {
                this.facilitySummary.availableTimeslots = [];
                this.facilitySummary.calenderTimeslots = [];
                if (res) {

                    res.forEach(t => {
                        if (t.asset.id) {
                            var facility = fcArr.find(f => f.id == t.asset.id);
                            if (facility) {
                                if (!facility.calenderTimeslots)
                                    facility.calenderTimeslots = [];
                                facility.timeslotLoaded = true;
                                var arr: Timeslot[] = [];
                                arr.push(t);
                                var ts = FacilityAdaptor.TimeslotPopulate(arr);
                                if (ts) {
                                    ts.forEach(x => {

                                        facility.calenderTimeslots.push(x);
                                    });

                                }
                            }
                        }
                    });
                    FacilityAdaptor.populateAvailableTimeslots(fcArr, dtS);
                    this.facilitySummary.timeslotLoaded = true;
                    this.IsUpdateTimeSlotCalendar = false;
                }
            },
                err => {
                    console.log("Error", err);
                    this.showUnavailableMessage = true;
                },
                () => { },
            );
        }

    }


    getSerchbaleDatefomTime(selected: Date) {
        let searchabledate = new Date(this.date.year(), this.date.month(), this.date.date());
        searchabledate.setHours(selected.getHours(), selected.getMinutes(), selected.getSeconds());
        return Utility.convertToISO(searchabledate);
    }

    attendeesChanged(val) {
        if (new String(val).length > 5) {
            val = + String(val).slice(0, 5);
        }
    }

    displayBumpOut(date: Date) {
        let tempDate = new Date(date);
        tempDate.setMinutes(tempDate.getMinutes() + this.facilitySummary.configuration.teardownTime)
        return tempDate
    }

    displayBumpIn(date: Date) {
        let tempDate = new Date(date);
        tempDate.setMinutes(tempDate.getMinutes() - this.facilitySummary.configuration.setupTime)
        return tempDate
    }

    addBooking() {
        if (this.isAvailable && this.attendeesIsValid() && this.attendeesCountIsValid()) {
            this.dialogRef.close();
            this.facilitySummary.startTime = this.getSerchbaleDatefomTime(this.StartTime);
            this.facilitySummary.endTime = this.getSerchbaleDatefomTime(this.EndTime);
            this.facilitySummary.attendees = (this.attendees == null || isNaN(this.attendees) || this.attendees == 0) ? 1 : this.attendees;
            this.store.dispatch(new AddFacility(this.facilitySummary));
        }
    }


    //time picker section start
    dateChanged() {
        this.isAvailable = false;
        this.IsUpdateTimeSlotCalendar = true;
        var dt = this.date.toDate();
        this.StartTime = Utility.changeDatePart(this.StartTime, this.date.toDate());
        //this.date.add(this.StartTime.getHours(), 'h').add(this.StartTime.getMinutes(), 'm').toDate();
        var today = new Date();
        if (!(dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
            dt.setHours(0, 0, 0);
            this.minStartDate = dt;
        } else {

            if (new Date(this.StartTime) < new Date()) {
                this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
            }
            else {
                let newstartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                newstartTime.setHours(this.StartTime.getHours());
                newstartTime.setMinutes(this.StartTime.getMinutes());
                this.StartTime = newstartTime;
            }
            this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        }

        this.maxStartDate = Utility.changeDatePart(new Date(this.maxStartDate), this.date.toDate());
        this.maxStartDate.setMinutes(this.maxStartDate.getMinutes() + 1);

        let newEndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
        newEndTime.setHours(this.EndTime.getHours());
        newEndTime.setMinutes(this.EndTime.getMinutes());

        this.EndTime = newEndTime;
        this.minEndDate = new Date( moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate());
        this.maxEndDate = new Date(this.minEndDate);
        this.maxEndDate.setHours(24, 0, 0);

    }

    startChange(event) {
        //if (event != null && this.isValidStart)
        //    this.StartTime = event;
    }
    endChange(event) {
        //if (event != null && this.isValidEnd)
        //    this.EndTime = event;
    }
    startTimeChanged() {
        this.isAvailable = false;
        if (this.StartTime != null) {
            var dtObj = Utility.roundToMinDuration(this.StartTime, environment.MinimumBookingDuration);
            if (dtObj.isDateChanged)
                this.StartTime = moment(dtObj.newDate).toDate();

            this.minEndDate = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

            if (moment(this.StartTime) >= moment(this.EndTime))
                this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();

            var dt = this.date.toDate();
            var today = new Date();
            if ((dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
                if (this.StartTime < today) {
                    this.StartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                }
            } 
        }
        //this.chRef.detectChanges();
    }
    endTimeChanged() {
        this.isAvailable = false;
        //var EndTime = this.EndTime;
        if (this.EndTime != null) {
            if (this.EndTime.getFullYear() == this.maxEndDate.getFullYear() &&
                this.EndTime.getMonth() == this.maxEndDate.getMonth() &&
                this.EndTime.getDate() == this.maxEndDate.getDate() &&
                this.EndTime.getHours() == this.maxEndDate.getHours() &&
                this.EndTime.getMinutes() == this.maxEndDate.getMinutes()
            ) {
                this.EndTime = moment(this.EndTime).add(-1, 'm').toDate();

            } else {

                var dtObj = Utility.roundToMinDuration(this.EndTime, environment.MinimumBookingDuration, true);
                if (dtObj.isDateChanged)
                    this.EndTime = moment(dtObj.newDate).toDate();

                if (moment(this.StartTime) >= moment(this.EndTime))
                    this.EndTime = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
            }
        }
        //this.chRef.detectChanges();
        return this.EndTime;
    }
    initializeTime(): void {
        //default start time: next rounded time based on the current time
        //// this.StartTime = ;
        this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        this.maxStartDate = new Date();
        this.maxStartDate.setHours(24, 0, 0);
        this.maxStartDate = moment(this.maxStartDate).add(environment.MinimumBookingDuration * -1, 'm').toDate();

        this.minEndDate = moment(this.StartTime).add(environment.MinimumBookingDuration, 'm').toDate();
        this.maxEndDate = new Date();
        this.maxEndDate.setHours(24, 0, 0);
        this.dateChanged();
    }

    //time picker section end
}
