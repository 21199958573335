import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DocModalComponent } from '../../booking-cart/doc-modal/doc-modal.component';
import { CalendarModalComponent } from '../../common/calendar-modal/calendar-modal.component';
import { EditModalComponent } from '../../booking-cart/edit-modal/edit-modal.component';

@Component({
    selector: 'opt-modal-window',
    templateUrl: './modal-window.component.html',
    //styleUrls: ['./modal-window.component.scss']
})
export class ModalWindowComponent implements OnInit {

    constructor(public dialog: MatDialog) { }

    public openDocPopup() {
        this.dialog.open(DocModalComponent, {
            data: { name: 'Upload Documents' },
            panelClass: ['custom-dialog-container', 'w80modal'],
            height: 'auto',
            width: '80%'
            // DialogPosition : top

        });

    }
    
    public openTimePopup() {
        this.dialog.open(CalendarModalComponent, {
            panelClass: ['custom-dialog-container', 'w60modal'],
            height: 'auto',
            width: '60%'
            // DialogPosition : top

        });

    }
    public openEditPopup() {
        this.dialog.open(EditModalComponent, {
            panelClass: ['custom-dialog-container', 'w60modal'],
            height: 'auto',
            width: '60%'
            // DialogPosition : top

        });

    }


    ngOnInit() {
    }

}
