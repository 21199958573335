﻿import { BaseResource } from '../base-resource';
import { Item } from '../item/item';
import { ItemPriceGroup } from '../item/item-price-group';
import { Configuration } from '../asset/configuration';
import { BespokePackage} from './bespoke-package';

//this is the object used to generate booking structure
export class BookingFacility extends BaseResource
{
    constructor() {
        super();       
    }
    item: Item;
    configuration: Configuration;
    //package: BespokePackage;
    itemPrice: ItemPriceGroup;
    startTime: string;
    endTime: string;
    attendees: number;
}

