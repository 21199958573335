﻿import { environment } from 'src/environments/environment';
import { FacilitySummary } from './facility-summary';
import { BookingFacility } from './booking-facility';
import { BespokePackage } from './bespoke-package';
import { Item } from '../item/item';
import { ItemPriceGroup } from '../item/item-price-group';
import { Configuration } from './configuration';
import { CalenderTimeslot,Timeslot } from './timeslots';
import { Utility } from 'src/modules/utility';

export class FacilityAdaptor
{
    static PopulateFacilitySummary(facilities: FacilitySummary[], items: Item[], defaultPackage: BespokePackage)
    {
        facilities.forEach((facility) => {

            var minP: ItemPriceGroup, maxP: ItemPriceGroup;
            //debugger;
            facility.items.forEach((item, i) =>
            {
                var searchedItem = items.find(i => i.id == item.id);
              
                if (searchedItem != undefined) {

                    //overwrite the facility item from item serach item
                    item.itemAssets = (searchedItem.itemAssets);
                    item.salesCategories = (searchedItem.salesCategories);
                    item.defaultPriceConcessionID = (searchedItem.defaultPriceConcessionID);
                    //check sales category matched or not
                    if (defaultPackage.salesCategories != undefined &&
                       (facility.item == undefined || facility.item.id == undefined || facility.item.id == ""))
                    {

                        var index = defaultPackage.salesCategories.findIndex(sc => item.salesCategories.findIndex(isc => isc.id == sc) >= 0);
                        if (index != undefined && index >= 0)
                        {
                            facility.item = item; //set default item for booking
                            facility.package = defaultPackage; //set default package for booking

                            this.populateItemDetail(item, facility, searchedItem.itemPriceGroups, minP, maxP);
                        } 
                    }
                  
                }
                
            });
            //if ((facility.item == undefined || facility.item.id == undefined || facility.item.id == "")) {
            //    //not matching item for default package, check other packages
            //    if (facility.items) {
            //        facility.items.forEach(itm => {
            //            if ((facility.item == undefined || facility.item.id == undefined || facility.item.id == ""))
            //            {
            //                var index = itm.salesCategories.findIndex(sc => environment.be.salesCategories.findIndex(isc => isc.id == sc) >= 0);
            //                if (index != undefined && index >= 0)
            //                {
            //                }
            //            }
            //        });
            //    }

            //    //price mapping
            //}
        });

    }

    static populateItemDetail(item: Item, facility: FacilitySummary, itemPriceGroups: ItemPriceGroup[], minP:ItemPriceGroup, maxP:ItemPriceGroup)
    {
        var confg: Configuration; // find matching configuration for that booking item
        if (item.itemAssets != undefined) {
            var itmAsset=item.itemAssets.find(a => a.asset.id == facility.id);
            if (itmAsset) {

                confg = facility.configarations.find(c => c.id == itmAsset.configuration.id);
            }

        }
        if (confg.id == undefined || confg.id ==0) {
            //map default configuration
            confg = facility.configarations.find(c => c.default);
        }
        if (confg != undefined && confg.id)
            facility.configuration = confg;

        //pricing
        if (itemPriceGroups != undefined) {

            //filter for hourly price type
            item.itemPriceGroups = [];
            itemPriceGroups.forEach(ip => {
                if (ip.priceTypeId == environment.PriceTypeId) {
                    item.itemPriceGroups.push(ip);
                }
            });


            item.itemPriceGroups.forEach(pr => {
                //1:min and max price, 
                //2: defined price concession(PriceMinConsessionId, PriceMaxConsessionId)
                if (environment.PriceDisplayOption == 1) {
                    if (minP == undefined || pr.priceIncludingTax < minP.priceIncludingTax) {
                        minP = pr;
                    }
                    if (maxP == undefined || pr.priceIncludingTax > maxP.priceIncludingTax) {
                        maxP = pr;
                    }
                } else if (environment.PriceDisplayOption == 2) {
                    if (environment.PriceMinConsessionId == pr.concessionId) {
                        if (minP == undefined || pr.priceIncludingTax < minP.priceIncludingTax) {
                            minP = pr;
                        }
                    }
                    if (environment.PriceMaxConsessionId == pr.concessionId) {
                        if (maxP == undefined || pr.priceIncludingTax > maxP.priceIncludingTax) {
                            maxP = pr;
                        }
                    }
                }
            }
            );
            if (minP == undefined && maxP == undefined && item.itemPriceGroups.length > 0)
            {
                minP = item.itemPriceGroups[0];
            }
            //set price group for booking Item
            facility.itemPrice = minP == undefined ? maxP : minP;
            facility.minPrice = minP == undefined ? maxP : minP;
            facility.maxPrice = maxP == undefined ? minP : maxP;
        }
    }

    static TimeslotPopulate(unavailableSlots:Timeslot[]):CalenderTimeslot[]
    {
        var timelineEntries: CalenderTimeslot[] = [];
        if (unavailableSlots)
        {
            unavailableSlots.forEach((slot) => {
               // if (!slot.preliminary) {
                    var t = new CalenderTimeslot();
                    var dtS =  slot.startTime;
                    var dtE = slot.endTime;
                    var minS = dtS.getMinutes();
                    var minE = dtE.getMinutes();
                    if (minS == 59) {
                        dtS.setMinutes(0);
                        dtS.setSeconds(0);
                        dtS.setHours(dtS.getHours() + 1);
                    }
                    t.start = (dtS.getHours() + dtS.getMinutes() / 60);
                    t.startTime = dtS;

                    if (minE == 59) {
                        if (dtE.getHours() == 23) {
                            t.end = 24.0;
                            dtE.setMinutes(0);
                            dtE.setSeconds(0);
                            dtE.setHours(dtE.getHours() + 1);
                        } else {
                            t.end = (dtE.getHours() + dtE.getMinutes() / 60);
                        }
                    } else {
                        t.end = (dtE.getHours() + dtE.getMinutes() / 60);


                    }

                    t.endTime = dtE;
                    timelineEntries.push(t);
               // }
            });
        }
        return timelineEntries;
    }

    static populateAvailableTimeslots(facilities:FacilitySummary[],searchDate:Date)
    {
        facilities.forEach(f => {
           
            var sortArr = f.calenderTimeslots.sort((a, b) => a.start < b.start ? -1 : a.start > b.start ? 1 : 0);
            var timeConsiderUpto = 0;
            var startTime = new Date(searchDate.getFullYear(), searchDate.getMonth(), searchDate.getDate());
            sortArr.forEach(t => {
                if (t.start > timeConsiderUpto) {
                    f.availableTimeslots.push({
                        start: timeConsiderUpto,
                        end: t.start,
                        startTime: startTime,
                        endTime: t.startTime
                    });
                    timeConsiderUpto = t.end
                    startTime = t.endTime;

                } else if (t.end > timeConsiderUpto) {
                    timeConsiderUpto = t.end;
                    startTime = t.endTime;
                }
            });
            if (timeConsiderUpto < 24 && ((timeConsiderUpto - 23) + 59 / 60) != timeConsiderUpto) {
                var endDt = new Date(searchDate.getFullYear(), searchDate.getMonth(), searchDate.getDate());
                endDt.setHours(23);
                endDt.setMinutes(59);
                endDt.setSeconds(0);
                f.availableTimeslots.push(
                    {
                        start: timeConsiderUpto,
                        startTime: startTime,
                        end: 24.0,
                        endTime: endDt
                    });
            }

        });
    }
}