// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    ApiUrl: '',
    WebApiUrl: '',
    Token: '',
    ConfigurationLoaded: false,
    publishFolder: 'dist',

    SalesChannel: 5,
    Currency: '$',
    PriceDisplayOption: 0, //1:min and max price, 2: defined price concession(PriceMinConsessionId,PriceMaxConsessionId)
    PriceMinConsessionId: 0,
    PriceMaxConsessionId: 0,
    DefaultBussinessAreaId: 0,
    DefaultPackageClassId: 0,
    PriceTypeId: 0,
    DefaultCurrencyId:0,
    PackageEntityConfigurationProfileId: '1',
    BookingEntityConfigurationProfileId: '2',
    ItemEntityConfigurationProfileId: '3',
    GoogleAPIKEY: "",
    BookingStatus: "",
    BookingExpireStatus:"",
    PackageClasses: [],
    MinimumBookingDuration: 0,
    BookingSearchDescriptionTextLimitHigh: 140,
    BookingSearchDescriptionTextLimitMedium: 80,
    BookingSearchDescriptionTextLimitLow: 40,
    IsDisableInputsInPayment: true,
    PrivacyPolicyLink: 'https://www.manningham.vic.gov.au/privacy',
    TermsandConditionLink: '',
    ReCaptchaSiteKey: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
