import {
    Component, AfterViewInit, ViewChild, ChangeDetectorRef, Input, OnInit,
    OnChanges, SimpleChanges, SimpleChange
} from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { Facility } from 'src/modules/models/asset/facility';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-simple',
    templateUrl: './simple.component.html',
    //styleUrls: ['']
})
export class SimpleComponent implements AfterViewInit {
    name = 'Angular';
    slideNo = 0;
    withAnim = true;
    resetAnim = true;
    WebAPiBaseUrl: string;

    @Input() venueFacilities: Observable<Facility[]>;
    @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
    dateInUrl
    carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
        load: 10,
        interval: { timing: 4000, initialDelay: 1000 },
        loop: true,
        touch: true,
        velocity: 0.2,
        point: {
            visible: true,
            hideOnSingleSlide: true
        }
    }
    carouselItems = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

    constructor(private cdr: ChangeDetectorRef) {
        this.WebAPiBaseUrl = environment.WebApiUrl;
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    reset() {
        this.myCarousel.reset(!this.resetAnim);
    }

    moveTo(slide) {
        this.myCarousel.moveTo(slide, !this.withAnim);
    }
    ngOnInit() {
        this.dateInUrl = new Date();
        this.dateInUrl.setHours(0, 0, 0);
    }

    ngOnChanges(changes: SimpleChanges) {
        const data: SimpleChange = changes.venueFacilities;
        if (data.currentValue.length > 0) {
            this.carouselItems = data.currentValue;
            this.ngAfterViewInit();
        }       
    }
}