import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';

import { BaseParam } from '../models/base-param';
import { Booking } from '../models/booking/booking';
import { AdHocDocument } from '../models/booking/adhoc-document';


import { jsonIgnoreReplacer, jsonIgnore } from 'json-ignore';


@Injectable({
  providedIn: 'root'
})
export class BookingService {

    constructor(private _baseService: BaseService) {

    }
    BookingSave(postdata: Booking): Observable<any> {
        var json = new Serializer().serialize(JSON.parse(JSON.stringify(postdata, jsonIgnoreReplacer)));
        let changingOrder = ["PrivateEventFacilityTimeslot", "Allocation", "PrivateBookingGeneraladmissionitem", "BookingPackage"];
       
        json.included.sort((a, b) => changingOrder.indexOf(a.type) - changingOrder.indexOf(b.type));
        json.included.reverse();
        console.log(JSON.stringify(json));
        return this._baseService.WebAPIPOST("api/V4.1/bookings", json).pipe(map(response => {
            var booking = (new Deserializer().deserialize(response));
            console.log("BookingSave",booking)
            return booking;
        }));
    }

    BookingPatch(id:string,postdata: Booking): Observable<any> {
        var json = new Serializer().serialize(postdata);
        console.log(JSON.stringify(json));
        return this._baseService.WebAPIPATCH("api/V4.1/bookings/"+id, json).pipe(map(response => {
            var booking = (new Deserializer().deserialize(response));
            console.log("BookingPatch",booking)
            return booking;
        }));
    }

    BookingSelect(baseParam: BaseParam): Observable<any> {
        return this._baseService.WebAPIGet("api/v4.1/products/bookings" + baseParam.fields).pipe(
            map((response) => {
                var json = new Deserializer().deserialize(response);                
                return json;
            })
        );
    }

    BookingDocumentsSave(id: string, postdata: AdHocDocument): Observable<any> {
        var json = new Serializer().serialize(JSON.parse(JSON.stringify(postdata, jsonIgnoreReplacer)));
        return this._baseService.WebAPIPOST("api/V4.1/bookings/" + id +"/documents", json).pipe(map(response => {
            var data = (new Deserializer().deserialize(response));
            console.log("BookingDocumentSave", data)
            return data;
        }));
    }
}
