﻿export class FacilityImageList {
    public ImagesInfo: ImageInfo;
    public ThumbnailInfo: ThumbnailInfo;
}

export class ImageInfo {
    public Type: string; /*Types: small, medium, large*/
    public Url: string[];
}

export class ThumbnailInfo {
    public Url: string;
}

export class FacilityImages {
    public small: string[];
    public medium: string[];
    public large: string[];
    constructor() {
        
        this.small = [];
        this.medium = [];
        this.large = [];
    }
}