﻿import { Client } from './../../../../src/modules/models/client/client';

export class CorporateClient extends Client{
    public legalName: string;
    public companyName: string;
    public headOfficeClient: CorporateClient;
    public headOffice: boolean;
    public turnover: number;
    public employeesRangeId: number;
    public employeesRange: string;
    public industry: string;
    public industryId: number;
    public companyRegistrationNo: string;
    public webSite: string;
    public dateFinalised: Date;
}

