﻿import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Pipe({ name: 'CurrencyFormat' })
export class CurrencyFormat implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) { }

    transform(value: number): string {
     
        if (value != undefined && value != null)
            return this.currencyPipe.transform(value, environment.Currency, 'symbol', '1.2-2');
        return this.currencyPipe.transform(0, environment.Currency,'symbol','1.2-2');
    }
}