﻿import { platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { BookingModule } from './booking.module';
import { Init } from '../Init';
import { environment } from '../../environments/environment';
import 'hammerjs';
//set url from web config
Init().then(() => {
   // console.log(environment);
    
    platformBrowserDynamic().bootstrapModule(BookingModule);
});
//Init()
//platformBrowserDynamic().bootstrapModule(BookingModule);
// if (document.readyState === 'complete') {
//     platformBrowserDynamic().bootstrapModule(BookingModule);

//} else {
//     document.addEventListener('DOMContentLoaded', function () {
//         return platformBrowserDynamic().bootstrapModule(BookingModule);
//     });
//}

