﻿import { BaseResource } from '../base-resource';
export class Venue extends BaseResource {
    name: string;
    active: boolean;
    defaultFunctionSheetMethod: number;
    description: string;
    direction: string;
    email: string;
    emailInvoiceType: number;
    etag: string;
    fax: string;
    googleMapUrl: string;
    latitude: string;
    longitude:string
    organisation:string
    prefix: string;
    siteCode: string;
    taxTypeName: string;
    telephone: string;
    url: string;
    userDefault: string;
    venueType: string;
    venueTypeId: number;
}
