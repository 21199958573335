import { Component, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit ,Input} from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { Facility } from 'src/modules/models/asset/facility';


@Component({
  selector: 'opt-mmc-carousel',
  templateUrl: './mmc-carousel.component.html',
    //styleUrls: ['./mmc-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class MmcCarouselComponent implements AfterViewInit {
    @Input() venueFacilitiesDTO: Facility[];
    name = 'Angular';
    slideNo = 2;
    withAnim = true;
    resetAnim = true;

    @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
    carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
        load: 3,
        slide: 3,
        interval: { timing: 4000, initialDelay: 1000 },
        loop: true,
        touch: true,
        velocity: 0.2,
        point: {
            visible: true,
            hideOnSingleSlide: true
        }
    }
    carouselItems = [1, 2, 3, 4];

    constructor(private cdr: ChangeDetectorRef) { }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    reset() {
        this.myCarousel.reset(!this.resetAnim);
    }

    moveTo(slide) {
        this.myCarousel.moveTo(slide, !this.withAnim);
    }
}
